import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { ProjectUserType } from 'common/models/projectUser'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdLock } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import LabelWithValue from 'web/components/LabelWithValue'
import ProjectUserPermissions from './ProjectUserPermissions'

interface Props {
    projectUser: ProjectUserType | undefined
    close: () => void
}

export default React.memo(ViewProjectUser)

function ViewProjectUser(props: Props) {
    const { projectUser, close } = props

    const classes = useStyles()
    const { t } = useTranslation()

    const [permissions, setPermissions] = useState(false)
    const openPermissions = useCallback(() => setPermissions(true), [])
    const closePermissions = useCallback(() => setPermissions(false), [])

    return (
        <div className={classes.container}>
            {projectUser ? (
                <Dialog open={permissions} onClose={closePermissions}>
                    <ProjectUserPermissions projectUser={projectUser} close={closePermissions} />
                </Dialog>
            ) : null}

            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.userInfo')}</Typography>
            </div>

            <div className={classes.content}>
                <Avatar
                    src={projectUser?.photo}
                    className={classes.avatar}
                    id={projectUser?._id}
                    name={projectUser?.name}
                />

                <LabelWithValue label={t('common.userName')} value={projectUser?.name} />
                <LabelWithValue label={t('common.userEmail')} value={projectUser?.email} />
                <LabelWithValue noMargin label={t('common.userPhoneNumber')} value={projectUser?.phoneNumber} />
            </div>

            <Button
                onClick={openPermissions}
                color="primary"
                variant="contained"
                className={classes.permissionsButton}
                startIcon={<MdLock />}
            >
                {t('common.projectPermissions')}
            </Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    avatar: {
        height: 72,
        width: 72,
        marginBottom: 32,
        fontSize: 26,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    permissionsButton: {
        position: 'absolute',
        right: 40,
        top: 80,
    },
}))
