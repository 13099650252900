import { FilledInput, IconButton, InputAdornment } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { auth } from 'frontend/API/auth'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import useRouter from 'use-react-router'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'

export default React.memo(ChangePassword)

function ChangePassword() {
    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const [loading, setLoading] = useState(false)
    const { history } = useRouter()

    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [showPasswords, setShowPasswords] = useState(false)

    const onCurrentPasswordChange = useCallback((e) => setCurrentPassword(e.target.value), [])
    const onPasswordChange = useCallback((e) => setPassword(e.target.value), [])
    const onConfirmPasswordChange = useCallback((e) => setConfirmPassword(e.target.value), [])
    const match = password === confirmPassword

    const toggleShowPassword = useCallback(() => {
        setShowPasswords(!showPasswords)
    }, [showPasswords])

    const handleMouseDownPassword = useCallback((event) => {
        event.preventDefault()
    }, [])

    const submit = useCallback(async () => {
        setLoading(true)

        const res = await auth.changePassword({ oldPassword: currentPassword, newPassword: password })

        if (res.result !== 'ok') {
            alert('error', t('errors.passwordUpdateFailed'))
            setLoading(false)
            return
        }

        alert('success', t('auth.passwordUpdatedSuccessfully'))

        setLoading(false)

        setTimeout(() => {
            history.push('/overview')
        }, 1000)
    }, [alert, currentPassword, history, password, t])

    return (
        <div className={classes.container}>
            <Form className={classes.content} disabled={!match || !currentPassword} onSubmit={submit}>
                <Typography className={classes.title}>{t('login.changePassword')}</Typography>

                <FilledInput
                    className={classes.password}
                    type={showPasswords ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={onCurrentPasswordChange}
                    autoComplete="off"
                    placeholder={t('common.currentPassword')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPasswords ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

                <FilledInput
                    className={classes.password}
                    autoComplete="off"
                    type={showPasswords ? 'text' : 'password'}
                    value={password}
                    placeholder={t('common.newPassword')}
                    onChange={onPasswordChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPasswords ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </IconButton>
                        </InputAdornment>
                    }
                />

                <FilledInput
                    className={classes.password}
                    type={showPasswords ? 'text' : 'password'}
                    value={confirmPassword}
                    autoComplete="off"
                    onChange={onConfirmPasswordChange}
                    placeholder={t('common.confirmPassword')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPasswords ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <Button
                    disabled={!match || loading || !currentPassword}
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : t('common.update')}
                </Button>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 32,
        marginBottom: 80,
        textAlign: 'center',
    },
    email: {
        marginBottom: 24,
    },
    password: {
        marginBottom: 26,
    },
    submit: {
        height: 40,
    },
    logo: {
        position: 'absolute',
        width: 264,
        left: '50%',
        top: 64,
        transform: 'translate(-60%)',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    forgetPasswordLink: {
        color: theme.palette.text.primary,
        marginBottom: 34,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))
