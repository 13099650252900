import { useRef } from 'react'

export default function useMemoIsEqual<T>(value: T, isEqualFn: (a: T, b: T) => boolean) {
    const ref = useRef(value)

    if (!isEqualFn(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}
