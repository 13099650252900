import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import SwitchTransition from 'react-transition-group/SwitchTransition'

interface Props {
    children: React.ReactNode // only one element, the effect will be applied to the element children
    changeKey: number | string | boolean
    timeout?: number // in ms
}

export default React.memo(Animate)

function Animate(props: Props) {
    const { children, changeKey, timeout } = props

    return (
        <SwitchTransition mode="out-in">
            <CSSTransition timeout={timeout ?? 100} key={changeKey + ''} classNames="fade">
                {children}
            </CSSTransition>
        </SwitchTransition>
    )
}
