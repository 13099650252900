/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import { Paper, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TransactionType } from 'common/models/transaction'
import { BeneficiaryInfoResponse } from 'common/types/BeneficiaryInfoTypes'
import { api } from 'frontend/API/api'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiUserGroup } from 'react-icons/hi'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import {
    CustomDragComponent,
    CustomPagingPanel,
    CustomTableFilterRow,
    CustomTableHeaderRow,
} from '../Tables/tableUtils'

export default React.memo(BeneficiaryInfoView)

function BeneficiaryInfoView() {
    const classes = useStyles()
    const { t } = useTranslation()

    const { match } = useReactRouter()
    const chainId = (match.params as any).chainId

    const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryInfoResponse | undefined>()

    useEffect(() => {
        if (beneficiaryData || !chainId) {
            return
        }
        const func = async () => {
            const responseData = await api.getBeneficiaryInfo({ chainId })

            setBeneficiaryData(responseData)
        }
        func()
    }, [beneficiaryData, chainId])

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.transactionId'),
                name: 'transactionId',
                getCellValue: (row: TransactionType) => row.transactionId,
            },
            {
                title: t('common.transactionType'),
                name: 'eventName',
                getCellValue: (row: TransactionType) => row.eventName,
            },
            {
                title: t('common.createdAt'),
                name: 'createdAt',
                getCellValue: (row: TransactionType) => {
                    const date = moment(row.createdAt)
                    if (date.isSame(moment(), 'hour')) {
                        return date.fromNow()
                    }

                    return date.format('DD/MM/YYYY hh:mm:ss')
                },
            },
            {
                title: t('common.orgId'),
                name: 'orgId',
                getCellValue: (row: TransactionType) => row.orgChainId,
            },
        ]
    }, [t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    if (!beneficiaryData) {
        return <Loading />
    }

    const transactions = beneficiaryData.transactions

    return (
        <div className={classes.container}>
            <Header title={t('common.beneficiarieInfo')} Icon={HiUserGroup} />
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <div className={classes.beneficiaryOrgAndIdContainer}>
                        <div className={classes.infoLabel} style={{ minWidth: 520 }}>
                            <span className={classes.labelTitle}>Beneficiary Id</span>
                            <span className={classes.labelValue}>{beneficiaryData.beneficiary.chainId}</span>
                        </div>

                        <div className={classes.infoLabel}>
                            <span className={classes.labelTitle}>Registered By</span>
                            <span className={classes.labelValue}>{beneficiaryData.registeredByOrgName}</span>
                        </div>
                    </div>

                    <div className={classes.status}>
                        <div className={classes.infoLabel}>
                            <span className={classes.labelTitle}>Status</span>
                            <span className={classes.labelValue}>{beneficiaryData.status}</span>
                        </div>
                    </div>

                    <div className={classes.beneficiaryProjects}>
                        <div className={classes.infoLabel}>
                            <span className={classes.labelTitle}>Projects</span>
                            {beneficiaryData.projects.map((projectName, idx) => {
                                return (
                                    <span key={idx} className={classes.labelValue}>
                                        {projectName}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <Animate changeKey={!transactions}>
                    <div className={classes.itemsContainer}>
                        <h4 className={classes.transactionsTableTitle}>Beneficiary Transactions</h4>
                        <Paper>
                            <Grid rows={transactions} columns={columns}>
                                <DataTypeProvider for={['transactionId']} formatterComponent={TransactionIdComponent} />
                                <DataTypeProvider for={['orgId']} formatterComponent={OrgIdComponent} />
                                <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                <DataTypeProvider for={['eventName']} formatterComponent={EventNameComponent} />

                                <DragDropProvider containerComponent={CustomDragComponent} />
                                <SortingState />

                                <IntegratedSorting />

                                <PagingState defaultCurrentPage={0} pageSize={24} />
                                <IntegratedPaging />

                                <FilteringState />
                                <IntegratedFiltering />

                                <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                                <PagingPanel containerComponent={CustomPagingPanel} />
                                <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                            </Grid>
                        </Paper>
                    </div>
                </Animate>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
        flexDirection: 'column',
        maxWidth: 980,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },
    transactionsTableTitle: {
        color: '#FFF',
    },
    status: {
        display: 'flex',
        marginTop: 24,
    },
    beneficiaryProjects: {
        display: 'flex',
        marginTop: 24,
    },
    table: {
        minWidth: 650,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 64,
        minWidth: 850,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    beneficiaryOrgAndIdContainer: {
        display: 'flex',
    },
    infoLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    labelTitle: {
        color: theme.palette.primary.light,
    },
    labelValue: {
        color: '#FFF',
        marginTop: 8,
    },
}))

const OrgIdComponent = ({ row }: any) => <span title={row.orgChainId}>{row.orgChainId}</span>
const TransactionIdComponent = ({ row }: any) => {
    const theme = useTheme()
    return (
        <span title={row.transactionId} style={{ color: theme.palette.primary.main }}>
            {row.transactionId}
        </span>
    )
}
const EventNameComponent = ({ row }: any) => {
    const { t } = useTranslation()
    return <span>{t(`common.${row.eventName}`)}</span>
}
const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />
