// TODO: lazy load the langauges
import i18n from 'i18next'
import moment from 'moment'
import React from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import en from '../translations/en'

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    fallbackNS: 'default',
    lng: 'en',
    resources: {
        en: {
            translation: en,
            default: en,
        },
    },
    interpolation: {
        escapeValue: false, // react already safes from xss
        format: function (value, format) {
            // if (format === 'uppercase') return value.toUpperCase();
            if (value instanceof Date) {
                return moment(value).format(format)
            }
            return value
        },
    },
})

interface Props {
    children: React.ReactNode
}

export default React.memo(Locale)

function Locale({ children }: Props) {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
