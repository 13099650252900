/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import Button from '@material-ui/core/Button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { ProjectType } from 'common/models/project'
import { ProjectBeneficiaryType } from 'common/models/projectBeneficiary'
import { MobileRegistrationAttemptType } from 'common/types/syncTypes'
import generateMongoId from 'common/utils/generateMongoId'
import { saveAs } from 'file-saver'
import { beneficiariesRegistraion } from 'frontend/API/beneficiariesRegistraion'
import useCollection from 'frontend/query/useCollection'
import genAccountFromSeed from 'frontend/utils/genAccountFromSeed'
import getDefaultStandardInfo from 'frontend/utils/standards/getDefaultStandardInfo'
import { replaceString } from 'frontend/utils/standards/replaces'
import { groupBy, map, omit } from 'lodash'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdImportExport } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import { useAlert } from 'web/containers/AlertProvider'
import Header from 'web/containers/Layout/Header'
import { useOrganization } from 'web/containers/UserProvider'
import { excelColumnCharacters, StandardInfoWithLocation } from 'web/utils/excelImportBeneficiariesValid'
import * as XLSX from 'xlsx'
import { excelImportBeneficiariesValid } from '../../utils/excelImportBeneficiariesValid'
import DownloadBeneficiaryImportTemplate from '../Project/components/DownloadBeneficiaryImportTemplate'
import { CustomDragComponent, CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from './tableUtils'

interface Props {
    project: ProjectType
}

export default React.memo(ProjectBeneficiariesTable)

function ProjectBeneficiariesTable(props: Props) {
    const { project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { push } = useReactRouter().history
    const alert = useAlert()
    const [importLoading, setImportLoading] = useState(false)

    const org = useOrganization()

    const exporterRef = useRef<typeof GridExporter>()

    const goBack = useCallback(() => push('/projects'), [push])

    const [projectBeneficiaries, BeneficiariesLoading] = useCollection<ProjectBeneficiaryType>({
        collection: 'projectBeneficiaries',
        projectId: project._id,
    })

    const downloadErrorText = useCallback((filename: string, text: string) => {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }, [])

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.beneficiaryId'),
                name: 'chainId',
                getCellValue: (row: ProjectBeneficiaryType) => row.chainId,
            },
            {
                title: t('common.registeredAt'),
                name: 'createdAt',
                getCellValue: (row: ProjectBeneficiaryType) => moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss'),
            },
            {
                title: t('common.orgId'),
                name: 'orgId',
                getCellValue: (row: ProjectBeneficiaryType) => row.orgChainId,
            },
        ]
    }, [t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(projectBeneficiaries ?? [], (x) => x.createdAt).reverse()
    }, [projectBeneficiaries])

    const startExport = useCallback(() => exporterRef.current?.exportGrid({}), [])
    const onSave = useCallback((workbook) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'projectBeneficiaries.xlsx')
        })
    }, [])

    const importBeneficiareis = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) {
                return
            }
            setImportLoading(true)
            const file = e.target.files[0]

            const reader = new FileReader()

            const convertArrayToObj = (array: any[]) => {
                let result = {}
                array.map((x) => {
                    result = { ...result, ...x }
                })
                return result
            }

            reader.onload = async function (e) {
                const data = new Uint8Array(e.target?.result as any)
                const workbook = XLSX.read(data, { type: 'array' })

                const aoa: string[][] = XLSX.utils.sheet_to_json(workbook.Sheets['Beneficiares'], { header: 1 })

                const fields = aoa[0]

                const rows = aoa

                const replaceStringRows = rows.map((_, idx) => {
                    const values = fields.map((key, index) => {
                        return replaceString(rows[idx][index]) as string
                    })

                    return values
                })

                const beneficiariesRegistraions = replaceStringRows
                    .map((_, idx) => {
                        const values = fields.map((key, index) => {
                            const cellValue = replaceStringRows[idx][index] as string

                            return {
                                [key]: cellValue,
                                location: { row: idx + 1, column: excelColumnCharacters[index] },
                            }
                        })

                        return convertArrayToObj(values) as StandardInfoWithLocation
                    })
                    .slice(1, replaceStringRows.length)

                const beneficiariesRegistraionsGroupd = groupBy(beneficiariesRegistraions, (benf) => {
                    const standardInfo = getDefaultStandardInfo(omit(benf, 'location') as any)
                    const hashId: string = genAccountFromSeed(standardInfo).address.base58
                    return hashId
                })

                const errors = await excelImportBeneficiariesValid(beneficiariesRegistraionsGroupd)

                if (errors.length !== 0) {
                    setImportLoading(false)
                    let errorTextContent = ''

                    errors.map((errorItem) => {
                        errorTextContent = errorTextContent + '\n' + ' * ' + errorItem.description
                    })

                    downloadErrorText('ImportError.txt', errorTextContent)
                    return
                }

                const registrationsAttampts = map(beneficiariesRegistraionsGroupd, (items) => {
                    const standardInfo = getDefaultStandardInfo(items[0])
                    const attemptId = generateMongoId()

                    const id: string = genAccountFromSeed(standardInfo).address.base58

                    const attempt = {
                        id: attemptId,
                        chainId: id,
                        orgId: org?._id,
                        status: 'unknown',
                        projectId: project._id,
                        createdAt: new Date(),
                    } as MobileRegistrationAttemptType

                    return attempt
                })

                const res = await beneficiariesRegistraion.registerBeneficiaries({ registrationsAttampts })
                setImportLoading(false)

                if (res.status === 'error') {
                    alert('error', t('errors.somethingWentWrong'))
                    return
                }

                alert('success', t('common.importSuccessfully'))
            }

            reader.readAsArrayBuffer(file)
        },
        [alert, downloadErrorText, org?._id, project._id, t]
    )

    return (
        <div className={classes.container}>
            <Header
                actions={
                    projectBeneficiaries?.length
                        ? [{ title: t('common.export'), onClick: startExport, Icon: MdImportExport }]
                        : []
                }
                onBackPress={goBack}
                title={`${project.name} - ${t('common.projectBeneficiaries')}`}
                additionalButtons={[
                    <Button
                        key="import"
                        className={classNames({ [classes.action]: true })}
                        variant="contained"
                        color="primary"
                        startIcon={<MdImportExport />}
                        disabled={importLoading}
                    >
                        {importLoading ? (
                            <CircularProgress color="primary" size={22} />
                        ) : (
                            <label>
                                {t('common.import')}
                                <input type="file" hidden onChange={importBeneficiareis} />
                            </label>
                        )}
                    </Button>,
                    <DownloadBeneficiaryImportTemplate key="template" />,
                ]}
            />

            <Animate changeKey={BeneficiariesLoading}>
                {BeneficiariesLoading ? (
                    <Loading />
                ) : !rows.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemsContainer}>
                                <GridExporter onSave={onSave} ref={exporterRef} columns={columns} rows={rows} />

                                <Paper>
                                    <Grid rows={rows} columns={columns}>
                                        <DataTypeProvider
                                            for={['beneficiaryId']}
                                            formatterComponent={BeneficiaryIdComponent}
                                        />
                                        <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                        <DataTypeProvider for={['orgId']} formatterComponent={OrgIdComponent} />

                                        <DragDropProvider containerComponent={CustomDragComponent} />
                                        <SortingState />

                                        <IntegratedSorting />

                                        <PagingState defaultCurrentPage={0} pageSize={24} />
                                        <IntegratedPaging />

                                        <FilteringState />
                                        <IntegratedFiltering />

                                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                                        <PagingPanel containerComponent={CustomPagingPanel} />
                                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const OrgIdComponent = ({ row }: any) => <span>{row.orgChainId}</span>

const BeneficiaryIdComponent = ({ row }: any) => <span>{row.chainId}</span>

const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    action: {
        marginLeft: 16,
    },
}))
