import Stream from 'common/utils/Stream'
import { useEffect, useState } from 'react'

export default function useStream<V>(stream: Stream<V>) {
    const [value, setValue] = useState<V>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const unsub = stream.subscribe((v) => {
            setValue(v)
            setLoading(false)
        })

        return () => {
            unsub()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [value, loading] as const
}
