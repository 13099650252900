/* eslint-disable react/no-multi-comp */
import { DragDropProvider, PagingPanel, Table, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'

export const CustomTableHeaderRow = (props: Table.RowProps) => (
    <TableHeaderRow.Row {...props} style={{ backgroundColor: '#303536' }} />
)

export const CustomDragComponent = (props: any) => (
    <DragDropProvider.Container {...props} style={{ backgroundColor: '#303536' }} />
)

export const CustomPagingPanel = (props: any) => (
    <PagingPanel.Container {...props} style={{ backgroundColor: '#303536', justifyContent: 'space-between' }} />
)

export const CustomTableFilterRow = ({ children, tableRow }: any) => {
    const props = { children, tableRow }

    return (
        <Table.Row
            {...props}
            style={{ backgroundColor: tableRow.row?.status === 'duplicated' ? '#4c494a' : '#404546' }}
            row={tableRow}
        />
    )
}

export const CustomToolbar = ({ children }: Toolbar.RootProps) => {
    const props = { children }
    return <Toolbar.Root {...props} style={{ backgroundColor: '#303536' }} />
}
