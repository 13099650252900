import { SocketResponse } from 'common/types/socketTypes'
import { CollectionStreamType } from 'frontend/query/subToCollection'
import { requestsToPromises, topicsToStreams } from '../../registry/requestsRegistry'

export default function responseHander(res: SocketResponse) {
    switch (res.method) {
        case 'subToId': {
            const streamSet = topicsToStreams.get(res.topic)

            if (streamSet) {
                streamSet.forEach((stream) => stream.value(res.data))
            }

            break
        }

        case 'ack': {
            const promise = requestsToPromises.get(res.requestId)
            promise?.resolve?.('ok')
            requestsToPromises.delete(res.requestId)

            break
        }

        case 'error': {
            const promise = requestsToPromises.get(res.requestId)
            promise?.reject?.(res.error)
            requestsToPromises.delete(res.requestId)

            break
        }

        case 'subToCollection': {
            const streamSet = topicsToStreams.get(res.topic)

            if (streamSet) {
                streamSet.forEach((stream) => stream.value({ type: 'initial', docs: res.data } as CollectionStreamType))
            }

            break
        }

        case 'queryUpdate': {
            const streamSet = topicsToStreams.get(res.topic)

            if (streamSet) {
                streamSet.forEach((stream) =>
                    stream.value({ type: res.mutate, doc: res.doc, docId: res.docId } as CollectionStreamType)
                )
            }

            break
        }
    }
}
