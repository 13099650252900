import { serverEndPoints } from 'common/api/serverEndpoints'
import {
    ChangePasswordArgs,
    ChangePasswordResponse,
    CheckEmailExistArgs,
    CheckEmailExistResponse,
    LoginArgs,
    LoginResponse,
    MobileLoginResponse,
    SetupPasswordArgs,
    SetupPasswordResponse,
} from 'common/types/authTypes'
import {
    CheckVerificationCodeArgs,
    CheckVerificationCodeResponse,
    ResetPasswordArgs,
    ResetPasswordResponse,
    sendVerificationToEmailArgs,
    sendVerificationToEmailResponse,
} from 'common/types/verificationTypes'
import { getGlobalConfig } from 'frontend/config/globalConfig'
import logoutStream from 'frontend/helpers/logoutStream'
import userIdStream from 'frontend/helpers/userIdStream'
import sendRequest from './sendRequest'

export const auth = {
    login: (args: LoginArgs) => sendRequest<LoginResponse>({ endpoint: serverEndPoints.auth.login, args, auth: false }),

    mobileLogin: (args: LoginArgs) =>
        sendRequest<MobileLoginResponse>({ endpoint: serverEndPoints.auth.mobileLogin, args, auth: false }),

    setupPassword: (args: SetupPasswordArgs) =>
        sendRequest<SetupPasswordResponse>({ endpoint: serverEndPoints.auth.setupPassword, args, auth: false }),

    changePassword: (args: ChangePasswordArgs) =>
        sendRequest<ChangePasswordResponse>({ endpoint: serverEndPoints.auth.changePassword, args, auth: true }),

    checkEmailExit: (args: CheckEmailExistArgs) =>
        sendRequest<CheckEmailExistResponse>({
            endpoint: serverEndPoints.auth.checkEmailExist,
            args,
            auth: false,
        }),

    sendResetPasswordVerificationCode: (args: sendVerificationToEmailArgs) =>
        sendRequest<sendVerificationToEmailResponse>({
            endpoint: serverEndPoints.auth.sendVerificationCode,
            args,
            auth: false,
        }),

    checkVerificationCode: (args: CheckVerificationCodeArgs) =>
        sendRequest<CheckVerificationCodeResponse>({
            endpoint: serverEndPoints.auth.checkVerificationCode,
            args,
            auth: false,
        }),

    resetPassword: (args: ResetPasswordArgs) =>
        sendRequest<ResetPasswordResponse>({
            endpoint: serverEndPoints.auth.resetPassword,
            args,
            auth: false,
        }),

    logout: () => {
        getGlobalConfig().clearToken()
        userIdStream.value({ loading: false, userId: undefined })
        logoutStream.value(true)
    },
}
