import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import removeUndefined from 'common/utils/removeUndefined'
import { useFormik } from 'formik'
import { mutate } from 'frontend/API/mutate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'
import * as yup from 'yup'

interface Props {
    close: () => void
}

export default React.memo(CreateProject)

function CreateProject(props: Props) {
    const { close } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const form = useFormik({
        initialValues: { name: '' },

        validationSchema: getSchema(),

        onSubmit: async (values) => {
            setLoading(true)

            try {
                const res = await mutate.insert({
                    collection: 'projects',
                    fields: removeUndefined({ name: values.name }),
                })

                if (res.result !== 'ok') {
                    alert('error', t(`errors.${res.result}`))
                    return
                }

                close()
            } catch (e) {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            } finally {
                setLoading(false)
            }
        },
    })

    const formValid = form.isValid && form.values.name.trim() !== ''

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.createProject')}</Typography>
            </div>

            <Form className={classes.content} disabled={!formValid} onSubmit={form.submitForm}>
                <TextField
                    value={form.values.name}
                    onChange={form.handleChange('name')}
                    className={classes.input}
                    fullWidth
                    autoFocus
                    variant="filled"
                    label={t('common.projectName')}
                    required
                />

                <div className={classes.actionsContainer}>
                    <Button onClick={close} style={{ marginRight: 16 }} variant="outlined">
                        {t('common.cancel')}
                    </Button>

                    <Button type="submit" disabled={!formValid} color="primary" variant="contained">
                        {t('common.create')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 548,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    input: {
        marginBottom: 16,
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 24,
        width: '100%',
    },
}))

const getSchema = () => {
    return yup.object().shape({ name: yup.string().required() })
}
