import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ProjectUserType } from 'common/models/projectUser'
import { UserType } from 'common/models/user'
import { useFormik } from 'formik'
import { mutate } from 'frontend/API/mutate'
import useCollection from 'frontend/query/useCollection'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'

interface Props {
    close: () => void
    projectId: string
    existingUsers: ProjectUserType[]
}

export default React.memo(AddUserToProject)

function AddUserToProject(props: Props) {
    const { close, projectId, existingUsers } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const [users, loading] = useCollection<UserType>({ collection: 'users' })

    const form = useFormik({
        initialValues: { userId: '', projectId },

        onSubmit: async (values) => {
            setLoading(true)

            const exists = !!existingUsers.find((x) => x.userId === values.userId)

            if (exists) {
                alert('error', t('common.userAlreadyInProject'))
                return
            }

            try {
                const res = await mutate.insert({
                    collection: 'projectUsers',
                    fields: { projectId: values.projectId, userId: values.userId },
                })

                if (res.result !== 'ok') {
                    alert('error', t(`errors.${res.result}`))
                    return
                }

                close()
            } catch (e) {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            } finally {
                setLoading(false)
            }
        },
    })

    const formValid = form.values.userId.trim() !== ''

    const getOptionLabel = useCallback((option) => option.name, [])
    const renderInput = useCallback((params) => <TextField {...params} label={t('common.user')} variant="filled" />, [
        t,
    ])
    const onChange = useCallback((e, val) => form.setFieldValue('userId', val ? val._id : ''), [form])

    const filteredUsers = useMemo(() => {
        return (users ?? []).filter((user) => {
            return !existingUsers.find((projectUser) => projectUser.userId === user._id) && !user.admin
        })
    }, [existingUsers, users])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.addUserToProject')}</Typography>
            </div>

            <Form className={classes.content} disabled={!formValid} onSubmit={form.submitForm}>
                {loading ? (
                    <div className={classes.loadingContainer}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    <Autocomplete
                        onChange={onChange}
                        fullWidth
                        options={filteredUsers}
                        getOptionLabel={getOptionLabel}
                        renderInput={renderInput}
                    />
                )}

                <div className={classes.actionsContainer}>
                    <Button onClick={close} style={{ marginRight: 16 }} variant="outlined">
                        {t('common.cancel')}
                    </Button>

                    <Button type="submit" disabled={!formValid} color="primary" variant="contained">
                        {t('common.add')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 548,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    input: {
        marginBottom: 16,
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 40,
        width: '100%',
    },
    loadingContainer: {
        height: 52,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
