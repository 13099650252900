import { serverEndPoints } from 'common/api/serverEndpoints'
import {
    CalculateRegistraionsArgs,
    CalculateRegistraionsResponse,
    GetPublicArgs,
    GetPublicResponse,
    OrganizationRegistrationsAgrs,
    OrganizationRegistrationsResponse,
} from 'common/types/dashboardTypes'
import sendRequest from './sendRequest'

export const dashboard = {
    caculateRegistrations: (args: CalculateRegistraionsArgs) =>
        sendRequest<CalculateRegistraionsResponse>({ endpoint: serverEndPoints.dashboard.project, args }),
    getPublicData: (args: GetPublicArgs) =>
        sendRequest<GetPublicResponse>({ endpoint: serverEndPoints.dashboard.getPublicData, args }),
    getOrgDashboardData: (args: OrganizationRegistrationsAgrs) =>
        sendRequest<OrganizationRegistrationsResponse>({ endpoint: serverEndPoints.dashboard.organizationData, args }),
}
