import { azureStorageSASToken, storageUrl } from 'common/storage/storage'
import { v4 as uuid } from 'uuid'

export default async function uploadToStorage(blob: Blob | File | string) {
    const name = uuid()

    const url = `${storageUrl}/${name}`

    const response = await fetch(`${url}${azureStorageSASToken}`, {
        method: 'put',
        headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Access-Control-Allow-Origin': '*',
        },
        body: blob,
    })

    return response.url
}
