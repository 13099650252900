import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { UserType } from 'common/models/user'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdEdit, MdLock } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import LabelWithValue from 'web/components/LabelWithValue'
import { useRoles } from 'web/containers/UserProvider'
import UserPermissions from './components/UserPermissions'
import CreateUser from './CreateUser'

interface Props {
    user: UserType | undefined
    close: () => void
    noEdit?: boolean
}

export default React.memo(ViewUser)

function ViewUser(props: Props) {
    const { user, close, noEdit } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const userRoles = useRoles()

    const [edit, setEdit] = useState(false)
    const openEdit = useCallback(() => setEdit(true), [])
    const closeEdit = useCallback(() => setEdit(false), [])

    const [permissions, setPermissions] = useState(false)
    const openPermissions = useCallback(() => setPermissions(true), [])
    const closePermissions = useCallback(() => setPermissions(false), [])

    const hideEdit = noEdit || (!userRoles.includes('admin') && user?.roles.includes('admin'))

    return (
        <div className={classes.container}>
            <Dialog open={edit} onClose={closeEdit}>
                <CreateUser old={user} close={closeEdit} />
            </Dialog>

            {user ? (
                <Dialog open={permissions} onClose={closePermissions}>
                    <UserPermissions user={user} close={closePermissions} />
                </Dialog>
            ) : null}

            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.userInfo')}</Typography>
            </div>

            <div className={classes.content}>
                <Avatar src={user?.photo} className={classes.avatar} id={user?._id} name={user?.name} />

                <LabelWithValue label={t('common.userName')} value={user?.name} />
                <LabelWithValue label={t('common.userEmail')} value={user?.email} />
                <LabelWithValue label={t('common.userPhoneNumber')} value={user?.phoneNumber} />
                <LabelWithValue
                    label={t('common.active')}
                    value={user?.active ? t('common.yes') : t('common.no')}
                    noMargin
                />
            </div>

            <div className={classes.buttonsContainer}>
                {hideEdit ? null : (
                    <Button
                        onClick={openEdit}
                        color="primary"
                        variant="contained"
                        className={classes.editButton}
                        startIcon={<MdEdit />}
                    >
                        {t('common.edit')}
                    </Button>
                )}

                {user?.admin || noEdit ? null : (
                    <Button
                        onClick={openPermissions}
                        color="primary"
                        variant="contained"
                        className={classes.permissionsButton}
                        startIcon={<MdLock />}
                    >
                        {t('common.generalPermissions')}
                    </Button>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    avatar: {
        height: 72,
        width: 72,
        marginBottom: 32,
        fontSize: 26,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    buttonsContainer: {
        position: 'absolute',
        right: 40,
        top: 80,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    editButton: {
        marginBottom: 16,
    },
    permissionsButton: {},
}))
