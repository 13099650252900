import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { stringToLowerCase } from 'common/utils/stringToLowerCase'
import { auth } from 'frontend/API/auth'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'

export default React.memo(ResetPasswordNewPasswordStep)

interface Props {
    token: string
}

function ResetPasswordNewPasswordStep(props: Props) {
    const { token } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const [loading, setLoading] = useState(false)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const onPasswordChange = useCallback((e) => setPassword(stringToLowerCase(e.target.value)), [])
    const onConfirmPasswordChange = useCallback((e) => setConfirmPassword(stringToLowerCase(e.target.value)), [])
    const match = password === confirmPassword

    const submit = useCallback(async () => {
        setLoading(true)

        const res = await auth.resetPassword({ token, newPassword: password })

        if (res.status !== 'success') {
            alert('error', t('errors.passwordUpdateFailed'))
            setLoading(false)
            return
        }

        alert('success', t('auth.passwordUpdatedSuccessfully'))

        setLoading(false)
    }, [alert, password, t, token])

    return (
        <div className={classes.container}>
            <Form className={classes.content} disabled={!match} onSubmit={submit}>
                <Typography className={classes.title}>{t('login.enterYourNewPassword')}</Typography>

                <TextField
                    disabled={loading}
                    onChange={onPasswordChange}
                    value={password}
                    className={classes.email}
                    autoFocus
                    variant="filled"
                    label={t('common.newPassword')}
                />

                <TextField
                    disabled={loading}
                    onChange={onConfirmPasswordChange}
                    value={confirmPassword}
                    className={classes.email}
                    autoFocus
                    variant="filled"
                    label={t('common.ConfirmPassword')}
                />

                <Button
                    disabled={!match || loading}
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : t('common.next')}
                </Button>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 32,
        marginBottom: 80,
        textAlign: 'center',
    },
    email: {
        marginBottom: 24,
    },
    password: {
        marginBottom: 16,
    },
    submit: {
        height: 40,
    },
    logo: {
        position: 'absolute',
        width: 264,
        left: '50%',
        top: 64,
        transform: 'translate(-60%)',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    forgetPasswordLink: {
        color: theme.palette.text.primary,
        marginBottom: 34,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))
