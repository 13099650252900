export default class Stream<V = any> {
    constructor(initial?: boolean, initialValue?: V) {
        if (initial) {
            this.latestValue = initialValue!
            this.hasValue = true
        }
    }

    private hasValue = false
    public lastValue?: V
    private latestValue?: V

    private subs: Set<(value: V) => void> = new Set()

    value(val: V) {
        this.hasValue = true
        this.latestValue = val
        this.subs.forEach((fn) => fn(val))

        this.lastValue = val
    }

    subscribe(subscriber: (value: V) => void) {
        this.subs.add(subscriber)
        if (this.hasValue) {
            subscriber(this.latestValue!)
        }

        return () => {
            this.subs.delete(subscriber)
        }
    }
}
