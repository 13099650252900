import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { serverEndPoints, withServerUrl } from 'common/api/serverEndpoints'
import { RegistrationLookupResponse } from 'common/types/chainTypes'
import moment from 'moment'
import queryString from 'query-string'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import Loading from 'web/components/Loading'
import { extraColors } from 'web/containers/ThemeProvider'
import { useUser } from 'web/containers/UserProvider'
import logo from '../../assets/logo.svg'

export default React.memo(RegistrationLookup)

function RegistrationLookup() {
    const classes = useStyles()
    const { t } = useTranslation()
    const { location, history } = useReactRouter()
    const user = useUser()

    const id = useMemo(() => queryString.parse(location.search).id as string, [location.search])

    const [loading, setLoading] = useState(true)

    const [status, setStatus] = useState<'success' | 'notFound'>()
    const [data, setData] = useState<RegistrationLookupResponse>()

    useEffect(() => {
        const fn = async () => {
            const response = await fetch(`${withServerUrl(serverEndPoints.chain.lookup)}?id=${id}`, {
                method: 'get',
                mode: 'cors',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            })

            if (response.status !== 200) {
                setStatus('notFound')
                return
            }

            const result = await response.json()
            setData(result)

            setStatus('success')
        }

        fn()
            .catch(() => setStatus('notFound'))
            .finally(() => setLoading(false))
    }, [id])

    const goHome = useCallback(() => history.push('/'), [history])

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} />

            {loading ? (
                <Loading />
            ) : status === 'notFound' ? (
                <div className={classes.statusContainer}>
                    <MdError className={classes.errorIcon} />
                    <Typography className={classes.title}>{t('errors.documentNotFound')}</Typography>
                </div>
            ) : (
                <div className={classes.content}>
                    <Typography className={classes.title}>{t('common.registrationAttemptInfo')}</Typography>

                    <div className={classes.labelContainer}>
                        <Typography className={classes.label}>{t('common.beneficiaryId')}</Typography>
                        <Typography className={classes.value}>{data?.chainId}</Typography>
                    </div>

                    <div className={classes.labelContainer}>
                        <Typography className={classes.label}>{t('common.createdAt')}</Typography>
                        <Typography className={classes.value}>
                            {moment(data?.createdAt).format('DD/MM/YYYY hh:mm:ss')}
                        </Typography>
                    </div>

                    <div className={classes.labelContainer}>
                        <Typography className={classes.label}>{t('common.project')}</Typography>
                        <Typography className={classes.value}>{data?.projectName}</Typography>
                    </div>

                    <div className={classes.labelContainer}>
                        <Typography className={classes.label}>{t('common.organizationName')}</Typography>
                        <Typography className={classes.value}>{data?.organizationName}</Typography>
                    </div>

                    <div className={classes.labelContainer}>
                        <Typography className={classes.label}>{t('common.status')}</Typography>
                        <Typography
                            style={{ fontSize: 20 }}
                            className={classNames({
                                [classes.value]: true,
                                [classes.duplicated]: data?.status === 'duplicated',
                            })}
                        >
                            {t(`common.${data?.status}`)}
                        </Typography>
                    </div>

                    {user ? (
                        <Button onClick={goHome} className={classes.button} variant="contained" color="primary">
                            {t('common.done')}
                        </Button>
                    ) : null}
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    logo: {
        position: 'absolute',
        width: 164,
        left: 32,
        top: 32,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 24,
        marginBottom: 40,
        marginTop: 40,
    },
    statusContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    errorIcon: {
        fontSize: 54,
        color: extraColors.red,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
    },
    label: {
        fontSize: 12,
        color: theme.palette.primary.main,
        marginBottom: 4,
    },
    value: {
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    duplicated: {
        color: extraColors.red,
    },
    button: {
        marginTop: 16,
    },
}))
