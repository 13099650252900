/* eslint-disable react/jsx-no-bind */
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { UserType } from 'common/models/user'
import { allUserRoles } from 'common/roles/roles'
import { mutate } from 'frontend/API/mutate'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import LabelWithSwitch from 'web/components/LabelWithSwitch'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'

interface Props {
    user: UserType
    close: () => void
}

export default React.memo(UserPermissions)

function UserPermissions(props: Props) {
    const { user, close } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const [roles, setRoles] = useState(user.roles)

    const submit = useCallback(() => {
        setLoading(true)

        mutate
            .update({ collection: 'users', id: user._id, fields: { roles } })
            .then((result) => {
                if (result.result !== 'ok') {
                    alert('error', t('errors.somethingWentWrong'))
                    return
                }

                close()
            })
            .catch((e) => {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            })
            .finally(() => setLoading(false))
    }, [alert, close, roles, setLoading, t, user._id])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.generalPermissions')}</Typography>
            </div>

            <div className={classes.content}>
                {allUserRoles.map((role, idx) => {
                    const checked = roles.includes(role)

                    return (
                        <LabelWithSwitch
                            onChange={(newValue) => {
                                if (newValue && !checked) {
                                    setRoles([...roles, role])
                                }
                                if (!newValue && checked) {
                                    setRoles(roles.filter((x) => x !== role))
                                }
                            }}
                            key={idx}
                            label={t(`roles.${role}`)}
                            checked={checked}
                        />
                    )
                })}

                <div className={classes.actionsContainer}>
                    <Button onClick={close} style={{ marginRight: 16 }} variant="outlined">
                        {t('common.cancel')}
                    </Button>

                    <Button onClick={submit} color="primary" variant="contained">
                        {t('common.save')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 24,
        width: '100%',
    },
}))
