import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { auth } from 'frontend/API/auth'
import queryString from 'query-string'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import top from '../../assets/left-top.png'
import logo from '../../assets/logo.svg'
import bottom from '../../assets/right-bottom.png'

export default React.memo(SetupAccount)

function SetupAccount() {
    const classes = useStyles()
    const { t } = useTranslation()
    const { location, history } = useReactRouter()
    const alert = useAlert()

    const token = useMemo(() => queryString.parse(location.search).token as string, [location.search])

    const [tried, setTried] = useState(false)
    const [loading, setLoading] = useState(false)
    const [trySubmit, setTrySubmit] = useState(false)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const onPassword = useCallback((e) => setPassword(e.target.value), [])
    const onConfirmPassword = useCallback((e) => setConfirmPassword(e.target.value), [])

    const disabled =
        confirmPassword.trim() === '' || password.trim() === '' || confirmPassword !== password || password.length < 8

    const submit = useCallback(() => {
        setTried(true)
        setTrySubmit(true)

        if (disabled) {
            return
        }

        setLoading(true)

        auth.setupPassword({ token, password })
            .then(({ result }) => {
                if (result === 'error') {
                    alert('error', t('errors.somethingWentWrong'))
                    return
                }

                alert('success', t('login.passwordSetupSuccessfully'))
                history.replace('/login')
            })
            .catch((e) => {
                console.error(e)
                alert('error', t('errors.somethingWentWrong'))
            })
            .finally(() => setLoading(false))
    }, [alert, disabled, history, password, t, token])

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} />

            <img src={top} className={classes.top} />
            <img src={bottom} className={classes.bottom} />

            <Form className={classes.content} disabled={disabled && tried} onSubmit={submit}>
                <Typography className={classes.title}>{t('login.setupYourPassword')}</Typography>

                <TextField
                    disabled={loading}
                    value={password}
                    onChange={onPassword}
                    className={classes.password}
                    variant="filled"
                    label={t('common.password')}
                    type="password"
                    autoFocus
                    required
                    helperText={t('login.atLeast8')}
                    error={trySubmit && confirmPassword.length < 8}
                />

                <TextField
                    required
                    disabled={loading}
                    onChange={onConfirmPassword}
                    value={confirmPassword}
                    className={classes.confirmPassword}
                    variant="filled"
                    label={t('common.confirmPassword')}
                    type="password"
                    error={trySubmit && password.length < 8}
                />

                <Button
                    disabled={(disabled && tried) || loading}
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : t('login.updatePassword')}
                </Button>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 32,
        marginBottom: 80,
        textAlign: 'center',
    },
    password: {
        marginBottom: 24,
    },
    confirmPassword: {
        marginBottom: 56,
    },
    submit: {
        height: 40,
    },
    logo: {
        position: 'absolute',
        width: 264,
        left: '50%',
        top: 64,
        transform: 'translate(-60%)',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
}))
