import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { stringToLowerCase } from 'common/utils/stringToLowerCase'
import { auth } from 'frontend/API/auth'
import userIdStream from 'frontend/helpers/userIdStream'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import isEmail from 'web/utils/isEmail'
import storeToken from 'web/utils/storeToken'
import top from '../../assets/left-top.png'
import logo from '../../assets/logo.svg'
import bottom from '../../assets/right-bottom.png'

export default React.memo(Login)

function Login() {
    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { history } = useReactRouter()

    const [tried, setTried] = useState(false)
    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onEmail = useCallback((e) => setEmail(stringToLowerCase(e.target.value)), [])
    const onPassword = useCallback((e) => setPassword(e.target.value), [])

    const inValid = !isEmail(email) || password.trim() === ''

    const submit = useCallback(() => {
        setTried(true)

        if (inValid) {
            return
        }

        setLoading(true)

        auth.login({ email, password })
            .then(({ token, userId, msg }) => {
                if (!token) {
                    if (msg) {
                        alert('error', t(`errors.${msg}`))
                    } else {
                        alert('error', t('errors.emailAndPasswordCombinationIsNotCorrect'))
                    }
                    return
                }


                storeToken(token, userId!)
                userIdStream.value({ loading: false, userId })

                setTimeout(() => {
                    location.reload()
                }, 1000)

                alert('success', t('errors.loggedInSuccessfully'))
            })
            .catch((e) => {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            })
            .finally(() => setLoading(false))
    }, [alert, email, inValid, password, t])

    const navigateToResetPassword = useCallback(() => {
        history.replace('/reset-password')
    }, [history])

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} />

            <img src={top} className={classes.top} />
            <img src={bottom} className={classes.bottom} />

            <Form className={classes.content} disabled={inValid && tried} onSubmit={submit}>
                <Typography className={classes.title}>{t('login.loginToYourAccount')}</Typography>

                <TextField
                    disabled={loading}
                    onChange={onEmail}
                    value={email}
                    className={classes.email}
                    autoFocus
                    variant="filled"
                    label={t('common.email')}
                />

                <TextField
                    disabled={loading}
                    value={password}
                    onChange={onPassword}
                    className={classes.password}
                    variant="filled"
                    label={t('common.password')}
                    type="password"
                />

                <a onClick={navigateToResetPassword} className={classes.forgetPasswordLink}>
                    {t('login.forgetPassword')}
                </a>

                <Button
                    disabled={(inValid && tried) || loading}
                    type="submit"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : t('login.login')}
                </Button>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 32,
        marginBottom: 80,
        textAlign: 'center',
    },
    email: {
        marginBottom: 24,
    },
    password: {
        marginBottom: 16,
    },
    submit: {
        height: 40,
    },
    logo: {
        position: 'absolute',
        width: 264,
        left: '50%',
        top: 64,
        transform: 'translate(-60%)',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    forgetPasswordLink: {
        color: theme.palette.text.primary,
        marginBottom: 34,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))
