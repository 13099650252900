import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import imgage from 'web/assets/404.svg'

export default function Page404() {
    const classes = useStyles()
    const { t } = useTranslation()
    const { replace } = useReactRouter().history

    const onClick = useCallback(() => replace('/'), [replace])

    return (
        <div className={classes.container}>
            <img src={imgage} className={classes.img} />

            <Typography className={classes.text}>{t('common.pageNotFound')}</Typography>

            <Button color="primary" variant="contained" className={classes.button} onClick={onClick}>
                {t('common.goHome')}
            </Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    img: {
        userSelect: 'none',
        pointerEvents: 'none',
        width: 324,
        marginBottom: 24,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 22,
        marginBottom: 24,
    },
    button: {},
}))
