/* eslint-disable react/no-multi-comp */
import { Column, DataTypeProvider } from '@devexpress/dx-react-grid'
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import Typography from '@material-ui/core/Typography'
import { OrganizagtionDashboardTransactionType } from 'common/types/dashboardTypes'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import { CustomTableFilterRow, CustomTableHeaderRow } from 'web/pages/Tables/tableUtils'

export default React.memo(OrganizationTransactions)

function OrganizationTransactions() {
    const classes = useStyles()
    const { t } = useTranslation()

    const [transactions, loading] = useCollection<OrganizagtionDashboardTransactionType>({
        collection: 'organizationDashboardTransactions',
    })


    console.log({ transactions: transactions?.length })

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.transactionId'),
                name: 'transactionId',
                getCellValue: (row: any) => row.transactionId,
            },
            {
                title: t('common.transactionType'),
                name: 'eventName',
                getCellValue: (row: any) => row.eventName,
            },
            {
                title: t('common.createdAt'),
                name: 'createdAt',
                getCellValue: (row: any) => {
                    const date = moment(row.createdAt)
                    if (date.isSame(moment(), 'hour')) {
                        return date.fromNow()
                    }

                    return date.format('DD/MM/YYYY hh:mm:ss')
                },
            },
            {
                title: t('common.project'),
                name: 'projectName',
                getCellValue: (row: any) => row.projectName,
            },

            {
                title: t('common.action'),
                key: '_id',
                name: '_id',
                filteringEnabled: false,٠
                getCellValue: function Actions(row) {
                    return (
                        <span>
                            <a
                                href={`https://shasta.tronscan.org/#/transaction/${row.transactionId}`}
                                className={classes.viewLink}
                                target={'_blank' as any}
                            >
                                {t('common.action')}
                            </a>
                        </span>
                    )
                },
            },
        ]
    }, [classes.viewLink, t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(transactions ?? [], (x) => x.createdAt).reverse()
    }, [transactions])

    return (
        <div className={classes.container}>
            <Animate changeKey={loading}>
                {loading ? (
                    <Loading />
                ) : !rows.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.itemsContainer}>
                        <Typography className={classes.title}>{t('common.latestTransactions')}</Typography>

                        <Paper>
                            <Grid rows={rows} columns={columns}>
                                <DataTypeProvider for={['transactionId']} formatterComponent={TransactionIdComponent} />
                                <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                <DataTypeProvider for={['eventName']} formatterComponent={EventNameComponent} />

                                <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                <TableHeaderRow rowComponent={CustomTableHeaderRow} />
                            </Grid>
                        </Paper>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const TransactionIdComponent = ({ row }: any) => {
    const theme = useTheme()
    return (
        <span title={row.transactionId} style={{ color: theme.palette.primary.main }}>
            {row.transactionId}
        </span>
    )
}

const EventNameComponent = ({ row }: any) => {
    const { t } = useTranslation()
    return <span>{t(`common.${row.eventName}`)}</span>
}

const DateComponent = ({ row }: any) => {
    const isToday = moment(row.createdAt).startOf('day').isSame(moment().startOf('day'))

    if (isToday) {
        const isOneHourBefore = moment(row.createdAt).isBetween(moment(), moment().subtract(1, 'hour'))

        if (isOneHourBefore) {
            return <span style={{ fontSize: 14 }}>{moment(row.createdAt).fromNow()}</span>
        }

        return <span style={{ fontSize: 14 }}>{moment(row.createdAt).calendar()}</span>
    }

    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}

const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        maxWidth: 1000,
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    orgAvatar: {
        width: 34,
        height: 34,
    },
    orgNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    orgName: {
        color: '#FFF',
        margin: '0px 8px',
        fontSize: 14,
        fontWeight: 600,
    },
    title: {
        marginBottom: 8,
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    viewLink: {
        color: theme.palette.primary.light,
    },
}))
