import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    children: React.ReactNode
}

export interface ConfirmOptions {
    title: string
    text: string
    ok?: string
    cancel?: string
}

export type ConfirmContextType = (options: ConfirmOptions) => { promise: Promise<'ok' | 'cancel'> }
export const ConfirmContext = createContext<ConfirmContextType>(null as any)

export const useConfirm = () => useContext(ConfirmContext)

export default React.memo(ConfirmProvider)

function ConfirmProvider({ children }: Props) {
    const { t } = useTranslation()

    const [activeDialog, setActiveDialog] = useState<ConfirmOptions | null>()
    const [open, setOpen] = useState(false)

    const ref = useRef<{ promise: Promise<'ok' | 'cancel'>; resolve: (result: 'ok' | 'cancel') => void }>({} as any)

    const openDialogCb = useCallback((options: ConfirmOptions) => {
        setActiveDialog(options)
        setOpen(true)

        ref.current.promise = new Promise((resolve) => {
            ref.current.resolve = resolve
        })

        return {
            promise: ref.current.promise,
        }
    }, [])

    const cancel = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
        setOpen(false)

        setTimeout(() => {
            setActiveDialog(null)
            ref.current.resolve('cancel')
        }, 300)
    }, [])

    const ok = useCallback(() => {
        setOpen(false)

        setTimeout(() => {
            setActiveDialog(null)
            ref.current.resolve('ok')
        }, 300)
    }, [])

    return (
        <ConfirmContext.Provider value={openDialogCb}>
            <Dialog open={open} onClose={cancel}>
                <DialogTitle>
                    <Typography color="textPrimary" style={{ fontSize: 18, fontWeight: 500 }}>
                        {activeDialog?.title}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>{activeDialog?.text}</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={cancel} color="default">
                        {activeDialog?.cancel ?? t('common.no')}
                    </Button>

                    <Button onClick={ok} color="primary">
                        {activeDialog?.ok ?? t('common.yes')}
                    </Button>
                </DialogActions>
            </Dialog>

            {children}
        </ConfirmContext.Provider>
    )
}
