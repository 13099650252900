import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ProjectType } from 'common/models/project'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaBuilding } from 'react-icons/fa'
import { MdAdd } from 'react-icons/md'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import { useRoles } from 'web/containers/UserProvider'
import CreateProject from './components/CreateProject'
import ProjectItem from './components/ProjectItem'

interface Props {}

export default React.memo(ProjectsList)

function ProjectsList(props: Props) {
    const {} = props

    const classes = useStyles()
    const { t } = useTranslation()
    const roles = useRoles()

    const [search, setSearch] = useState('')

    const [projects, loading] = useCollection<ProjectType>({ collection: 'projects' })

    const list = useMemo(() => {
        const filtered = (projects ?? []).filter((x) => x.name.toLowerCase().includes(search.toLowerCase()))

        return sortBy(filtered, (x) => x.createdAt).reverse()
    }, [projects, search])

    const [createOpen, setCreateOpen] = useState(false)
    const openCreate = useCallback(() => setCreateOpen(true), [])
    const closeCreate = useCallback(() => setCreateOpen(false), [])

    const changeKey = loading + '' + list.length + ''

    const canCreate = roles.includes('admin') || roles.includes('manageProjects')

    return (
        <div className={classes.container}>
            <Dialog open={createOpen} onClose={closeCreate}>
                <CreateProject close={closeCreate} />
            </Dialog>

            <Header
                value={search}
                onChange={setSearch}
                title={t('common.projects')}
                Icon={FaBuilding}
                actions={canCreate ? [{ title: t('common.createProject'), onClick: openCreate, Icon: MdAdd }] : []}
            />

            <Animate changeKey={changeKey}>
                {loading ? (
                    <Loading />
                ) : !list.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemContainer}>
                                {list.map((project, idx) => {
                                    const last = idx === list.length - 1
                                    const first = idx === 0

                                    return <ProjectItem project={project} key={idx} last={last} first={first} />
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
}))
