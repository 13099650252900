import { SubToIdArgs } from 'common/types/subTypes'
import { useEffect, useState } from 'react'
import subToId from './subToId'

export default function useId<V>(args: SubToIdArgs) {
    const { collection, id } = args

    const [value, setValue] = useState<V>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!id) {
            return
        }

        const unsub = subToId<V>({
            data: { id, collection },
            onValue: (val) => {
                setValue(val)
                setLoading(false)
            },
        })

        return () => unsub()
    }, [collection, id])

    return [value, loading] as const
}
