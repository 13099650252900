import { Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { Shadows } from '@material-ui/core/styles/shadows'
import transitions from '@material-ui/core/styles/transitions'
import React, { useMemo } from 'react'

interface Props {
    children: React.ReactNode
}

const direction: 'rtl' | 'ltr' = 'ltr'

export const extraColors = {
    cyan: '#72CEDA',
    blue: '#72AFDA',
    purple: '#9A72DA',
    orange: '#DAC372',
    red: '#DA7272',
    yellow: '#B9DA72',
    green: '#72DAA3',
}

export const useThemeConfig = () => {
    return useMemo(() => {
        const dirHelpers = {
            start: direction === 'ltr' ? 'left' : 'right',
            end: direction === 'ltr' ? 'right' : 'left',
        }

        const originalTheme = createMuiTheme({
            dirHelpers,
            palette: {
                type: 'dark',

                background: { default: '#404546', paper: '#383D3E' },
                action: { hover: '#484C4D', selected: '#505555' },
                divider: '#585C5D',
                primary: { main: '#72DAA3' },

                text: {
                    primary: 'rgba(255, 255, 255, 0.87)',
                    secondary: 'rgba(255, 255, 255, 0.64)',
                    hint: 'rgba(255, 255, 255, 0.48)',
                    disabled: 'rgba(255, 255, 255, 0.32)',
                },
            },

            overrides: {
                MuiBackdrop: {
                    root: {
                        transition: `${transitions.create(['opacity', 'backdrop-filter'], {
                            duration: 200,
                        })} !important`,
                        backdropFilter: 'blur(6px)',
                        WebkitBackdropFilter: 'blur(6px)',
                    },
                },
                MuiDialog: {
                    paper: {
                        borderRadius: 8,
                    },
                },
                MuiInputBase: {
                    root: {
                        direction: 'unset',
                    },
                    input: { direction: direction },
                },
                MuiInputLabel: {
                    root: {
                        transformOrigin: 'top ' + dirHelpers.start,
                    },
                    formControl: {
                        [dirHelpers.end]: 'initial',
                        [dirHelpers.start]: 0,
                    },
                    shrink: {
                        transformOrigin: 'top ' + dirHelpers.start,
                    },
                    outlined: {
                        transform:
                            direction === 'ltr' ? 'translate(14px, 20px) scale(1)' : 'translate(-14px, 20px) scale(1)',
                        '&$marginDense': {
                            transform:
                                direction === 'ltr'
                                    ? 'translate(14px, 12px) scale(1)'
                                    : 'translate(-14px, 12px) scale(1)',
                        },

                        '&$shrink': {
                            transform:
                                direction === 'ltr'
                                    ? 'translate(14px, -6px) scale(0.75)'
                                    : 'translate(-14px, -6px) scale(0.75)',
                        },
                    },
                },
                MuiOutlinedInput: {
                    notchedOutline: {
                        transition: transitions.create(['border-color']),
                    },
                },
                MuiListItem: {
                    root: {
                        textAlign: dirHelpers.start as any,
                    },
                    secondaryAction: {
                        paddingInlineEnd: 48,
                        paddingRight: undefined,
                    },
                },
                MuiListItemSecondaryAction: {
                    root: {
                        right: direction === 'ltr' ? 16 : undefined,
                        left: (direction as any) === 'rtl' ? 0 : undefined,
                    },
                },

                // @ts-ignore
                PrivateNotchedOutline: {
                    legendLabelled: {
                        textAlign: dirHelpers.start,
                    },
                },
                MuiAutocomplete: {
                    endAdornment: {
                        [dirHelpers.end]: 0,
                        [dirHelpers.start]: 'initial !important',
                    },
                    inputRoot: {
                        paddingRight: '8px !important',
                    },
                },
                MuiButton: {
                    startIcon: {
                        marginInlineStart: -4,
                        marginInlineEnd: 8,
                    },
                },
            },
            typography: {
                allVariants: {},
                body1: { direction },
                body2: { direction },
                caption: { direction },
                h1: { direction },
                h2: { direction },
                h3: { direction },
                h4: { direction },
                h5: { direction },
                h6: { direction },
                subtitle1: { direction },
                subtitle2: { direction },
                button: {
                    direction,
                    textTransform: 'capitalize' as const,
                },
                fontFamily: [
                    'Poppins',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Roboto',
                    '"Helvetica Neue"',
                    'DroidKufi',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                ].join(','),
            },
        })

        const shadows = originalTheme.shadows.slice() as Shadows

        shadows[2] = '0px 2px 6px rgba(23, 51, 79, 0.08), 0px 1px 2px rgba(23, 51, 79, 0.12)'
        shadows[4] = '0px 4px 8px rgba(23, 51, 79, 0.1), 0px 2px 4px rgba(23, 51, 79, 0.08)'
        shadows[8] = '0px 8px 16px rgba(5, 19, 20, 0.12), 0px 4px 8px rgba(5, 19, 20, 0.08)'
        shadows[16] = '0px 16px 24px rgba(0, 8, 31, 0.12), 0px 6px 12px rgba(0, 0, 0, 0.08)'
        shadows[24] = '0px 24px 32px rgba(0, 8, 31, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.08)'

        return {
            ...originalTheme,

            sizes: { cards: { user: { width: 240, avatar: 75 } } },
            shadows,
            shape: { borderRadius: 4 },

            breakpoints: {
                ...originalTheme.breakpoints,
                values: { xs: 0, sm: 600, md: 960, lg: 1400, xl: 1920 },
                laptop: originalTheme.breakpoints.between(960, 1400),
            },
        } as Theme
    }, [])
}

export default function ThemeProvider({ children }: Props) {
    const theme = useThemeConfig()

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
