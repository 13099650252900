import { makeStyles } from '@material-ui/core/styles'
import { OrganizationRegistrationsResponse } from 'common/types/dashboardTypes'
import { dashboard } from 'frontend/API/dashboard'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDashboard } from 'react-icons/ai'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import { useOrganization } from 'web/containers/UserProvider'
import ChartViewer from '../../Project/components/ChartViewer'
import OrganizationTransactions from './OrganizationTransactions'

export default React.memo(OrganizationDashboard)

function OrganizationDashboard() {
    const classes = useStyles()
    const organization = useOrganization()
    const orgId = organization?._id
    const { t } = useTranslation()

    const [data, setData] = useState<OrganizationRegistrationsResponse>()

    useEffect(() => {
        if (!orgId) {
            return
        }

        const fn = async () => {
            const res = await dashboard.getOrgDashboardData({ orgId })
            setData(res)
            return
        }

        fn()
    }, [orgId])

    if (!data) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Header title={t('common.overview')} Icon={AiFillDashboard} />

                <ChartViewer
                    title={'Registrations'}
                    width={1000}
                    height={300}
                    data={data.registraions as any}
                    dataColumns={data.projects}
                    dataColumnKey="projectId"
                />

                <OrganizationTransactions />
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        marginRight: 5,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 24,
        paddingBottom: 100,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },
}))
