import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import { RouteType } from '../../Router/routes'

interface Props {
    tab: RouteType
    idx: number
}

export default React.memo(NavMenuItem)

function NavMenuItem(props: Props) {
    const { tab, idx } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { history, location } = useReactRouter()

    const selected = useMemo(() => tab.path && location.pathname.includes(tab.key), [
        location.pathname,
        tab.key,
        tab.path,
    ])
    const onClick = useCallback(() => history.push(tab.path!), [history, tab.path])

    return (
        <div
            onClick={selected ? undefined : onClick}
            className={classNames({
                [classes.navItem]: true,
                [classes.firstItem]: idx === 0,
                [classes.selected]: selected,
                [classes.hover]: !selected,
            })}
        >
            <tab.icon className={classes.icon} />
            <Typography className={classes.itemText}>{t(tab.title)}</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    navItem: {
        height: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        borderRadius: 4,
        userSelect: 'none',
        transition: theme.transitions.create('background'),
    },
    firstItem: {
        marginTop: 16,
    },
    icon: {
        color: theme.palette.text.secondary,
        fontSize: 22,
        marginRight: 16,
        marginLeft: 8,
        transition: theme.transitions.create('color'),
    },
    itemText: {
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontWeight: 500,
        transition: theme.transitions.create('color'),
    },
    selected: {
        background: theme.palette.action.selected,
        '& $icon': {
            color: theme.palette.text.primary,
        },
        '& $itemText': {
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
    },
    hover: {
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
}))
