import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { UserType } from 'common/models/user'
import React, { useCallback, useState } from 'react'
import Avatar from 'web/components/Avatar'
import ViewUser from '../ViewUser'

interface Props {
    user: UserType
    last?: boolean
    first?: boolean
}

export default React.memo(UserItem)

function UserItem(props: Props) {
    const { user, first, last } = props

    const classes = useStyles()

    const [viewOpen, setViewOpen] = useState(false)
    const onClick = useCallback(() => setViewOpen(true), [])
    const closeView = useCallback(() => setViewOpen(false), [])

    return (
        <>
            <Dialog open={viewOpen} onClose={closeView}>
                <ViewUser close={closeView} user={user} />
            </Dialog>

            <div
                onClick={onClick}
                className={classNames({
                    [classes.item]: true,
                    [classes.lastItem]: last,
                    [classes.firstItem]: first,
                })}
            >
                <Avatar src={user.photo} name={user.name} id={user._id} className={classes.avatar} />

                <div className={classes.textContainer}>
                    <Typography className={classes.title}>{user.name}</Typography>
                    <Typography className={classes.id}>{user.email}</Typography>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        height: 64,
        width: '100%',
        borderRadius: 8,
        marginBottom: 16,
        display: 'flex',
        userSelect: 'none',
        transition: theme.transitions.create('background'),
        cursor: 'pointer',
        alignItems: 'center',

        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    avatar: {
        margin: '0px 8px',
        height: 48,
        width: 48,
        marginRight: 16,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 8,
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    lastItem: {
        marginBottom: 56,
    },
    id: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    firstItem: {
        marginTop: 40,
    },
}))
