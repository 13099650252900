import { SocketRequest, SocketResponse } from 'common/types/socketTypes'
import createPromise from 'common/utils/createPromise'
import { auth } from 'frontend/API/auth'
import io, { io as ioNotDefault, Socket } from 'socket.io-client'
import { v4 as uuid } from 'uuid'
import { serverEndPoints } from '../../common/api/serverEndpoints'
import { getGlobalConfig } from '../config/globalConfig'
import responseHander from './socket/responseHander'

function connect() {
    const { promise, resolve } = createPromise()

    let socket: Socket | undefined = undefined

    ;(async () => {
        const usedToken = (await getGlobalConfig().getTokens()).token

        if (!usedToken) {
            return
        }

        const newSocket = ((io as any) as typeof ioNotDefault)(serverEndPoints.socket, {
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 2000,
            timeout: 75 * 1000,
            query: { h: uuid() },
            auth: { token: usedToken },
        })

        socket = newSocket

        newSocket.on('unauthenticated', () => {
            auth.logout()
        })

        newSocket.on('message', (res: SocketResponse) => {
            responseHander(res)
        })

        resolve()
    })()

    return {
        send: async (req: SocketRequest) => {
            await promise
            socket!.emit('message', req)
        },
    }
}

export const socketHelpers = connect()
