import { serverEndPoints } from 'common/api/serverEndpoints'
import { DeleteArgs, InsertArgs, MutateResponse, UpdateArgs } from 'common/types/mutateTypes'
import sendRequest from './sendRequest'

export const mutate = {
    insert: (args: InsertArgs) => sendRequest<MutateResponse>({ args, endpoint: serverEndPoints.mutate.insert }),

    update: (args: UpdateArgs) => sendRequest<MutateResponse>({ args, endpoint: serverEndPoints.mutate.update }),

    delete: (args: DeleteArgs) => sendRequest<MutateResponse>({ args, endpoint: serverEndPoints.mutate.delete }),
}
