import { RoleType } from 'common/roles/roles'
import { IconType } from 'react-icons'
import { AiFillDashboard } from 'react-icons/ai'
import { FaBuilding, FaProjectDiagram } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { MdGroup, MdImportExport, MdList, MdSearch, MdSettings } from 'react-icons/md'
import BeneficiariesTable from 'web/pages/Beneficiary/BeneficiariesTable'
import BeneficiaryInfoView from 'web/pages/Beneficiary/BeneficiaryInfoView'
import CheckBeneficiaryStatus from 'web/pages/Beneficiary/CheckBeneficiaryStatus'
import OrganizationSettings from 'web/pages/Organization/OrganizationSettings'
import OrganizationsList from 'web/pages/Organization/OrganizationsList'
import OrganizationDashboard from 'web/pages/Organization/overview/OrganizationDashboard'
import ProjectDashboard from 'web/pages/Project/ProjectDashboard'
import ProjectsList from 'web/pages/Project/ProjectsList'
import ProjectUsersList from 'web/pages/Project/ProjectUsersList'
import ProjectBeneficiariesTable from 'web/pages/Tables/ProjectBeneficiariesTable'
import ProjectTransactionsTable from 'web/pages/Tables/ProjectTransactionsTable'
import RegistrationsTable from 'web/pages/Tables/RegistrationsTable'
import UsersList from 'web/pages/User/UsersList'

export interface RouteType {
    title: string
    path?: string
    icon: IconType
    roles: RoleType[]
    exact?: boolean
    component: React.ComponentType<any>
    key: string
}

export const LAYOUT_ROUTES: RouteType[] = [
    {
        title: 'common.organizations',
        path: '/orgs',
        icon: FaBuilding,
        roles: ['superAdmin'],
        component: OrganizationsList,
        key: '/orgs',
    },

    {
        title: 'common.overview',
        path: '/overview',
        icon: AiFillDashboard,
        roles: ['user', 'manageProjects'],
        component: OrganizationDashboard,
        key: '/overview',
    },
    {
        title: 'common.users',
        path: '/users',
        icon: MdGroup,
        roles: ['user', 'manageUsers'],
        component: UsersList,
        key: '/users',
    },
    {
        title: 'common.projects',
        path: '/projects',
        icon: FaProjectDiagram,
        roles: ['user'],
        component: ProjectsList,
        exact: true,
        key: '/projects',
    },
    {
        title: 'common.beneficiaries',
        path: '/beneficiaries',
        icon: HiUserGroup,
        key: 'beneficiaries',
        roles: ['user', 'manageProjects'],
        exact: true,
        component: BeneficiariesTable,
    },

    {
        title: 'common.settings',
        path: '/settings',
        icon: MdSettings,
        key: 'settings',
        roles: ['user', 'admin'],
        exact: true,
        component: OrganizationSettings,
    },
]

export const PROJECT_ROUTES: RouteType[] = [
    {
        title: 'common.overview',
        path: '/overview',
        icon: AiFillDashboard,
        roles: [],
        component: ProjectDashboard,
        key: 'overview',
    },
    {
        title: 'common.projectUsers',
        path: '/users',
        icon: MdGroup,
        roles: ['user', 'manageProjects'],
        component: ProjectUsersList,
        key: 'users',
    },
    {
        title: 'common.registrationAttempts',
        path: '/registrations',
        icon: MdList,
        roles: ['user', 'manageProjects'],
        component: RegistrationsTable,
        key: 'registrations',
    },
    {
        title: 'common.projectBeneficiaries',
        path: '/project-beneficiaries',
        icon: HiUserGroup,
        roles: ['user', 'manageProjects'],
        component: ProjectBeneficiariesTable,
        key: 'beneficiaries',
    },
    {
        title: 'common.allTransactions',
        path: '/allTransactions',
        icon: MdImportExport,
        key: 'allTransactions',
        roles: ['user'],
        exact: true,
        component: ProjectTransactionsTable,
    },
    {
        title: 'common.checkStatus',
        path: '/checkStatus',
        icon: MdSearch,
        roles: ['user', 'manageProjects'],
        component: CheckBeneficiaryStatus,
        key: 'checkStatus',
    },
]

export const BENEFICIARY_ROUTES = [
    {
        title: 'common.beneficiariesInfo',
        path: '/beneficiary/:chainId',
        icon: HiUserGroup,
        key: 'beneficiary',
        roles: ['user'],
        exact: true,
        component: BeneficiaryInfoView,
    },
]
