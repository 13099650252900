import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { ProjectUserType } from 'common/models/projectUser'
import { mutate } from 'frontend/API/mutate'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiUserRemove } from 'react-icons/hi'
import { MdSettings } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import Menu from 'web/components/Menu'
import { useAlert } from 'web/containers/AlertProvider'
import { useConfirm } from 'web/containers/ConfirmProvider'
import { useLoading } from 'web/containers/LoadingProvider'
import ViewProjectUser from './ViewProjectUser'

interface Props {
    projectUser: ProjectUserType
    last?: boolean
    first?: boolean
}

export default React.memo(ProjectUserItem)

function ProjectUserItem(props: Props) {
    const { projectUser, first, last } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const confirm = useConfirm()
    const { setLoading } = useLoading()
    const alert = useAlert()

    const [viewOpen, setViewOpen] = useState(false)
    const onClick = useCallback(() => setViewOpen(true), [])
    const closeView = useCallback(() => setViewOpen(false), [])

    const optionsRef = useRef(null as any)

    const [menuOpen, setMenuOpen] = useState(false)
    const closeMenu = useCallback(() => setMenuOpen(false), [])
    const openMenu = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        setMenuOpen(true)
    }, [])

    const onRemove = useCallback(async () => {
        closeMenu()

        const res = await confirm({
            title: t('common.removeUserFromProject'),
            text: t('common.removeFromProjectConfirm'),
        }).promise

        if (res === 'ok') {
            setLoading(true)

            mutate
                .delete({ collection: 'projectUsers', id: projectUser._id })
                .then(() => alert('success', t('common.removedSuccessfully')))
                .catch((e) => {
                    console.log(e)
                    alert('error', t('errors.somethingWentWrong'))
                })
                .finally(() => setLoading(false))
        }
    }, [alert, closeMenu, confirm, projectUser._id, setLoading, t])

    return (
        <>
            <Dialog open={viewOpen} onClose={closeView}>
                <ViewProjectUser close={closeView} projectUser={projectUser} />
            </Dialog>

            <Menu onClose={closeMenu} open={menuOpen} anchorEl={optionsRef}>
                <div className={classes.menuContent}>
                    <div style={{ width: '100%', height: 16 }} />

                    <ButtonBase onClick={onRemove} className={classes.button}>
                        <HiUserRemove className={classes.icon} />
                        <Typography className={classes.text}>{t('common.removeUserFromProject')}</Typography>
                    </ButtonBase>

                    <div style={{ width: '100%', height: 8 }} />
                </div>
            </Menu>

            <div
                onClick={onClick}
                className={classNames({
                    [classes.item]: true,
                    [classes.lastItem]: last,
                    [classes.firstItem]: first,
                })}
            >
                <Avatar
                    src={projectUser.photo}
                    name={projectUser.name}
                    id={projectUser._id}
                    className={classes.avatar}
                />

                <div className={classes.textContainer}>
                    <Typography className={classes.title}>{projectUser.name}</Typography>
                    <Typography className={classes.id}>{projectUser.email}</Typography>
                </div>

                <IconButton ref={optionsRef as any} onClick={openMenu} className={classes.settingsButton}>
                    <MdSettings className={classes.settingsIcon} />
                </IconButton>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        height: 64,
        width: '100%',
        borderRadius: 8,
        marginBottom: 16,
        display: 'flex',
        userSelect: 'none',
        transition: theme.transitions.create('background'),
        cursor: 'pointer',
        alignItems: 'center',
        position: 'relative',

        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    avatar: {
        margin: '0px 8px',
        height: 48,
        width: 48,
        marginRight: 16,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 8,
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    lastItem: {
        marginBottom: 56,
    },
    id: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    firstItem: {
        marginTop: 40,
    },
    settingsButton: {
        position: 'absolute',
        right: 16,
        zIndex: 100,
    },
    settingsIcon: {
        color: theme.palette.text.secondary,
    },
    menuContent: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 40,
        marginBottom: 8,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 16,
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: 22,
        marginRight: 16,
        marginLeft: 16,
    },
}))
