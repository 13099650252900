import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import noop from 'lodash/noop'
import React, { createContext, useContext, useMemo, useState } from 'react'

interface Props {
    children: React.ReactNode
}

export type LoadingContextType = { loading: boolean; setLoading: (value: boolean) => void }
const LoadingContext = createContext<LoadingContextType>(null as any)

export const useLoading = () => useContext(LoadingContext)

function LoadingProvider(props: Props) {
    const { children } = props

    const classes = useStyle()

    const [loading, setLoading] = useState(false)

    const value: LoadingContextType = useMemo(() => ({ loading, setLoading }), [loading])

    return (
        <LoadingContext.Provider value={value}>
            <Dialog onClose={noop} open={loading} classes={{ paper: classes.paper }}>
                <CircularProgress size={64} color="primary" />
            </Dialog>

            {children}
        </LoadingContext.Provider>
    )
}

export default React.memo(LoadingProvider)

const useStyle = makeStyles(() => ({
    paper: {
        background: 'transparent',
        boxShadow: 'none',
        width: 500,
        height: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
