import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { useTranslation } from 'react-i18next'
import { MdInfo } from 'react-icons/md'



interface Props {
    scop: string
}

function ScopItem(props: Props) {

    const { scop } = props
    const classes = useStyles()
    const { t } = useTranslation()


    return (<div className={classes.container}>
        <div className={classes.infoHeader}>
            <MdInfo className={classes.infoICon} />
            <Typography className={classes.scopTitle} >{t(`scopes.${scop}`)}</Typography>
        </div>
        <Typography className={classes.scopDescription}>{t(`scopes.${scop}Des`)}</Typography>
    </div>)

}


export default React.memo(ScopItem)

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 500,

        marginTop: 24,
    },

    infoHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoICon: {
        color: theme.palette.text.primary,
    },
    scopTitle: {
        marginLeft: 16,
        color: theme.palette.text.primary,
    },
    scopDescription: {
        color: theme.palette.text.secondary,
        marginLeft: 32,
        marginTop: 8,
        fontSize: 12,
    },
}))
