import { GridExporter } from '@devexpress/dx-react-grid-export'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch from '@material-ui/core/Switch/Switch'
import { ProjectType } from 'common/models/project'
import { dashboard } from 'frontend/API/dashboard'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdImportExport, MdSettings } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import { useRoles } from 'web/containers/UserProvider'
import ProjectLiveTransactionsTable from '../Tables/ProjectLiveTransactionsTable'
import ProjectRegistrations from '../Tables/ProjectRegistrations'
import ChartViewer from './components/ChartViewer'
import ConfigureProject from './components/ConfigureProject'

interface Props {
    project: ProjectType
}

export type DashboardDataType = { date: Date } & { [orgId: string]: string }

export default React.memo(ProjectDashboard)

function ProjectDashboard(props: Props) {
    const { project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { history } = useReactRouter()
    const roles = useRoles()

    const exportRegistrationsRef = useRef<typeof GridExporter>()
    const exportDuplicatedRegistrationsRef = useRef<typeof GridExporter>()

    const [displayDataAsTable, setDisplayDataAsTable] = useState(false)

    const [registrations, setregistrations] = useState<DashboardDataType[] | undefined>()
    const [duplicatedRegistrations, setDuplicatedRegistrations] = useState<DashboardDataType[] | undefined>()

    const [projectOrganaizations, setProjectOrganaizations] = useState<{ orgId: string; name: string }[] | undefined>(
        undefined
    )

    const [configOpen, setConfigOpen] = useState(false)
    const openConfig = useCallback(() => setConfigOpen(true), [])
    const closeConfig = useCallback(() => setConfigOpen(false), [])

    const goBack = useCallback(() => history.push('/projects'), [history])
    const canConfig = roles.includes('admin') || roles.includes('manageProjects')

    useEffect(() => {
        if (!project || (registrations && duplicatedRegistrations)) {
            return
        }
        const fun = async () => {
            const result = await dashboard.caculateRegistrations({ projectId: project._id })
            setregistrations(result.registraions as any)
            setDuplicatedRegistrations(result.duplications as any)
            setProjectOrganaizations(result.orgs)
        }
        fun()
    }, [project, project._id, duplicatedRegistrations, registrations])

    const handelDisply = useCallback((e: any, val: boolean) => {
        setDisplayDataAsTable(val)
    }, [])

    const epxortData = useCallback(() => {
        if (!exportRegistrationsRef || !exportDuplicatedRegistrationsRef) {
            return
        }

        exportRegistrationsRef.current?.exportGrid({})
        exportDuplicatedRegistrationsRef.current?.exportGrid({})
    }, [exportRegistrationsRef, exportDuplicatedRegistrationsRef])

    const onSave = useCallback((workbook) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'registrationAttempts.xlsx')
        })
    }, [])

    if (!registrations || !projectOrganaizations || !duplicatedRegistrations) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <Dialog open={configOpen} onClose={closeConfig}>
                <ConfigureProject project={project} close={closeConfig} />
            </Dialog>

            <Header
                title={`${project.name} - ${t('common.overview')}`}
                actions={[
                    { title: t('common.configureProject'), onClick: openConfig, Icon: MdSettings, hide: !canConfig },
                    {
                        title: t('common.export'),
                        onClick: epxortData,
                        Icon: MdImportExport,
                        hide: !displayDataAsTable,
                    },
                ]}
                onBackPress={goBack}
            />

            <div className={classes.content}>
                <div className={classes.displyChangerContainer}>
                    <div className={classes.displySwitchTextContainer}>
                        <span className={classes.displySwitchText}> Display As Table </span>
                    </div>
                    <Switch color="primary" checked={displayDataAsTable} onChange={handelDisply} />
                </div>

                {displayDataAsTable ? (
                    <ProjectRegistrations
                        registrations={registrations}
                        projectOrganaizations={projectOrganaizations}
                        tableOf="registrations"
                        exporterRef={exportRegistrationsRef}
                        onSave={onSave}
                    />
                ) : (
                    <ChartViewer
                        title={'Registrations'}
                        width={1000}
                        height={300}
                        data={registrations}
                        dataColumns={projectOrganaizations}
                        dataColumnKey="orgId"
                    />
                )}

                {displayDataAsTable ? (
                    <ProjectRegistrations
                        registrations={duplicatedRegistrations}
                        projectOrganaizations={projectOrganaizations}
                        tableOf="duplications"
                        exporterRef={exportDuplicatedRegistrationsRef}
                        onSave={onSave}
                    />
                ) : (
                    <ChartViewer
                        title={'Duplications'}
                        width={1000}
                        height={300}
                        data={duplicatedRegistrations}
                        dataColumns={projectOrganaizations}
                        dataColumnKey="orgId"
                    />
                )}

                <ProjectLiveTransactionsTable projectId={project._id} width={1000} />
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        marginRight: 5,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 24,
        paddingBottom: 100,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },

    displySwitchText: {
        color: '#FFF',
    },
    displyChangerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 964,
    },
    displySwitchTextContainer: { display: 'flex' },
}))
