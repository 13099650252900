import MaterialMenu from '@material-ui/core/Menu'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import React, { useMemo } from 'react'

interface Props {
    anchorEl: React.MutableRefObject<HTMLElement | null>
    open: boolean
    onClose: () => void
    children: React.ReactNode
    keepMounted?: boolean
    className?: string
    left?: number // can be used to adjust the menu
    top?: number // can be used to adjust the menu
}

const defaultTransition = { exit: 100, appear: 300, enter: 300 }

export default React.memo(Menu)

function Menu(props: Props) {
    const { onClose, open, anchorEl, children, keepMounted, className, left, top } = props

    const classes = useStyles()

    const elm = anchorEl.current?.getBoundingClientRect()
    const anchorPosition = useMemo(() => {
        const elementPositionLeft = elm?.left ?? 0
        const elementPositionTop = elm?.top ?? 0

        const leftResult = elementPositionLeft + (left ?? 0)

        return {
            left: leftResult,
            top: elementPositionTop + (top ?? 0),
        }
    }, [elm, left, top])

    const menuClasses = useMemo(() => {
        return {
            paper: classNames(classes.container, className),
            list: classes.list,
        }
    }, [className, classes.container, classes.list])

    return (
        <MaterialMenu
            classes={menuClasses}
            keepMounted={keepMounted}
            open={open}
            onClose={onClose}
            anchorPosition={anchorPosition}
            anchorReference="anchorPosition"
            transitionDuration={defaultTransition}
        >
            {children}
        </MaterialMenu>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[24],
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
        outline: 'none !important',
    },
    list: {
        padding: 0,
        outline: 'none !important',
    },
}))
