import values  from "lodash/values"


export const extraColors = {
    cyan: '#72CEDA',
    blue: '#72AFDA',
    purple: '#9A72DA',
    orange: '#DAC372',
    red: '#DA7272',
    yellow: '#B9DA72',
    green: '#72DAA3',
}
const AvatarColors = [...values(extraColors)]

export const hashCode = function (str: string) {
    let hash = 0,
        i,
        chr,
        len
    if (str.length == 0) return hash
    for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export const getColorFromId = (id: string) => {
    const hashResult = hashCode(id)
    return AvatarColors[Math.abs(hashResult % AvatarColors.length)]
}
