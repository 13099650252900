/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ProjectType } from 'common/models/project'
import { RegistrationAttemptType } from 'common/models/registrationAttempt'
import { saveAs } from 'file-saver'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { MdImportExport } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import { CustomDragComponent, CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from './tableUtils'

interface Props {
    project: ProjectType
}

export default React.memo(RegistrationsTable)

function RegistrationsTable(props: Props) {
    const { project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { push } = useReactRouter().history

    const exporterRef = useRef<typeof GridExporter>()

    const goBack = useCallback(() => push('/projects'), [push])

    const [registrations, loading] = useCollection<RegistrationAttemptType>({
        collection: 'registrationAttempts',
        projectId: project._id,
    })

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.beneficiaryId'),
                name: 'chainId',
                getCellValue: (row) => row.chainId,
            },
            {
                title: t('common.createdAt'),
                name: 'createdAt',
                getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss'),
            },
            {
                title: t('common.status'),
                name: 'status',
                getCellValue: (row) => row.status,
            },
        ]
    }, [t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(registrations ?? [], (x) => x.createdAt).reverse()
    }, [registrations])

    const startExport = useCallback(() => exporterRef.current?.exportGrid({}), [])
    const onSave = useCallback((workbook) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'registrationAttempts.xlsx')
        })
    }, [])

    return (
        <div className={classes.container}>
            <Header
                actions={
                    registrations?.length
                        ? [{ title: t('common.export'), onClick: startExport, Icon: MdImportExport }]
                        : []
                }
                onBackPress={goBack}
                title={`${project.name} - ${t('common.registrationAttempts')}`}
            />

            <Animate changeKey={loading}>
                {loading ? (
                    <Loading />
                ) : !rows.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemsContainer}>
                                <GridExporter onSave={onSave} ref={exporterRef} columns={columns} rows={rows} />

                                <Paper>
                                    <Grid rows={rows} columns={columns}>
                                        <DataTypeProvider
                                            for={['beneficiaryId']}
                                            formatterComponent={BeneficiaryIdComponent}
                                        />
                                        <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                        <DataTypeProvider for={['status']} formatterComponent={StatusComponent} />

                                        <DragDropProvider containerComponent={CustomDragComponent} />
                                        <SortingState />

                                        <IntegratedSorting />

                                        <PagingState defaultCurrentPage={0} pageSize={24} />
                                        <IntegratedPaging />

                                        <FilteringState />
                                        <IntegratedFiltering />

                                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                                        <PagingPanel containerComponent={CustomPagingPanel} />
                                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const BeneficiaryIdComponent = ({ row }: any) => <span>{row.chainId}</span>
const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}

const StatusComponent = ({ row }: any) => {
    const { t } = useTranslation()

    const color = row.status == 'duplicated' ? '#DA7272' : '#72DAA3'
    return <span style={{ color, fontSize: 14 }}>{t(`common.${row.status}`)}</span>
}

const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
}))
