import { getIdTopic } from 'common/live/topics'
import { SubToIdArgs } from 'common/types/subTypes'
import Stream from 'common/utils/Stream'
import { socketHelpers } from '../config/socket'
import { topicsToStreams } from '../registry/requestsRegistry'
import unsubFromTopic from './unsubFromTopic'

interface Args<V> {
    data: SubToIdArgs
    onValue: (value: V) => void
}

export default function subToId<V>(args: Args<V>): () => void {
    const { onValue, data } = args
    const { collection, id } = data

    const topic = getIdTopic({ collection, id })

    if (!topicsToStreams.has(topic)) {
        topicsToStreams.set(topic, new Set())
    }

    const stream = new Stream<V>()

    const streamSet = topicsToStreams.get(topic)!
    streamSet.add(stream)

    const unSub = stream.subscribe((value) => {
        onValue(value)
    })

    socketHelpers.send({ method: 'subToId', data: { collection, id } })

    return () => {
        unSub()

        const streamSet = topicsToStreams.get(topic)

        if (streamSet) {
            streamSet.delete(stream)

            if (!streamSet.size) {
                topicsToStreams.delete(topic)

                unsubFromTopic(topic)
            }
        }
    }
}
