/* eslint-disable react/jsx-no-bind */
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Alert from '@material-ui/lab/Alert'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { v4 as uuid } from 'uuid'

interface Props {
    children: React.ReactNode
}

type AlertType = 'error' | 'warning' | 'info' | 'success'
export type AlertContextType = (type: AlertType, text: string) => void
const AlertContext = createContext<AlertContextType>(null as any)

export const useAlert = () => useContext(AlertContext)

interface AlertObject {
    id: string
    type: AlertType
    text: string
    open: boolean
}

const ALERT_TIME = 4000

function AlertProvider(props: Props) {
    const { children } = props

    const classes = useStyle()

    const [alerts, setAlerts] = useState<AlertObject[]>([])

    const value: AlertContextType = useCallback((type, text) => {
        const id = uuid()

        setTimeout(() => {
            setAlerts((old) => old.filter((x) => x.id !== id))
        }, ALERT_TIME)

        setTimeout(() => {
            setAlerts((old) => {
                const idx = old.findIndex((x) => x.id === id)
                const oldAlert = old.find((x) => x.id === id)

                if (idx !== -1 && oldAlert) {
                    return [...old.slice(0, idx), { ...oldAlert!, open: false }, ...old.slice(idx + 1)]
                }

                return old
            })
        }, ALERT_TIME - 400)

        setAlerts((old) => {
            return [...old, { id, text, type, open: true }]
        })
    }, [])

    return (
        <AlertContext.Provider value={value}>
            {alerts.map((alert) => {
                const onClick = () => setAlerts((old) => old.filter((x) => x.id !== alert.id))

                return (
                    <Collapse key={alert.id} in={alert.open}>
                        <Alert
                            className={classes.alert}
                            severity={alert.type}
                            action={
                                <IconButton color="inherit" size="small" onClick={onClick}>
                                    <MdClose fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {alert.text}
                        </Alert>
                    </Collapse>
                )
            })}

            {children}
        </AlertContext.Provider>
    )
}

export default React.memo(AlertProvider)

const useStyle = makeStyles(() => ({
    alert: {
        minWidth: 300,
        maxWidth: 500,
        position: 'fixed',
        zIndex: 9999,
        bottom: 24,
        left: 24,
    },
}))
