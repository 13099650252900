import { v4 as uuid } from 'uuid'
import { socketHelpers } from '../config/socket'
import { requestsToPromises } from '../registry/requestsRegistry'

export default function unsubFromTopic(topic: string) {
    return new Promise<void>((resolve, reject) => {
        const requestId = uuid()

        requestsToPromises.set(requestId, { resolve, reject })

        socketHelpers.send({ method: 'unsubFromTopic', topic, requestId })
    })
}
