import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ProjectType } from 'common/models/project'
import { CheckStatusResponse } from 'common/types/chainTypes'
import { useFormik } from 'formik'
import { api } from 'frontend/API/api'
import genAccountFromSeed from 'frontend/utils/genAccountFromSeed'
import getDefaultStandardInfo from 'frontend/utils/standards/getDefaultStandardInfo'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import Loading from 'web/components/Loading'
import { useAlert } from 'web/containers/AlertProvider'
import Header from 'web/containers/Layout/Header'
import { extraColors } from 'web/containers/ThemeProvider'
import * as yup from 'yup'

export default React.memo(CheckBeneficiaryStatus)

interface Props {
    project: ProjectType
}

function CheckBeneficiaryStatus(props: Props) {
    const { project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { push } = useReactRouter().history
    const theme = useTheme()
    const alert = useAlert()

    const [loading, setLoading] = useState(false)

    const goBack = useCallback(() => push('/projects'), [push])

    const [id, setId] = useState('')
    const [result, setResult] = useState<CheckStatusResponse | undefined>(undefined)

    const form = useFormik({
        initialValues: {
            name: '',
            lname: '',
            father_name: '',
            mother_name: '',
            gender: 'male',
            gv_doc: 'national_id',
            id_number: '',
        },

        validationSchema: getSchema(),

        onSubmit: async (values) => {
            setLoading(true)

            try {
                const standardInfo = getDefaultStandardInfo(values)
                const id = genAccountFromSeed(standardInfo).address.base58 as string

                const result = await api.checkStatus({ id, projectId: project._id })

                setId(id)
                setResult(result)

                if (result.result === 'notFound') {
                    alert('error', t('common.beneficiaryDoesNotExist'))
                }
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        },
    })

    const formValid = form.isValid && form.values.name!.trim() !== ''

    const onDone = useCallback(() => {
        setId('')
        setResult(undefined)
    }, [])

    return (
        <div className={classes.container}>
            <Header title={`${project.name} - ${t('common.checkStatus')}`} onBackPress={goBack} />

            <div className={classes.content}>
                <div className={classes.innerContent}>
                    <Animate changeKey={loading + (result?.result ?? '')}>
                        <div className={classes.itemsContainer}>
                            {loading ? (
                                <Loading />
                            ) : result && result.result === 'registered' ? (
                                <>
                                    <Typography className={classes.title}>{t('common.beneficiaryStatus')}</Typography>

                                    <Typography className={classes.label}>{t('common.beneficiaryId')}</Typography>
                                    <Typography className={classes.value}>{id}</Typography>

                                    <Typography className={classes.label}>{t('common.registeredBy')}</Typography>
                                    <Typography className={classes.value}>{result.registeredBy.orgName}</Typography>

                                    <Typography className={classes.label}>{t('common.orgId')}</Typography>
                                    <Typography className={classes.value}>{result.registeredBy.orgChainId}</Typography>

                                    <Typography style={{ marginBottom: 16 }} className={classes.label}>
                                        {t('common.registrationAttempts')}
                                    </Typography>

                                    {result.attempts.map((at, idx) => {
                                        const valid = at.status === 'valid'

                                        return (
                                            <div key={idx} className={classes.attemptContainer}>
                                                <div className={classes.flex}>
                                                    <Typography className={classes.attemptLabel}>
                                                        {t('common.orgId') + ' :'}
                                                    </Typography>
                                                    <Typography className={classes.attemptValue}>
                                                        {at.orgChainId}
                                                    </Typography>
                                                </div>

                                                <div className={classes.flex}>
                                                    <Typography className={classes.attemptLabel}>
                                                        {t('common.status') + ' :'}
                                                    </Typography>

                                                    <Typography
                                                        style={{
                                                            color: valid ? theme.palette.primary.main : extraColors.red,
                                                        }}
                                                        className={classes.attemptValue}
                                                    >
                                                        {t(`common.${at.status}`)}
                                                    </Typography>
                                                </div>

                                                <div className={classes.flex}>
                                                    <Typography className={classes.attemptLabel}>
                                                        {t('common.date') + ' :'}
                                                    </Typography>
                                                    <Typography className={classes.attemptValue}>
                                                        {moment(at.date).format('DD/MM/YYYY hh:mm:ss')}
                                                    </Typography>
                                                </div>

                                                <Divider className={classes.divider} />
                                            </div>
                                        )
                                    })}

                                    <Button
                                        onClick={onDone}
                                        className={classes.submitButton}
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                    >
                                        {t('common.done')}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography className={classes.title}>{t('common.checkStatus')}</Typography>

                                    <div className={classes.twoFieldsContainer}>
                                        <TextField
                                            autoFocus
                                            value={form.values.name}
                                            onChange={form.handleChange('name')}
                                            style={{ marginRight: 24 }}
                                            label={t('common.beneficiaryFirstName')}
                                            variant="filled"
                                        />
                                        <TextField
                                            value={form.values.lname}
                                            onChange={form.handleChange('lname')}
                                            label={t('common.beneficiaryLastName')}
                                            variant="filled"
                                        />
                                    </div>

                                    <div className={classes.twoFieldsContainer}>
                                        <TextField
                                            value={form.values.father_name}
                                            onChange={form.handleChange('father_name')}
                                            style={{ marginRight: 24 }}
                                            label={t('common.fatherName')}
                                            variant="filled"
                                        />
                                        <TextField
                                            value={form.values.mother_name}
                                            onChange={form.handleChange('mother_name')}
                                            label={t('common.motherName')}
                                            variant="filled"
                                        />
                                    </div>

                                    <TextField
                                        value={form.values.gender}
                                        onChange={form.handleChange('gender')}
                                        className={classes.field}
                                        fullWidth
                                        select
                                        variant="filled"
                                        label={t('common.gender')}
                                        required
                                    >
                                        {genderOptions.map((opt) => {
                                            return (
                                                <MenuItem key={opt.value} value={opt.value}>
                                                    {t(opt.title)}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>

                                    <TextField
                                        value={form.values.gv_doc}
                                        onChange={form.handleChange('gv_doc')}
                                        className={classes.field}
                                        fullWidth
                                        select
                                        variant="filled"
                                        label={t('common.govDoc')}
                                        required
                                    >
                                        {govOptions.map((opt) => {
                                            return (
                                                <MenuItem key={opt.value} value={opt.value}>
                                                    {t(opt.title)}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>

                                    {form.values.gv_doc === 'not_existed' ? null : (
                                        <TextField
                                            value={form.values.id_number}
                                            onChange={form.handleChange('id_number')}
                                            label={t('common.idNumber')}
                                            variant="filled"
                                            className={classes.field}
                                        />
                                    )}

                                    <Button
                                        onClick={form.submitForm}
                                        disabled={!formValid}
                                        className={classes.submitButton}
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                    >
                                        {t('common.check')}
                                    </Button>
                                </>
                            )}
                        </div>
                    </Animate>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: '100%',
        maxWidth: 600,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
        minHeight: 500,
    },
    twoFieldsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
    },
    field: {
        marginBottom: 24,
    },
    submitButton: {
        marginTop: 40,
    },
    label: {
        color: theme.palette.primary.main,
        fontSize: 12,
        marginBottom: 2,
    },
    value: {
        color: theme.palette.text.primary,
        fontSize: 16,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 24,
        marginBottom: 40,
    },
    attemptContainer: {
        display: 'flex',
        marginBottom: 24,
        flexDirection: 'column',
    },
    divider: {},
    flex: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    attemptLabel: {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: 16,
    },
    attemptValue: {
        fontSize: 16,
        color: theme.palette.text.primary,
    },
}))

const getSchema = () => {
    return yup.object().shape({
        name: yup.string().required(),
        lname: yup.string().required(),
        father_name: yup.string().required(),
        mother_name: yup.string().required(),
        gender: yup.string().required(),
        gv_doc: yup.string().required(),
        id_number: yup.string(),
    })
}

const genderOptions = [
    { value: 'male', title: 'common.male' },
    { value: 'female', title: 'common.female' },
]

const govOptions = [
    { value: 'national_id', title: 'common.national_id' },
    { value: 'passport', title: 'common.passport' },
    { value: 'marriage_doc', title: 'common.marriage_doc' },
    { value: 'driving_license', title: 'common.driving_license' },
    { value: 'civil_reg_doc', title: 'common.civil_reg_doc' },
    { value: 'not_existed', title: 'common.not_existed' },
]
