import { registerGlobalConfig } from 'frontend/config/globalConfig'

registerGlobalConfig({
    getTokens: async () => {
        const token = window.localStorage.getItem('token')
        const userId = window.localStorage.getItem('userId')

        return { token, userId }
    },

    clearToken: async () => window.localStorage.clear(),
})
