import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { auth } from 'frontend/API/auth'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaUserAlt } from 'react-icons/fa'
import { FiLock, FiLogOut } from 'react-icons/fi'
import useRouter from 'use-react-router'
import { useConfirm } from 'web/containers/ConfirmProvider'
import { useUser } from 'web/containers/UserProvider'
import ViewUser from 'web/pages/User/ViewUser'

interface Props {
    close: () => void
}

export default React.memo(UserMenu)

function UserMenu(props: Props) {
    const { close } = props
    const { history } = useRouter()

    const classes = useStyles()
    const { t } = useTranslation()
    const confirm = useConfirm()
    const user = useUser()

    const logoutClick = useCallback(async () => {
        close()
        const res = await confirm({ title: t('common.logout'), text: t('common.logoutConfirm') }).promise

        if (res === 'ok') {
            auth.logout()
        }
    }, [close, confirm, t])

    const [profileOpen, setProfileOpen] = useState(false)
    const openProfile = useCallback(() => setProfileOpen(true), [])
    const closeProfile = useCallback(() => setProfileOpen(false), [])

    const goToChangePassword = useCallback(() => {
        history.push('/change-password')
    }, [history])

    return (
        <>
            <Dialog open={profileOpen} onClose={closeProfile}>
                <ViewUser user={user} noEdit close={closeProfile} />
            </Dialog>

            <div style={{ width: '100%', height: 16 }} />

            <ButtonBase onClick={openProfile} className={classes.button}>
                <FaUserAlt className={classes.icon} />
                <Typography className={classes.text}>{t('common.profile')}</Typography>
            </ButtonBase>

            <ButtonBase onClick={goToChangePassword} className={classes.button}>
                <FiLock className={classes.icon} />
                <Typography className={classes.text}>{t('common.changeMyPassword')}</Typography>
            </ButtonBase>

            <ButtonBase onClick={logoutClick} className={classes.button}>
                <FiLogOut className={classes.icon} />
                <Typography className={classes.text}>{t('common.logout')}</Typography>
            </ButtonBase>

            <div style={{ width: '100%', height: 8 }} />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 40,
        marginBottom: 8,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 16,
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: 22,
        marginRight: 16,
        marginLeft: 16,
    },
}))
