import { serverEndPoints } from 'common/api/serverEndpoints'
import { BeneficiaryInfoArgs, BeneficiaryInfoResponse } from 'common/types/BeneficiaryInfoTypes'
import {
    CheckRegistrationArgs,
    CheckRegistrationResponse,
    CheckStatusArgs,
    CheckStatusResponse,
    GetOrgInfoByChainIdArgs,
    GetOrgInfoByChainIdResponse,
} from 'common/types/chainTypes'
import sendRequest from './sendRequest'

export const api = {
    getOrgInfoByChainId: (args: GetOrgInfoByChainIdArgs) =>
        sendRequest<GetOrgInfoByChainIdResponse>({ args, endpoint: serverEndPoints.chain.getOrgInfoByChainId }),

    checkRegistration: (args: CheckRegistrationArgs) =>
        sendRequest<CheckRegistrationResponse>({ args, endpoint: serverEndPoints.chain.checkIfRegistrationValid }),

    checkStatus: (args: CheckStatusArgs) =>
        sendRequest<CheckStatusResponse>({ args, endpoint: serverEndPoints.chain.checkStatus }),

    getBeneficiaryInfo: (args: BeneficiaryInfoArgs) =>
        sendRequest<BeneficiaryInfoResponse>({ args, endpoint: serverEndPoints.api.getBeneficiaryInfo }),
}
