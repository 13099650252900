/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { flatMap, sortBy } from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from 'web/components/Avatar'
import { DashboardDataType } from '../Project/ProjectDashboard'
import { CustomDragComponent, CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from './tableUtils'

interface Props {
    registrations: DashboardDataType[]
    projectOrganaizations: {
        orgId: string
        name: string
    }[]
    tableOf: 'duplications' | 'registrations'
    exporterRef: typeof GridExporter | any
    onSave: (workbook: any) => void
}

export default React.memo(ProjectRegistrations)

const convertDataToTableReadableValue = (dataRows: DashboardDataType[]) => {
    return flatMap(
        dataRows.map((data) => {
            const dataKeys = Object.keys(data).filter((x) => x !== 'date')

            return dataKeys.map((key) => {
                return { createdBy: key, createdAt: data.date, amount: data[key] == '0.1' ? '0' : data[key] }
            })
        })
    )
}

function ProjectRegistrations(props: Props) {
    const { registrations, projectOrganaizations, tableOf, exporterRef, onSave } = props

    const classes = useStyles()
    const { t } = useTranslation()

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.numberOf', { status: tableOf }),
                name: 'amount',
                getCellValue: (row) => row.amount,
            },
            {
                title: t('common.createdAt'),
                name: 'createdAt',
                getCellValue: (row) => moment(row.date).format('DD/MM/YYYY'),
            },
            {
                title: t('common.createdBy'),
                name: 'createdBy',
                getCellValue: (row) => row.createdBy,
            },
        ]
    }, [t, tableOf])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(convertDataToTableReadableValue(registrations) ?? [], (x) => x.createdAt).reverse()
    }, [registrations])

    const CreatedByComponent = useCallback(
        ({ row }: any) => {
            const orgName = projectOrganaizations.find((x) => x.orgId === row.createdBy)?.name ?? ''

            return (
                <div className={classes.orgNameContainer}>
                    <Avatar id={row.createdBy} square name={orgName} className={classes.orgAvatar} />
                    <span className={classes.orgName}>{orgName}</span>
                </div>
            )
        },
        [classes.orgAvatar, classes.orgName, classes.orgNameContainer, projectOrganaizations]
    )

    const setRef = useCallback((ref: any) => (exporterRef.current = ref), [exporterRef])

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <h3 className={classes.tableTitle}>{t(`common.${tableOf}`)}</h3>
                <div className={classes.innerContent}>
                    <div className={classes.itemsContainer}>
                        <GridExporter onSave={onSave} ref={setRef as any} columns={columns} rows={rows} />

                        <Paper>
                            <Grid rows={rows} columns={columns}>
                                <DataTypeProvider for={['beneficiaryId']} formatterComponent={BeneficiaryIdComponent} />
                                <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                <DataTypeProvider for={['createdBy']} formatterComponent={CreatedByComponent} />

                                <DragDropProvider containerComponent={CustomDragComponent} />
                                <SortingState />

                                <IntegratedSorting />

                                <PagingState defaultPageSize={5} defaultCurrentPage={0} />
                                <IntegratedPaging />

                                <IntegratedFiltering />

                                <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                <PagingPanel containerComponent={CustomPagingPanel} pageSizes={[5, 10, 15]} />
                                <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                            </Grid>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    )
}

const BeneficiaryIdComponent = ({ row }: any) => <span>{row.chainId}</span>

const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY')}</span>
}

const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        padding: 16,
        backgroundColor: theme.palette.background.default,
        borderRadius: 4,
        margin: '24px 0px',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
        flexDirection: 'column',
    },
    innerContent: {
        width: '100%',
        maxWidth: 943,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    orgAvatar: {
        width: 34,
        height: 34,
    },
    tableTitle: {
        color: '#FFF',
        width: '100%',
        margin: '8px 0px 24px  2px',
    },
    orgNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    orgName: {
        color: '#FFF',
        margin: '0px 8px',
        fontSize: 14,
        fontWeight: 600,
    },
}))
