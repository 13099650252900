import React, { useCallback } from 'react'

interface Props {
    className?: string
    children: React.ReactNode
    onSubmit?: () => void
    disabled?: boolean
}

export default React.memo(Form)

function Form(props: Props) {
    const { className, children, onSubmit, disabled } = props

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.stopPropagation()
            e.preventDefault()

            if (disabled) {
                return
            }

            onSubmit?.()
        },
        [disabled, onSubmit]
    )

    return (
        <form noValidate onSubmit={handleSubmit} className={className}>
            {children}
        </form>
    )
}
