import { OrganizationType } from 'common/models/organization'
import { UserType } from 'common/models/user'

export const allUserRoles: RoleType[] = ['manageUsers', 'manageProjects']
export const allProjectRoles: RoleType[] = ['registerBeneficiary']

export const allRoles: RoleType[] = [...allUserRoles, 'admin', 'user']

export type RoleType = 'superAdmin' | 'admin' | 'user' | 'registerBeneficiary' | 'manageUsers' | 'manageProjects'

export const getUserRoles = (user: UserType | undefined, org: OrganizationType | undefined): RoleType[] => {
    if (!user) return []
    if (!org) return []

    return [...user.roles]
}
