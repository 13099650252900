import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import React, { useCallback } from 'react'

interface Props {
    label: string
    checked: boolean
    onChange: (value: boolean) => void
    noMargin?: boolean
}

export default React.memo(LabelWithSwitch)

function LabelWithSwitch(props: Props) {
    const { label, noMargin, checked, onChange } = props

    const classes = useStyles()

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.checked)
        },
        [onChange]
    )

    return (
        <div className={classes.container} style={{ marginBottom: noMargin ? undefined : 24 }}>
            <Typography className={classes.label}>{label}</Typography>

            <Switch color="primary" checked={checked} onChange={handleChange} className={classes.value} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        fontSize: 16,
        color: theme.palette.primary.main,
        flex: 1,
        fontWeight: 500,
    },
    value: {
        fontSize: 16,
        color: theme.palette.text.primary,
    },
}))
