/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
    DragDropProvider,
    Grid,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import { ProjectDashboardTransactionType } from 'common/types/dashboardTypes'
import { saveAs } from 'file-saver'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import { CustomDragComponent, CustomTableFilterRow, CustomTableHeaderRow } from './tableUtils'

interface Props {
    projectId: string
    width: number
}

export default React.memo(ProjectTransactionsTable)

function ProjectTransactionsTable(props: Props) {
    const { projectId, width } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { history } = useReactRouter()

    const exporterRef = useRef<typeof GridExporter>()

    const [transactions, loading] = useCollection<ProjectDashboardTransactionType>({
        collection: 'projectDashboardTransactions',
        projectId,
    })

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.transactionId'),
                name: 'transactionId',
                getCellValue: (row: ProjectDashboardTransactionType) => row.transactionId,
            },
            {
                title: t('common.transactionType'),
                name: 'eventName',
                getCellValue: (row: ProjectDashboardTransactionType) => row.eventName,
            },
            {
                title: t('common.createdAt'),
                name: 'createdAt',
                getCellValue: (row: ProjectDashboardTransactionType) => {
                    const date = moment(row.createdAt)
                    if (date.isSame(moment(), 'hour')) {
                        return date.fromNow()
                    }

                    return date.format('DD/MM/YYYY hh:mm:ss')
                },
            },
            {
                title: t('common.createdBy'),
                name: 'orgId',
                getCellValue: (row: ProjectDashboardTransactionType) => row.orgName,
            },

            {
                title: t('common.actions'),
                key: '_id',
                name: '_id',
                filteringEnabled: false,
                getCellValue: function Actions(row) {
                    return (
                        <span>
                            <a
                                href={`https://shasta.tronscan.org/#/transaction/${row.transactionId}`}
                                className={classes.viewLink}
                                target={'_blank' as any}
                            >
                                {t('common.viewTX')}
                            </a>
                        </span>
                    )
                },
            },
        ]
    }, [classes.viewLink, t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(transactions ?? [], (x) => x.createdAt).reverse()
    }, [transactions])

    // const startExport = useCallback(() => exporterRef.current?.exportGrid({}), [])
    const onSave = useCallback((workbook) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'transactions.xlsx')
        })
    }, [])

    const goToAllTransactions = useCallback(() => history.push(`/projects/${projectId}/allTransactions`), [
        history,
        projectId,
    ])

    return (
        <div className={classes.container}>
            <Animate changeKey={loading}>
                {loading ? (
                    <Loading />
                ) : !rows.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.itemsContainer} style={{ width }}>
                        <div className={classes.tableHeaderOptions}>
                            <h3 className={classes.itemTitle}>{t('common.latestTransactions')}</h3>
                            <a onClick={goToAllTransactions} className={classes.goToAll}>
                                {t('common.viewAllTransactions')}
                            </a>
                        </div>

                        <GridExporter onSave={onSave} ref={exporterRef} columns={columns} rows={rows} />

                        <Paper>
                            <Grid rows={rows} columns={columns}>
                                <DataTypeProvider for={['transactionId']} formatterComponent={TransactionIdComponent} />
                                <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />
                                <DataTypeProvider for={['eventName']} formatterComponent={EventNameComponent} />

                                <DragDropProvider containerComponent={CustomDragComponent} />
                                <SortingState />

                                <IntegratedSorting />

                                <PagingState defaultCurrentPage={0} pageSize={10} />
                                <IntegratedPaging />

                                <FilteringState />
                                <IntegratedFiltering />

                                <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                                <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                            </Grid>
                        </Paper>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const TransactionIdComponent = ({ row }: any) => {
    const theme = useTheme()
    return (
        <span title={row.transactionId} style={{ color: theme.palette.primary.main }}>
            {row.transactionId}
        </span>
    )
}

const EventNameComponent = ({ row }: any) => {
    const { t } = useTranslation()
    return <span>{t(`common.${row.eventName}`)}</span>
}

const DateComponent = ({ row }: any) => {
    const isToday = moment(row.createdAt).startOf('day').isSame(moment().startOf('day'))

    if (isToday) {
        const isOneHourBefore = moment(row.createdAt).isBetween(moment(), moment().subtract(1, 'hour'))

        if (isOneHourBefore) {
            return <span style={{ fontSize: 14 }}>{moment(row.createdAt).fromNow()}</span>
        }

        return <span style={{ fontSize: 14 }}>{moment(row.createdAt).calendar()}</span>
    }

    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}

const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    orgAvatar: {
        width: 34,
        height: 34,
    },
    orgNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    orgName: {
        color: '#FFF',
        margin: '0px 8px',
        fontSize: 14,
        fontWeight: 600,
    },
    itemTitle: {
        color: '#FFF',
        margin: 0,
    },
    goToAll: {
        color: theme.palette.primary.light,
        fontSize: 14,
        cursor: 'pointer',
    },
    tableHeaderOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 0px',
    },
    viewLink: {
        color: theme.palette.primary.light,
    },
}))
