/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import {
    DragDropProvider,
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BeneficiaryType } from 'common/models/beneficiary'
import { saveAs } from 'file-saver'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { HiUserGroup } from 'react-icons/hi'
import { MdImportExport } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import {
    CustomDragComponent,
    CustomPagingPanel,
    CustomTableFilterRow,
    CustomTableHeaderRow,
} from '../Tables/tableUtils'

export default React.memo(BeneficiariesTable)

function BeneficiariesTable() {
    const classes = useStyles()
    const { t } = useTranslation()

    const exporterRef = useRef<typeof GridExporter>()

    const [beneficiaries, loading] = useCollection<BeneficiaryType>({ collection: 'beneficiaries' })

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: t('common.beneficiaryId'),
                name: 'chainId',
                getCellValue: (row: BeneficiaryType) => row.chainId,
            },
            {
                title: t('common.registeredAt'),
                name: 'createdAt',
                getCellValue: (row: BeneficiaryType) => moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss'),
            },
            {
                title: t('common.action'),
                key: '_id',
                name: '_id',
                filteringEnabled: false,
                getCellValue: function Actions(row) {
                    return (
                        <span>
                            <Link to={`/beneficiary/${row.chainId}`} type="primary" className={classes.viewLink}>
                                {t('common.view')}
                            </Link>
                        </span>
                    )
                },
            },
        ]
    }, [classes.viewLink, t])

    const tableRowComponent = useCallback((props) => <CustomTableFilterRow {...props} />, [])

    const rows = useMemo(() => {
        return sortBy(beneficiaries ?? [], (x) => x.createdAt).reverse()
    }, [beneficiaries])

    const startExport = useCallback(() => exporterRef.current?.exportGrid({}), [])
    const onSave = useCallback((workbook) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'beneficiaries.xlsx')
        })
    }, [])

    return (
        <div className={classes.container}>
            <Header
                actions={
                    beneficiaries?.length
                        ? [{ title: t('common.export'), onClick: startExport, Icon: MdImportExport }]
                        : []
                }
                title={t('common.beneficiaries')}
                Icon={HiUserGroup}
            />

            <Animate changeKey={loading}>
                {loading ? (
                    <Loading />
                ) : !rows.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemsContainer}>
                                <GridExporter onSave={onSave} ref={exporterRef} columns={columns} rows={rows} />

                                <Paper>
                                    <Grid rows={rows} columns={columns}>
                                        <DataTypeProvider
                                            for={['beneficiaryId']}
                                            formatterComponent={BeneficiaryIdComponent}
                                        />
                                        <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />

                                        <DragDropProvider containerComponent={CustomDragComponent} />
                                        <SortingState />

                                        <IntegratedSorting />

                                        <PagingState defaultCurrentPage={0} pageSize={24} />
                                        <IntegratedPaging />

                                        <FilteringState />
                                        <IntegratedFiltering />

                                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                                        <PagingPanel containerComponent={CustomPagingPanel} />
                                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const BeneficiaryIdComponent = ({ row }: any) => <span>{row.chainId}</span>
const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: 'calc(100% - 80px)',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        marginTop: 64,
    },

    table: {
        minWidth: 650,
    },
    tableHeader: { background: '#303536' },
    tableCell: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },
    viewLink: {
        color: theme.palette.primary.light,
    },
}))
