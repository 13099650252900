import { RoleType } from 'common/roles/roles'

export default function hasPermissions(userRoles: RoleType[], required: RoleType[]): boolean {
    if (!required.length) {
        return true
    }

    if (required.includes('user') && userRoles.includes('superAdmin')) {
        return false
    }
    if (required.includes('superAdmin') && userRoles.includes('user')) {
        return false
    }

    if (userRoles.includes('admin')) {
        return true
    }

    const withoutDefault = required.filter((x) => x !== 'user')

    if (!withoutDefault.length) {
        return true
    }

    return required.every((r) => userRoles.includes(r))
}
