import BN from 'bn.js'
import { ec as EC } from 'elliptic'
// @ts-ignore
import TronWeb from 'tronweb'

export default function genAccountFromSeed(data: string) {
    const ec = new EC('secp256k1')
    const ns2 = ec.n!.sub(new BN(2))
    let priKeyHex = ''
    let hash = data

    // eslint-disable-next-line no-constant-condition
    while (true) {
        hash = ec.hash().update(hash).digest('hex')
        const priv = new BN(hash, 16)
        if (priv.cmp(ns2) > 0) {
            continue
        }
        priv.iaddn(1)
        priKeyHex = priv.toString(16)
        break
    }

    while (priKeyHex.length < 64) {
        priKeyHex = `0${priKeyHex}`
    }
    const priKeyBytes = TronWeb.utils.code.hexStr2byteArray(priKeyHex)
    const pubKeyBytes = TronWeb.utils.crypto.getPubKeyFromPriKey(priKeyBytes)
    const addressBytes = TronWeb.utils.crypto.getAddressFromPriKey(priKeyBytes)
    const privateKey = TronWeb.utils.bytes.byteArray2hexStr(priKeyBytes)
    const publicKey = TronWeb.utils.bytes.byteArray2hexStr(pubKeyBytes)
    return {
        privateKey,
        publicKey,
        address: {
            base58: TronWeb.utils.crypto.getBase58CheckAddress(addressBytes),
            hex: TronWeb.utils.bytes.byteArray2hexStr(addressBytes),
        },
    }
}
