interface GlobalConfig {
    getTokens: () => Promise<{ token: string | null; userId: string | null }>
    clearToken: () => Promise<void>
}

let globalConfig: GlobalConfig = {} as any

export const registerGlobalConfig = (conf: GlobalConfig) => (globalConfig = conf)

export const getGlobalConfig = () => globalConfig
