export default {
    common: {
        FeedbackCaseCreation: 'Feedback case created',
        FeedbackCaseResolved: 'Feedback case resolved',
        search: 'Search',
        viewTx: 'View Tx',
        email: 'Email',
        password: 'Password',
        organizations: 'Organizations',
        projects: 'Projects',
        users: 'Users',
        AidDistribution: 'Aid Distribution',
        beneficiaries: 'Beneficiaries',
        viewTX: 'View Tx',
        settings: 'Settings',
        FeedbackCaseRegisteration: 'Feedback Case Registeration',
        FeedbackCaseResoltion: 'Feedback Case Resolve',
        listIsEmpty: 'List is empty!',
        code: 'Verification Code',
        createOrg: 'Create Organization',
        organizationInfo: 'Organization Info',
        organizationName: 'Organization Name',
        organizationEmail: 'Organization Email',
        organizationPhoneNumber: 'Organization Phone Number',
        organizationID: 'Organization ID',
        uploadLogo: 'Upload logo',
        orgName: 'Organization Name',
        orgEmail: 'Organization Email',
        orgPhoneNumber: 'Organization Phone Number',
        adminName: 'Admin Name',
        adminEmail: 'Admin Email',
        cancel: 'Cancel',
        create: 'Create',
        edit: 'Edit',
        editOrg: 'Edit Organization',
        action: 'Action',
        view: 'View',
        update: 'Update',
        logout: 'Logout',
        profile: 'Profile',
        yes: 'Yes',
        no: 'No',
        logoutConfirm: 'Are you sure you want to logout?',
        confirmPassword: 'Confirm Password',
        createUser: 'Create User',
        userInfo: 'User Info',
        userName: 'User Name',
        userEmail: 'User Email',
        userPhoneNumber: 'User Phone Number',
        active: 'Active',
        editUser: 'Edit User',
        uploadPhoto: 'Upload Photo',
        initialPassword: 'Initial Password',
        initialPasswordHint: `An setup email will also be sent to the user's email`,
        pageNotFound: 'Page Not Found',
        goHome: 'Go Home',
        createProject: 'Create Project',
        projectName: 'Project Name',
        newPassword: 'New Password',
        ConfirmPassword: 'Confirm Password',
        overview: 'Overview',
        configureProject: 'Configure Project',
        duplicationSettings: 'Duplication Settings',
        ignore: 'Ignore',
        insideThisProject: 'Inside This Project',
        inAllProject: 'In All Project',
        beneficiaryIdStandard: 'Beneficiary ID Standard',
        firstAndLastName: 'First And Last Name',
        nameWithId: 'Name With ID',
        addOrganization: 'Add Organization',
        organizationId: 'Organization ID',
        permissions: 'Permissions',
        generalPermissions: 'General Permissions',
        save: 'Save',
        changeMyPassword: 'Change Password',
        currentPassword: 'Current Password',
        projectUsers: 'Project Users',
        addUserToProject: 'Add User To Project',
        user: 'User',
        userAlreadyInProject: 'User Already In Project',
        projectPermissions: 'Project Permissions',
        removeUserFromProject: 'Remove User From Project',
        removeFromProjectConfirm: 'Are you sure you want to remove this user from the project?',
        removedSuccessfully: 'Removed Successfully',
        add: 'Add',
        organizationSettings: 'Organization Settings',
        publicSettings: 'Public Settings',
        displayOrgName: 'Display Organization Name',
        displayOrgNameDesc: `If on, organization's name will be displayed in public pages.`,
        aliasInfo: 'Alias Info',
        organizationAlias: 'Organization Alias',
        discard: 'Discard',
        updatedSuccessfully: 'Updated Successfully',
        projectBeneficiaries: 'Project Beneficiaries',
        beneficiaryId: 'Beneficiary ID',
        registeredBy: 'Registered By',
        status: 'Status',
        searchPlaceholder: 'Search',
        dragColumn: 'Drag Column',
        projectId: 'Project ID',
        projectIdHint: 'This ID should be passed to the mobile application via data collection tools forms.',
        addOrgToProject: 'Add Org To Project',
        registrationAttempts: 'Registration Attempts',
        createdAt: 'Created At',
        valid: 'Valid',
        duplicated: 'Duplicated',
        export: 'Export',
        registeredAt: 'Registered At',
        registrationAttemptInfo: 'Registration Attempt Info',
        project: 'Project',
        done: 'Done',
        checkStatus: 'Check Status',
        next: 'Next',
        orgId: 'Org ID',
        deactivate: 'Deactivate',
        activate: 'Activate',
        sendSetupEmail: 'Send Setup Email',
        resendSetupEmail: 'Resend Setup Email',
        resendSetupEmailText: 'Are you sure you want to resend setup email?',
        sentSuccessfully: 'Sent Successfully',
        deactivateOrg: 'Deactivate Organization',
        deactivateOrgText: 'Are you sure you want to deactivate this organization?',
        deactivatedSuccessfully: 'Deactivated Successfully',
        activateOrg: 'Activate Organization',
        activateOrgText: 'Are you sure you want to activate this organization?',
        activatedSuccessfully: 'Activated Successfully',
        disabled: 'Disabled',
        numberOf: 'Number of {{status}}',
        createdBy: 'Created by',
        duplications: 'Duplications',
        registrations: 'Registrations',
        allTransactions: 'All Transactions',
        transactionType: 'Transaction Type',
        transactionId: 'Transaction ID',
        OwnerRegisteration: 'Owner Registeration',
        ActorRegisteration: 'Actor Registeration',
        ActorAddedToProject: 'Actor Added To Project',
        ProjectRegisteration: 'Project Registeration',
        BeneficiaryAddedToProject: 'Beneficiary Added To Project',
        defaultStandard: 'Default Standard',
        beneficiaryFirstName: 'Beneficiary First Name',
        beneficiaryLastName: 'Beneficiary Last Name',
        fatherName: 'Father Name',
        motherName: 'Mother Name',
        check: 'Check',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        govDoc: 'Governmental documents',

        national_id: 'National ID',
        passport: 'Passport',
        marriage_doc: 'Marriage Document',
        driving_license: 'Driving License',
        civil_reg_doc: 'Civil Registry Document',
        not_existed: 'Others / Not existed',
        idNumber: 'ID Number',
        beneficiaryStatus: 'Beneficiary Status',
        date: 'Date',
        beneficiaryDoesNotExist: 'Beneficiary Does Not Exist',
        dataNotEnough: 'Insufficient information to calculate chart',
        latestTransactions: 'Latest Transactions',
        viewAllTransactions: 'View All Transactions',
        import: 'Import',
        topOrgsByRegistrations: 'Top Organizations By Registrations',
        importSuccessfully: 'Beneficiaries imported successfully',
        importTemplate: 'Import template',
        beneficiarieInfo: 'Beneficiarie Info View',
        hideInPublicPage: 'Hide Organization In Public Page',
        hideInPublicPageDesc: `If on, organization's  will be hidden in public pages.`,
    },
    login: {
        loginToYourAccount: 'Login to Your Account',
        login: 'Login',
        updatePassword: 'Update Password',
        setupYourPassword: 'Setup your Password',
        atLeast8: 'Password must be at least 8 characters',
        passwordSetupSuccessfully: 'Password was setup successfully',
        forgetPassword: 'Forget Password?',
        resetPassword: 'Reset Password',
        enterVerificationCode: 'Enter the Verification Code we just sent you on Your Email',
        enterYourNewPassword: 'Enter Your New Password',
        changePassword: 'Change Your Current Password',
    },
    errors: {
        somethingWentWrong: 'Something Went Wrong',
        emailAndPasswordCombinationIsNotCorrect: 'Email and password combination is not correct',
        loggedInSuccessfully: 'Logged in successfully',
        permissionDenied: 'Permission Denied',
        emailDuplicated: 'Email Already Exists',
        instituteDoesNotExist: 'Institute Does Not Exist',
        documentNotFound: 'Document Not Found',
        orgIsDisabled: 'Organization Is Disabled',
        enteredAccountNotFound: `The email you entered was not found`,
        verificationCodeNotValid: 'The verification code you entered was incorrect',
        passwordUpdateFailed: 'password update failed',
    },
    roles: {
        registerBeneficiary: 'Register Beneficiary',
        createProject: 'Create Project',
        manageUsers: 'Manage Users',
        manageProjects: 'Manage Projects',
    },

    auth: {
        passwordUpdatedSuccessfully: 'Password Updated Successfully',
    },
    scopes: {
        caseCreation: 'Case Creation',
        caseResolve: 'Case Resolve',
        caseCreationDes:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus sed massa nunc, et elementum purus dignissim nulla aliquam. Posuere sit tempor tellus quam. Purus non mattis habitant habitant tristique.',
        caseResolveDes:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus sed massa nunc, et elementum purus dignissim nulla aliquam. Posuere sit tempor tellus quam. Purus non mattis habitant habitant tristique.',
    },
}
