import { DefaultStandardInfo } from 'frontend/utils/standards/getDefaultStandardInfo'
import { replaceString } from 'frontend/utils/standards/replaces'
import { Dictionary, map } from 'lodash'

export type ErrorInfo = {
    errorType: 'emptyValue' | 'duplicated' | 'notValid'
    row: number
    column: string
    description: string
}

const gv_doc_Valus = ['national_id', 'passport', 'marriage_doc', 'driving_license', 'civil_reg_doc', 'not_existed']
const genderValues = ['male', 'female']

export const excelColumnCharacters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'W',
    'Y',
    'Z',
]

export const arrayToString = (array: string[]) => {
    return array.join('').trim()
}

export type StandardInfoWithLocation = DefaultStandardInfo & {
    location: { row: number; column: string }
}

export async function excelImportBeneficiariesValid(data: Dictionary<StandardInfoWithLocation[]>) {
    let errors: ErrorInfo[] = []

    await Promise.all(
        map(data, (items) => {
            if (items.length && items.length != 1) {
                items.map((dupItem) => {
                    let duplicationDescription = `Row ${dupItem.location.row} duplicated With Row`

                    items
                        .filter((x) => x.location.row !== dupItem.location.row)
                        .map((r) => {
                            duplicationDescription = duplicationDescription + ` ${r.location.row + ''} `
                            return
                        })

                    errors = [
                        ...errors,
                        {
                            errorType: 'duplicated',
                            row: dupItem.location.row,
                            description: duplicationDescription,
                            column: dupItem.location.column,
                        },
                    ]
                })
            }

            const item = items[0]
            const keys = Object.keys(item)
                .map((x) => x)
                .filter((key) => key !== 'location')

            keys.map((key) => {
                const itemKeyValue =
                    (item as any)[key] && typeof (item as any)[key] === 'number'
                        ? (item as any)[key] + ''
                        : (item as any)[key]

                if (!itemKeyValue || (itemKeyValue ?? '')?.trim() === '') {
                    errors = [
                        ...errors,
                        {
                            errorType: 'emptyValue',
                            row: item.location.row,
                            column: key,
                            description: `column ${key} is Empty in Row ${item.location.row} `,
                        },
                    ]
                }
            })

            if (!gv_doc_Valus.map((x) => replaceString(x)).includes(item.gv_doc)) {
                errors = [
                    ...errors,
                    {
                        errorType: 'emptyValue',
                        row: item.location.row,
                        description: `invalid gv_doc Value in Row ${item.location.row} `,
                        column: item.location.column,
                    },
                ]
            }

            if (!genderValues.includes(item.gender)) {
                errors = [
                    ...errors,
                    {
                        errorType: 'emptyValue',
                        row: item.location.row,
                        description: `invalid gender Value in Row ${item.location.row} `,
                        column: item.location.column,
                    },
                ]
            }
        })
    )
    return errors
}
