import { Button, Divider } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { OAuthClientType } from 'common/models/oAuth'
import { oAuth } from 'frontend/API/oAuth'
import sendRequest from 'frontend/API/sendRequest'
import React, { useCallback, useEffect, useState } from 'react'
import { MdLink } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import Loading from 'web/components/Loading'
import { useOrganization, useUser } from 'web/containers/UserProvider'
import ScopItem from './Components/ScopItem'

export default React.memo(OAuth)

function OAuth() {
    const classes = useStyles()
    const user = useUser()
    const org = useOrganization()

    const url = new URL(location.href);
    const clientId = url.searchParams.get("clientId");
    const redirectUri = url.searchParams.get("redirectUri");
    const state = url.searchParams.get("state");



    const [client, setClient] = useState<OAuthClientType | undefined>()

    const   getTokenCode = useCallback(async () => {
        if (!client) {
            return
        }

        await sendRequest<any>({ args: {}, extraHeaders: { clientSecret: client.clientSecret }, endpoint: `oauth/authorize?client_id=${clientId}&redirectUri=${redirectUri}&state=${state}&response_type=code`, method: 'post', auth: true })
        return
    }, [client, clientId, redirectUri, state])


    useEffect(() => {
        const func = async () => {
            if (!clientId) return
            const clientResponse = await oAuth.getClintById({ clientId, redirectUri: redirectUri! })

            if (clientResponse.status === 'error' || !clientResponse.clint) {
                return
            }

            setClient(clientResponse.clint)
        }
        func()
    }, [clientId, redirectUri])


    return (
        <div className={classes.container}>
            {!client ? <Loading /> : <> <div className={classes.header}>
                <div className={classes.logosContainer}>
                    <Avatar src={client.logo} square id={user?._id} name={user?.name} />

                    <MdLink className={classes.linkIcon} />
                    <Avatar src={org?.photo} square id={org?._id} name={org?.name} />
                </div>
                <div className={classes.title}>
                    <Typography>{client.name} will be able to connect Genius Chain</Typography>
                </div>
            </div>
                <div className={classes.bodyContainer}>
                    <div className={classes.topDescriptionTitle}>
                        <span className={classes.descriptionText}>By authorizing {client.name} he will be able to...</span>
                    </div>

                    <Divider className={classes.divider} />

                    <div className={classes.scopesListContainer}>
                        {client.scopes.map((scop, idx) => {
                            return <ScopItem scop={scop} key={scop + idx} />
                        })}
                    </div>

                    <Divider className={classes.divider} />

                    <div className={classes.optionsButtonsContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={getTokenCode}
                        >
                            Authorize
                        </Button>
                        <Button
                            variant="outlined"
                            color="default"
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                    </div>

                </div>
            </>}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        background: theme.palette.background.default,
        height: 264,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    logosContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.divider}`,
        padding: 16,
        borderRadius: 8,
    },
    title: {
        color: '#FFF',
        marginTop: 24,
    },
    bodyContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    linkIcon: {
        marginInline: 26,
        color: theme.palette.text.secondary,
        fontSize: 20,
    },
    topDescriptionTitle: {
        padding: '24px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    descriptionText: {
        textAlign: 'center',
        color: '#FFF'
    },
    divider: {
        width: 500,
        display: 'flex',
        alignSelf: 'center'
    },

    scopesListContainer: {
        marginBottom: 24,
    },
    optionsButtonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: 24,
        width: 500
    },
    cancelButton: {
        margin: '0px 12px'
    },

}))
