import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { OrganizationType } from 'common/models/organization'
import { admin } from 'frontend/API/admin'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IoMdMore } from 'react-icons/io'
import { MdClose, MdEdit, MdEmail, MdError } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import LabelWithValue from 'web/components/LabelWithValue'
import Menu from 'web/components/Menu'
import { useAlert } from 'web/containers/AlertProvider'
import { useConfirm } from 'web/containers/ConfirmProvider'
import { useLoading } from 'web/containers/LoadingProvider'
import CreateOrganization from './CreateOrganization'

interface Props {
    org: OrganizationType | undefined
    close: () => void
}

export default React.memo(ViewOrganization)

function ViewOrganization(props: Props) {
    const { org, close } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const confirm = useConfirm()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const [menuOpen, setMenuOpen] = useState(false)
    const openMenu = useCallback(() => setMenuOpen(true), [])
    const closeMenu = useCallback(() => setMenuOpen(false), [])

    const [edit, setEdit] = useState(false)
    const openEdit = useCallback(() => {
        setEdit(true)
        closeMenu()
    }, [closeMenu])
    const closeEdit = useCallback(() => setEdit(false), [])

    const ref = useRef<HTMLButtonElement>(null)

    const onSendEmail = useCallback(() => {
        closeMenu()
        confirm({ title: t('common.resendSetupEmail'), text: t('common.resendSetupEmailText') }).promise.then((res) => {
            if (res !== 'ok') return
            setLoading(true)

            admin
                .resendSetupEmail({ orgId: org!._id })
                .then(() => alert('success', t('common.sentSuccessfully')))
                .catch((e) => console.error(e))
                .finally(() => setLoading(false))
        })
    }, [alert, closeMenu, confirm, org, setLoading, t])

    const onDeactivate = useCallback(() => {
        closeMenu()
        confirm({ title: t('common.deactivateOrg'), text: t('common.deactivateOrgText') }).promise.then((res) => {
            if (res !== 'ok') return
            setLoading(true)

            admin
                .changeOrgStatus({ orgId: org!._id, method: 'disable' })
                .then(() => alert('success', t('common.deactivatedSuccessfully')))
                .catch((e) => console.error(e))
                .finally(() => setLoading(false))
        })
    }, [alert, closeMenu, confirm, org, setLoading, t])

    const onActivate = useCallback(() => {
        closeMenu()
        confirm({ title: t('common.activateOrg'), text: t('common.activateOrgText') }).promise.then((res) => {
            if (res !== 'ok') return
            setLoading(true)

            admin
                .changeOrgStatus({ orgId: org!._id, method: 'activate' })
                .then(() => alert('success', t('common.activatedSuccessfully')))
                .catch((e) => console.error(e))
                .finally(() => setLoading(false))
        })
    }, [alert, closeMenu, confirm, org, setLoading, t])

    return (
        <div className={classes.container}>
            <Dialog open={edit} onClose={closeEdit}>
                <CreateOrganization old={org} close={closeEdit} />
            </Dialog>

            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.organizationInfo')}</Typography>
            </div>

            <div className={classes.content}>
                <Avatar src={org?.photo} className={classes.avatar} id={org?._id} name={org?.name} square />

                <LabelWithValue label={t('common.organizationName')} value={org?.name} />
                <LabelWithValue label={t('common.organizationEmail')} value={org?.email} />
                <LabelWithValue label={t('common.organizationPhoneNumber')} value={org?.phoneNumber} />
                <LabelWithValue label={t('common.organizationID')} value={org?.address} />
                <LabelWithValue
                    noMargin
                    label={t('common.status')}
                    value={org?.active ? t('common.active') : t('common.disabled')}
                />
            </div>

            <IconButton ref={ref} onClick={openMenu} className={classes.editButton}>
                <IoMdMore />
            </IconButton>

            <Menu left={-180} top={24} open={menuOpen} anchorEl={ref} onClose={closeMenu}>
                <div className={classes.menu}>
                    <ButtonBase onClick={openEdit} style={{ marginTop: 8 }} className={classes.menuItem}>
                        <MdEdit className={classes.itemIcon} />
                        <Typography className={classes.itemText}>{t('common.edit')}</Typography>
                    </ButtonBase>

                    {org?.owner ? null : (
                        <ButtonBase onClick={org?.active ? onDeactivate : onActivate} className={classes.menuItem}>
                            {org?.active ? (
                                <MdError className={classes.itemIcon} />
                            ) : (
                                <AiFillCheckCircle className={classes.itemIcon} />
                            )}
                            <Typography className={classes.itemText}>
                                {org?.active ? t('common.deactivate') : t('common.activate')}
                            </Typography>
                        </ButtonBase>
                    )}

                    {org?.owner ? null : (
                        <ButtonBase onClick={onSendEmail} style={{ marginBottom: 8 }} className={classes.menuItem}>
                            <MdEmail className={classes.itemIcon} />
                            <Typography className={classes.itemText}>{t('common.sendSetupEmail')}</Typography>
                        </ButtonBase>
                    )}
                </div>
            </Menu>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    avatar: {
        height: 72,
        width: 72,
        marginBottom: 32,
        fontSize: 26,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    editButton: {
        position: 'absolute',
        right: 40,
        top: 80,
        background: theme.palette.background.default,
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    menu: {
        outline: 'none',
        width: 200,
        display: 'flex',
        flexDirection: 'column',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        transition: theme.transitions.create('background'),
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.action.hover,
        },
        height: 40,
        justifyContent: 'flex-start',
    },
    itemText: {
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    itemIcon: {
        fontSize: 20,
        marginRight: 8,
        marginLeft: 16,
    },
}))
