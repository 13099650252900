import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { duplicationOptions, ProjectType } from 'common/models/project'
import { useFormik } from 'formik'
import { mutate } from 'frontend/API/mutate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'
import { useOrganization } from 'web/containers/UserProvider'
import * as yup from 'yup'
import ConfigureSelectOrgs from './ConfigureSelectOrgs'

interface Props {
    close: () => void
    project: ProjectType
}

export default React.memo(ConfigureProject)

function ConfigureProject(props: Props) {
    const { close, project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()
    const org = useOrganization()

    const canEdit = org?._id === project.ownerOrgId
    const disabled = !canEdit

    const form = useFormik<Partial<ProjectType>>({
        initialValues: {
            name: project.name,
            duplicationStatus: project.duplicationStatus,
            beneficiaryIdStandard: project.beneficiaryIdStandard,
            actors: project.actors,
        },

        validationSchema: getSchema(),

        onSubmit: async (values) => {
            setLoading(true)

            try {
                const res = await mutate.update({
                    collection: 'projects',
                    id: project._id,
                    fields: {
                        name: values.name,
                        actors: values.actors,
                        beneficiaryIdStandard: values.beneficiaryIdStandard,
                        duplicationStatus: values.duplicationStatus,
                    },
                })

                if (res.result !== 'ok') {
                    alert('error', t(`errors.${res.result}`))
                    return
                }

                close()
            } catch (e) {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            } finally {
                setLoading(false)
            }
        },
    })

    const formValid = form.isValid && form.values.name!.trim() !== ''

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{t('common.configureProject')}</Typography>
            </div>

            <Form className={classes.content} disabled={!formValid} onSubmit={form.submitForm}>
                <div className={classes.idContainer}>
                    <div className={classes.idTextContainer}>
                        <Typography className={classes.idTextLabel}>{t('common.projectId') + ': '}</Typography>
                        <Typography className={classes.idText}>{project._id}</Typography>
                    </div>
                    <Typography className={classes.idHint}>{'* ' + t('common.projectIdHint')}</Typography>
                </div>

                <TextField
                    disabled={disabled}
                    value={form.values.name}
                    onChange={form.handleChange('name')}
                    className={classes.input}
                    fullWidth
                    variant="filled"
                    label={t('common.projectName')}
                    required
                />

                <TextField
                    disabled={disabled}
                    value={form.values.duplicationStatus}
                    onChange={form.handleChange('duplicationStatus')}
                    className={classes.input}
                    fullWidth
                    select
                    variant="filled"
                    label={t('common.duplicationSettings')}
                    required
                >
                    {duplicationOptions.map((opt) => {
                        return (
                            <MenuItem key={opt.value} value={opt.value}>
                                {t(opt.title)}
                            </MenuItem>
                        )
                    })}
                </TextField>

                {/* <TextField
                    disabled={disabled}
                    value={form.values.beneficiaryIdStandard}
                    onChange={form.handleChange('beneficiaryIdStandard')}
                    className={classes.input}
                    fullWidth
                    select
                    variant="filled"
                    label={t('common.beneficiaryIdStandard')}
                    required
                >
                    {beneficiaryIdStandardOptions.map((opt) => {
                        return (
                            <MenuItem key={opt.value} value={opt.value}>
                                {t(opt.title)}
                            </MenuItem>
                        )
                    })}
                </TextField> */}

                <Typography className={classes.organizations}>{t('common.organizations')}</Typography>

                <ConfigureSelectOrgs disabled={disabled} form={form} />

                <div className={classes.actionsContainer}>
                    {disabled ? null : (
                        <Button onClick={close} style={{ marginRight: 16 }} variant="outlined">
                            {t('common.cancel')}
                        </Button>
                    )}

                    {disabled ? null : (
                        <Button type="submit" disabled={!formValid} color="primary" variant="contained">
                            {t('common.update')}
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        projectSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    uploadText: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginBottom: 24,
        marginTop: 8,
    },
    input: {
        marginBottom: 16,
    },
    inputContainer: {
        display: 'flex',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 24,
        width: '100%',
    },
    organizations: {
        color: theme.palette.text.primary,
        marginBottom: 16,
        marginTop: 8,
        fontSize: 16,
        textAlign: 'start',
        width: '100%',
    },
    idContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 36,
        alignItems: 'flex-start',
        width: '100%',
    },
    idText: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    idTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    idTextLabel: {
        color: theme.palette.text.secondary,
        marginRight: 6,
    },
    idHint: {
        fontSize: 12,
        color: theme.palette.text.hint,
        maxWidth: '80%',
        marginTop: 8,
    },
}))

const getSchema = () => {
    return yup.object().shape({
        name: yup.string().required(),
    })
}
