import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { GetPublicResponse } from 'common/types/dashboardTypes'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    data: GetPublicResponse
}

export default React.memo(TopCompanies)

function TopCompanies(props: Props) {
    const { data } = props

    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>{t('common.topOrgsByRegistrations')}</Typography>

            <div className={classes.dataContainer}>
                {data.topCompanies.map((org, idx) => {
                    return (
                        <div className={classes.item} key={idx}>
                            <Typography className={classes.itemText}>{org.name}</Typography>
                            <Typography className={classes.itemNumber}>{org.numberOfRegistrations}</Typography>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        maxWidth: 960,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        background: theme.palette.background.default,
        margin: '0px 24px',
        marginTop: 100,
        marginBottom: 40,
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
        marginBottom: 24,
    },
    dataContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 8px',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    itemText: {
        color: theme.palette.text.primary,
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 8,
    },
    itemNumber: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
    },
}))
