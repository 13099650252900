import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { OrganizationType } from 'common/models/organization'
import { UserType } from 'common/models/user'
import { getUserRoles, RoleType } from 'common/roles/roles'
import logoutStream from 'frontend/helpers/logoutStream'
import userIdStream from 'frontend/helpers/userIdStream'
import useId from 'frontend/query/useId'
import useStream from 'frontend/utils/useStream'
import queryString from 'query-string'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'

interface Props {
    children: React.ReactNode
}

export default React.memo(UserProvider)

export type UserContextType = UserType | undefined
const UserContext = createContext<UserContextType>(null as any)
export const useUser = () => useContext(UserContext)

export type OrganizationContextType = OrganizationType | undefined
const OrganizationContext = createContext<OrganizationContextType>(null as any)
export const useOrganization = () => useContext(OrganizationContext)

export type RolesContextType = RoleType[]
const RolesContext = createContext<RolesContextType>([])
export const useRoles = () => useContext(RolesContext)

const noAuthRoutes = ['/login', '/setup', '/registration-lookup', '/public', '/reset-password']
const toHomeRoutes = ['/login', '/setup']

function UserProvider(props: Props) {
    const { children } = props

    const { history, location } = useReactRouter()
    const classes = useStyle()

    const [userIdValue, userIdLoading] = useStream(userIdStream)
    const [shouldLogout] = useStream(logoutStream)

    const idLoading = userIdLoading || userIdValue?.loading
    const userId = userIdValue?.userId

    const [user] = useId<UserType>({ collection: 'users', id: userIdValue?.userId as any })
    const userValue = shouldLogout ? undefined : user

    const [org] = useId<OrganizationType>({ collection: 'organizations', id: user?.orgId as any })

    const roles = useMemo(() => getUserRoles(user, org), [org, user])
    useEffect(() => {
        if (!idLoading && !userId && !noAuthRoutes.includes(location.pathname)) {
            const search =
                location.search?.trim() !== ''
                    ? `&search=${encodeURIComponent(location.search.replace(/^\?/g, ''))}`
                    : ''
            history.push(`/login?redirect=${encodeURIComponent(location.pathname)}${search}`)
            return
        }

        if (userValue && toHomeRoutes.some((path) => location.pathname.includes(path))) {
            const params = queryString.parse(location.search)

            if (params.redirect) {
                history.push(`${params.redirect}`)
            } else {
                history.push(`/`)
            }
        }
    }, [history, idLoading, location.pathname, userValue, userId, location.search, location])

    if (idLoading || (userId && (!userValue || !org))) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress color="primary" size={64} />
            </div>
        )
    }

    return (
        <UserContext.Provider value={userValue}>
            <OrganizationContext.Provider value={org}>
                <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
            </OrganizationContext.Provider>
        </UserContext.Provider>
    )
}

const useStyle = makeStyles((theme) => ({
    loadingContainer: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
