import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { OrganizationType } from 'common/models/organization'
import { MutateResponse } from 'common/types/mutateTypes'
import removeUndefined from 'common/utils/removeUndefined'
import { stringToLowerCase } from 'common/utils/stringToLowerCase'
import uploadToStorage from 'common/utils/uploadToStorage'
import { useFormik } from 'formik'
import { mutate } from 'frontend/API/mutate'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'
import * as yup from 'yup'

interface Props {
    close: () => void
    old?: OrganizationType
}

export default React.memo(CreateOrganization)

function CreateOrganization(props: Props) {
    const { close, old } = props

    const edit = !!old

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const form = useFormik({
        initialValues: {
            phoneNumber: old?.phoneNumber ?? '',
            name: old?.name ?? '',
            email: old?.email ?? '',
            adminName: '',
            adminEmail: '',
            photo: old?.photo ?? undefined,
        },

        validationSchema: getSchema(edit),

        onSubmit: async (values) => {
            setLoading(true)

            try {
                let url: string | undefined = old?.photo
                const photoChanged = (!edit && values.photo) || (edit && values.photo !== old?.photo)
                if (photoChanged) {
                    url = await uploadToStorage(values.photo as any)
                }

                let res!: MutateResponse

                if (edit) {
                    res = await mutate.update({
                        collection: 'organizations',
                        id: old!._id,
                        fields: removeUndefined({
                            name: form.values.name,
                            email: form.values.email,
                            phoneNumber: form.values.phoneNumber,
                            photo: url,
                        }),
                    })
                }

                if (!edit) {
                    res = await mutate.insert({
                        collection: 'organizations',
                        fields: removeUndefined({
                            name: form.values.name,
                            email: form.values.email,
                            phoneNumber: form.values.phoneNumber,
                            photo: url,
                            adminName: form.values.adminName,
                            adminEmail: form.values.adminEmail,
                        }),
                    })
                }

                if (res.result !== 'ok') {
                    alert('error', t(`errors.${res.result}`))
                    return
                }

                close()
            } catch (e) {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            } finally {
                setLoading(false)
            }
        },
    })

    const handleInput = useCallback(
        (e) => {
            form.setFieldValue('photo', e.target.files[0])
            e.target.value = ''
        },
        [form]
    )

    const formValid = form.isValid && form.values.name.trim() !== ''

    const photo: any = form.values.photo
    const image = useMemo(() => (photo instanceof Blob ? window.URL.createObjectURL(photo) : photo ?? ''), [photo])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <IconButton onClick={close} className={classes.close}>
                    <MdClose />
                </IconButton>

                <Typography className={classes.title}>{edit ? t('common.editOrg') : t('common.createOrg')}</Typography>
            </div>

            <Form className={classes.content} disabled={!formValid} onSubmit={form.submitForm}>
                <label htmlFor="upload" className={classes.label}>
                    <Avatar src={image} className={classes.avatar} square name={form.values.name} />
                    <Typography className={classes.uploadText}>{t('common.uploadLogo')}</Typography>
                </label>

                <input hidden accept="image/*" id="upload" type="file" onChange={handleInput} />

                <TextField
                    value={form.values.name}
                    onChange={form.handleChange('name')}
                    className={classes.input}
                    fullWidth
                    autoFocus
                    variant="filled"
                    label={t('common.orgName')}
                    required
                />

                <TextField
                    value={stringToLowerCase(form.values.email)}
                    onChange={form.handleChange('email')}
                    className={classes.input}
                    fullWidth
                    variant="filled"
                    label={t('common.orgEmail')}
                />
                <TextField
                    value={form.values.phoneNumber}
                    onChange={form.handleChange('phoneNumber')}
                    className={classes.input}
                    fullWidth
                    variant="filled"
                    label={t('common.orgPhoneNumber')}
                />

                {edit ? null : (
                    <div className={classes.inputContainer}>
                        <TextField
                            value={form.values.adminName}
                            onChange={form.handleChange('adminName')}
                            style={{ marginRight: 16 }}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.adminName')}
                            required
                        />
                        <TextField
                            value={stringToLowerCase(form.values.adminEmail)}
                            onChange={form.handleChange('adminEmail')}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.adminEmail')}
                            required
                        />
                    </div>
                )}

                <div className={classes.actionsContainer}>
                    <Button onClick={close} style={{ marginRight: 16 }} variant="outlined">
                        {t('common.cancel')}
                    </Button>

                    <Button type="submit" disabled={!formValid} color="primary" variant="contained">
                        {edit ? t('common.update') : t('common.create')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 548,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    avatar: {
        height: 72,
        width: 72,
        fontSize: 26,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    uploadText: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginBottom: 24,
        marginTop: 8,
    },
    label: {
        cursor: 'pointer',
    },
    input: {
        marginBottom: 16,
    },
    inputContainer: {
        display: 'flex',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 24,
        width: '100%',
    },
}))

const getSchema = (edit?: boolean) => {
    const original = { name: yup.string().required(), email: yup.string().email(), phoneNumber: yup.string() }
    const admin = { adminName: yup.string().required(), adminEmail: yup.string().required().email() }
    return yup.object().shape({ ...original, ...(edit ? {} : admin) })
}
