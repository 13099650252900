/* eslint-disable react/jsx-no-bind */
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import removeUndefined from 'common/utils/removeUndefined'
import uploadToStorage from 'common/utils/uploadToStorage'
import { useFormik } from 'formik'
import { mutate } from 'frontend/API/mutate'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdSettings } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import { useAlert } from 'web/containers/AlertProvider'
import Header from 'web/containers/Layout/Header'
import { useLoading } from 'web/containers/LoadingProvider'
import { useOrganization } from 'web/containers/UserProvider'
import * as yup from 'yup'

interface Props {}

export default React.memo(OrganizationSettings)

function OrganizationSettings(props: Props) {
    const {} = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()
    const org = useOrganization()!

    const form = useFormik({
        initialValues: {
            phoneNumber: org.phoneNumber ?? '',
            name: org.name ?? '',
            email: org.email ?? '',
            photo: org.photo,
            displayOrgName: org.displayOrgName ?? true,
            aliasName: org.aliasName ?? '',
            hideOrgInPublicPage: org.hideOrgInPublicPage ?? false,
        },

        validationSchema: schema,

        onSubmit: async (values) => {
            setLoading(true)

            try {
                let url: string | undefined = org.photo
                if (values.photo !== org.photo) {
                    url = await uploadToStorage(values.photo as any)
                }

                const res = await mutate.update({
                    collection: 'organizations',
                    id: org!._id,
                    fields: removeUndefined({
                        name: values.name,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        photo: url,
                        aliasName: values.aliasName,
                        displayOrgName: values.displayOrgName,
                        hideOrgInPublicPage: values.hideOrgInPublicPage,
                    }),
                })

                if (res.result !== 'ok') {
                    alert('error', t(`errors.${res.result}`))
                    return
                }

                alert('success', t('common.updatedSuccessfully'))
            } catch (e) {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            } finally {
                setLoading(false)
            }
        },
    })

    const handleInput = useCallback(
        (e) => {
            form.setFieldValue('photo', e.target.files[0])
            e.target.value = ''
        },
        [form]
    )

    const photo: any = form.values.photo
    const image = useMemo(() => (photo instanceof Blob ? window.URL.createObjectURL(photo) : photo ?? ''), [photo])

    const displayName = form.values.displayOrgName
    const hideInPublic = form.values.hideOrgInPublicPage

    const formValid = form.isValid && form.values.name.trim() !== ''

    return (
        <div className={classes.container}>
            <Header title={t('common.organizationSettings')} Icon={MdSettings} />

            <div className={classes.content}>
                <div className={classes.innerContent}>
                    <div className={classes.scroll}>
                        <div className={classes.titleContainer}>
                            <Typography className={classes.title}>{t('common.organizationInfo')}</Typography>
                            <Divider className={classes.divider} />
                        </div>

                        <div className={classes.avatarContainer}>
                            <label htmlFor="upload" className={classes.label}>
                                <Avatar src={image} className={classes.avatar} square name={form.values.name} />
                                <Typography className={classes.uploadText}>{t('common.uploadLogo')}</Typography>
                            </label>
                        </div>

                        <input hidden accept="image/*" id="upload" type="file" onChange={handleInput} />

                        <TextField
                            value={form.values.name}
                            onChange={form.handleChange('name')}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.orgName')}
                            required
                        />

                        <TextField
                            value={form.values.email}
                            onChange={form.handleChange('email')}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.orgEmail')}
                        />
                        <TextField
                            value={form.values.phoneNumber}
                            onChange={form.handleChange('phoneNumber')}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.orgPhoneNumber')}
                        />

                        <div className={classes.titleContainer}>
                            <Typography className={classes.title}>{t('common.publicSettings')}</Typography>
                            <Divider className={classes.divider} />
                        </div>

                        <div className={classes.switchContainer}>
                            <div className={classes.switchTextContainer}>
                                <Typography className={classes.switchTitle}>{t('common.displayOrgName')}</Typography>
                                <Typography className={classes.switchDescription}>
                                    {t('common.displayOrgNameDesc')}
                                </Typography>
                            </div>

                            <Switch
                                color="primary"
                                checked={displayName}
                                onChange={(e) => form.setFieldValue('displayOrgName', e.target.checked)}
                            />
                        </div>

                        <div className={classes.switchContainer}>
                            <div className={classes.switchTextContainer}>
                                <Typography className={classes.switchTitle}>{t('common.hideInPublicPage')}</Typography>
                                <Typography className={classes.switchDescription}>
                                    {t('common.hideInPublicPageDesc')}
                                </Typography>
                            </div>

                            <Switch
                                color="primary"
                                checked={hideInPublic}
                                onChange={(e) => form.setFieldValue('hideOrgInPublicPage', e.target.checked)}
                            />
                        </div>

                        <div className={classes.titleContainer}>
                            <Typography
                                className={classNames({ [classes.title]: true, [classes.disabled]: displayName })}
                            >
                                {t('common.aliasInfo')}
                            </Typography>
                            <Divider
                                className={classNames({
                                    [classes.divider]: true,
                                    [classes.disabledBackground]: displayName,
                                })}
                            />
                        </div>

                        <TextField
                            value={form.values.aliasName}
                            onChange={form.handleChange('aliasName')}
                            className={classes.input}
                            fullWidth
                            variant="filled"
                            label={t('common.organizationAlias')}
                            disabled={displayName}
                        />

                        <div style={{ width: 1, height: 104 }} />
                    </div>
                </div>
            </div>

            <div className={classes.footer}>
                <div className={classes.footerContent}>
                    <Button
                        disabled={!form.dirty}
                        onClick={form.resetForm as any}
                        style={{ marginRight: 16 }}
                        variant="outlined"
                    >
                        {t('common.discard')}
                    </Button>

                    <Button
                        onClick={form.submitForm}
                        disabled={!form.dirty || !formValid}
                        color="primary"
                        variant="contained"
                    >
                        {t('common.update')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },
    scroll: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
    },
    title: {
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: 14,
        marginBottom: 8,
        transition: theme.transitions.create('color'),
    },
    divider: {
        marginBottom: 24,
        transition: theme.transitions.create('background'),
    },
    avatar: {
        height: 72,
        width: 72,
        fontSize: 26,
    },
    uploadText: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginBottom: 24,
        marginTop: 8,
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    label: {
        cursor: 'pointer',
    },
    input: {
        marginBottom: 16,
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 24,
    },
    switchTitle: {
        fontSize: 16,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    switchDescription: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    switchTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    disabled: {
        color: theme.palette.action.disabled,
    },
    disabledBackground: {
        background: theme.palette.action.disabled,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 88,
        display: 'flex',
        justifyContent: 'center',
        background: theme.palette.background.paper,
        zIndex: 100,
    },
    footerContent: {
        display: 'flex',
        width: '100%',
        maxWidth: 640,
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: theme.palette.background.paper,
        zIndex: 100,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}))

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email(),
    phoneNumber: yup.string(),
    aliasName: yup.string(),
})
