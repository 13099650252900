/* 

ORDER IS REALLY IMPORTANT

*/

import './config/registerGlobalConfig'
import 'frontend/config/socket'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/Root'
import './index.css'
import 'frontend/polyfill/index'

function App() {
    return <Root />
}

ReactDOM.render(<App />, document.getElementById('app'))
