/* eslint-disable react/no-multi-comp */
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import values from 'lodash/values'
import React, { useMemo } from 'react'
import { extraColors } from 'web/containers/ThemeProvider'

const AvatarColors = [...values(extraColors)]

interface Props {
    src?: string
    name?: string
    id?: string
    className?: string
    square?: boolean
}

export default React.memo(Avatar)

function Avatar(props: Props) {
    const { src, id, name, className, square } = props

    const nameShort = useMemo(() => getNameAbbreviation(name ?? ''), [name])
    const avatarColor = useMemo(() => getColorFromId(id ?? name ?? ''), [id, name])

    return (
        <div title={name ?? ''}>
            <SimpleAvatar
                square={square}
                className={className}
                style={{ background: !src ? avatarColor : undefined, borderRadius: square ? 8 : undefined }}
                src={src || ''}
            >
                {!src ? nameShort : null}
            </SimpleAvatar>
        </div>
    )
}

interface SimpleProps {
    className?: string
    style?: React.CSSProperties
    src?: string
    children?: React.ReactNode
    square?: boolean
}

function SimpleAvatar(props: SimpleProps) {
    const { children, style, src, className, square } = props

    const classes = useStyles()

    const srcValid = useMemo(() => src && src.trim() !== '', [src])
    const content = useMemo(() => (srcValid ? null : processChildren(children)), [children, srcValid])

    return (
        <div style={style} className={classNames(classes.avatarComponent, className)}>
            {srcValid ? (
                <img style={{ borderRadius: square ? 8 : undefined }} className={classes.avatarImg} src={src} />
            ) : (
                content
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarComponent: {
        userSelect: 'none',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.background.paper,
        fontSize: 16,
        height: 48,
        width: 48,
    },
    avatarImg: {
        borderRadius: '50%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        userSelect: 'none',
    },
}))

function processChildren(val: any) {
    const splitCharacters = typeof val === 'string' && isStringArabic(val)

    return splitCharacters ? `${val?.charAt(0)} ${val?.charAt(1)}` : val
}

function isStringArabic(value: string) {
    return /^[\u0600-\u06FF]+$/.test(value)
}

export const hashCode = function (str: string) {
    let hash = 0,
        i,
        chr,
        len
    if (str.length == 0) return hash
    for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export const getColorFromId = (id: string) => {
    const hashResult = hashCode(id)
    return AvatarColors[Math.abs(hashResult % AvatarColors.length)]
}

export const getNameAbbreviation = (name: string) => {
    const split = name.split(' ')
    const firstAndLast = [split[0], name.split(' ')[split.length - 1] ?? '']

    return firstAndLast.map((seg) => seg[0]?.toLocaleUpperCase()).join('')
}
