export default function createPromise<T = void>() {
    let resolve: (val: T) => void
    let reject: (e: any) => void
    const promise = new Promise<T>((_resolve, _reject) => {
        resolve = _resolve
        reject = _reject
    })

    return {
        promise,
        resolve: resolve!,
        reject: reject!,
    }
}
