import makeStyles from '@material-ui/core/styles/makeStyles'
import { BarGroupBar } from '@visx/shape/lib/types/barGroup'
import { getColorFromId } from 'common/utils/getColorFromId'
import React from 'react'

interface Props {
    barData: BarGroupBar<string>
}
function DashboardBarItem(props: Props) {
    const { barData } = props
    const bar = barData

    const classes = useStyles()

    return (
        <>
            <g transform={`translate(${bar.x + bar.width / 2} ${bar.y - 6})`}>
                <text fill={getColorFromId(bar.key)} fontSize="11" textAnchor="middle">
                    <tspan>{(bar.value as any) === 0.1 ? '0' : bar.value}</tspan>
                </text>
            </g>

            <rect
                key={`bar-group-bar-${bar.index}-${bar.value}-${bar.key}`}
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={getColorFromId(bar.key)}
                rx={4}
                className={classes.item}
            />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        '&:hover': {
            opacity: 0.8,
        },
    },
}))

export default React.memo(DashboardBarItem)
