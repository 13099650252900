import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    className?: string
}

export default React.memo(EmptyList)

function EmptyList(props: Props) {
    const { className } = props

    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classNames(classes.container, className)}>
            <Typography className={classes.text}>{t('common.listIsEmpty')}</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        fontSize: 20,
    },
}))
