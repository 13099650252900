import { getCollectionTopic } from 'common/live/topics'
import { QueryUpdateMutateType } from 'common/types/socketTypes'
import { SubToCollectionArgs } from 'common/types/subTypes'
import Stream from 'common/utils/Stream'
import uniqBy from 'lodash/uniqBy'
import { socketHelpers } from '../config/socket'
import { topicsToStreams } from '../registry/requestsRegistry'
import unsubFromTopic from './unsubFromTopic'

interface Args<V> {
    data: SubToCollectionArgs
    onValue: (value: V[]) => void
}

export interface CollectionStreamType {
    type: QueryUpdateMutateType | 'initial'
    docId?: string
    doc?: any
    docs?: any[]
}

export default function subToCollection<V>(args: Args<V>): () => void {
    const { onValue } = args

    const topic = getCollectionTopic(args.data)

    if (!topicsToStreams.has(topic)) {
        topicsToStreams.set(topic, new Set())
    }

    const stream = new Stream<CollectionStreamType>()

    const streamSet = topicsToStreams.get(topic)!
    streamSet.add(stream)

    let lastValue: any[] = []

    const unSub = stream.subscribe((update) => {
        switch (update.type) {
            case 'initial': {
                const newValue = update.docs!
                lastValue = newValue
                onValue(newValue)

                break
            }

            case 'insert': {
                const newValue = uniqBy([...lastValue, update.doc], (x) => x._id)

                lastValue = newValue
                onValue(newValue)

                break
            }

            case 'delete': {
                const newValue = [...lastValue].filter((x) => x._id !== update.docId)

                lastValue = newValue
                onValue(newValue)

                break
            }

            case 'update': {
                const idx = lastValue.findIndex((x) => x._id === update.docId)

                if (idx === -1) {
                    return
                }

                const newValue = uniqBy(
                    [...lastValue.slice(0, idx), update.doc, ...lastValue.slice(idx + 1)],
                    (x) => x._id
                )

                lastValue = newValue
                onValue(newValue)

                break
            }
        }
    })

    socketHelpers.send({ method: 'subToCollection', args: args.data })

    return () => {
        unSub()

        const streamSet = topicsToStreams.get(topic)

        if (streamSet) {
            streamSet.delete(stream)

            if (!streamSet.size) {
                topicsToStreams.delete(topic)

                unsubFromTopic(topic)
            }
        }
    }
}
