import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import { ProjectType } from 'common/models/project'
import React, { useCallback } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import useReactRouter from 'use-react-router'

interface Props {
    project: ProjectType
    last?: boolean
    first?: boolean
}

export default React.memo(ProjectItem)

function ProjectItem(props: Props) {
    const { project, first, last } = props

    const classes = useStyles()
    const { push } = useReactRouter().history

    const onClick = useCallback(() => push(`/projects/${project._id}/overview`), [project._id, push])

    return (
        <div
            onClick={onClick}
            className={classNames({
                [classes.item]: true,
                [classes.lastItem]: last,
                [classes.firstItem]: first,
            })}
        >
            <div className={classes.textContainer}>
                <Typography className={classes.title}>{project.name}</Typography>
                <Typography className={classes.id}>{project.actors.map((x) => x.name).join(' , ')}</Typography>
            </div>

            <MdKeyboardArrowRight className={classes.icon} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    item: {
        height: 64,
        width: '100%',
        borderRadius: 8,
        marginBottom: 16,
        display: 'flex',
        projectSelect: 'none',
        transition: theme.transitions.create('background'),
        cursor: 'pointer',
        alignItems: 'center',
        position: 'relative',
        userSelect: 'none',

        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 8,
        marginLeft: 16,
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    lastItem: {
        marginBottom: 56,
    },
    id: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    firstItem: {
        marginTop: 40,
    },
    icon: {
        position: 'absolute',
        right: 16,
        color: theme.palette.text.secondary,
        fontSize: 24,
    },
}))
