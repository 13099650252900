import { SubToCollectionArgs } from 'common/types/subTypes'
import useMemoObject from 'frontend/utils/useMemoObject'
import { useEffect, useState } from 'react'
import subToCollection from './subToCollection'

export default function useCollection<V>(args: SubToCollectionArgs) {
    const [value, setValue] = useState<V[]>()
    const [loading, setLoading] = useState(true)

    const data = useMemoObject(args)

    useEffect(() => {
        const unsub = subToCollection<V>({
            data,
            onValue: (val) => {
                setValue(val)
                setLoading(false)
            },
        })

        return () => unsub()
    }, [data])

    return [value, loading] as const
}
