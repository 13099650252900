import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ProjectType } from 'common/models/project'
import { FormikProps } from 'formik'
import { api } from 'frontend/API/api'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd, MdClose } from 'react-icons/md'
import Avatar from 'web/components/Avatar'
import Form from 'web/components/Form'
import { useAlert } from 'web/containers/AlertProvider'
import { useLoading } from 'web/containers/LoadingProvider'

interface Props {
    form: FormikProps<Partial<ProjectType>>
    disabled: boolean
}

export default React.memo(ConfigureSelectOrgs)

function ConfigureSelectOrgs(props: Props) {
    const { form, disabled: noEdit } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const alert = useAlert()
    const { setLoading } = useLoading()

    const [open, setOpen] = useState(false)
    const openDialog = useCallback(() => setOpen(true), [])
    const closeDialog = useCallback(() => setOpen(false), [])

    const [orgId, setOrgId] = useState('')
    const onIdChange = useCallback((e) => setOrgId(e.target.value), [])

    const disabled = orgId.trim() === '' || orgId.length < 12

    const submitId = useCallback(() => {
        if (disabled) {
            return
        }

        setLoading(true)

        api.getOrgInfoByChainId({ chainId: orgId })
            .then((data) => {
                if (!data.info) {
                    alert('error', t('errors.instituteDoesNotExist'))
                    return
                }

                const newActors = [...(form.values.actors ?? []), data.info]

                form.setFieldValue(
                    'actors',
                    uniqBy(newActors, (x) => x._id)
                )

                setOrgId('')
                closeDialog()
            })
            .catch((e) => {
                console.log(e)
                alert('error', t('errors.somethingWentWrong'))
            })
            .finally(() => setLoading(false))
    }, [alert, closeDialog, disabled, form, orgId, setLoading, t])

    return (
        <>
            <Dialog open={open} onClose={closeDialog}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <IconButton onClick={closeDialog} className={classes.close}>
                            <MdClose />
                        </IconButton>

                        <Typography className={classes.title}>{t('common.addOrgToProject')}</Typography>
                    </div>

                    <Form className={classes.content} disabled={disabled} onSubmit={submitId}>
                        <TextField
                            value={orgId}
                            onChange={onIdChange}
                            className={classes.input}
                            fullWidth
                            autoFocus
                            variant="filled"
                            label={t('common.organizationId')}
                            required
                        />

                        <div className={classes.actionsContainer}>
                            <Button onClick={closeDialog} style={{ marginRight: 16 }} variant="outlined">
                                {t('common.cancel')}
                            </Button>

                            <Button type="submit" disabled={disabled} color="primary" variant="contained">
                                {t('common.add')}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Dialog>

            <div className={classes.actorsContainer}>
                {form.values.actors?.map((org, idx) => {
                    return (
                        <div className={classes.item} key={idx}>
                            <Avatar square src={org.photo} id={org._id} name={org.name} />
                            <div className={classes.itemText}>
                                <Typography className={classes.itemName}>{org.name}</Typography>
                                <Typography className={classes.itemId}>{org.chainId}</Typography>
                            </div>
                        </div>
                    )
                })}

                {noEdit ? null : (
                    <ButtonBase onClick={openDialog} className={classes.addOrgButton}>
                        <div className={classes.iconContainer}>
                            <MdAdd className={classes.icon} />
                        </div>

                        <div className={classes.buttonTextContainer}>
                            <Typography className={classes.itemName}>{t('common.addOrganization')}</Typography>
                        </div>
                    </ButtonBase>
                )}
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    actorsContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    itemText: {
        display: 'flex',
        marginLeft: 8,
        flexDirection: 'column',
    },
    itemName: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    itemId: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        fontSize: 12,
    },
    item: {
        display: 'flex',
        marginLeft: 8,
        marginBottom: 16,
    },
    addOrgButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        borderRadius: 8,
    },
    iconContainer: {
        height: 48,
        width: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        background: theme.palette.background.default,
        marginLeft: 8,
    },
    icon: {
        color: theme.palette.text.secondary,
        fontSize: 24,
    },
    buttonTextContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 8,
    },
    container: {
        width: 548,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: 40,
        width: '100%',
        background: theme.palette.background.default,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        userSelect: 'none',
        position: 'relative',
    },
    content: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 16,
    },
    close: {
        position: 'absolute',
        right: 0,
        height: 40,
        width: 40,
        padding: 0,
        borderRadius: 8,
        fontSize: 20,
    },
    input: {
        marginBottom: 16,
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 24,
        width: '100%',
    },
}))
