import Stream from 'common/utils/Stream'
import { getGlobalConfig } from '../config/globalConfig'

type UserIdStreamType = { userId: string | undefined; loading: boolean }
const userIdStream = new Stream<UserIdStreamType>(true, { loading: true, userId: undefined })

getGlobalConfig()
    .getTokens()
    .then((data) => {
        userIdStream.value({ loading: false, userId: data.userId || undefined })
    })

export default userIdStream
