import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import logo from 'web/assets/logo.svg'
import Avatar from 'web/components/Avatar'
import Menu from 'web/components/Menu'
import { useOrganization, useUser } from 'web/containers/UserProvider'
import { RouteType } from '../../Router/routes'
import NavMenuItem from './NavMenuItem'
import UserMenu from './UserMenu'

interface Props {
    tabs: RouteType[]
}

export default React.memo(NavMenu)

function NavMenu(props: Props) {
    const { tabs } = props

    const classes = useStyles()
    const { history, location } = useReactRouter()

    const org = useOrganization()
    const user = useUser()!

    useEffect(() => {
        if (tabs[0]?.path && location.pathname === '/') {
            history.push(tabs[0].path!)
        }
    }, [history, location.pathname, tabs])

    const optionsRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const openMenu = useCallback(() => setMenuOpen(true), [])
    const closeMenu = useCallback(() => setMenuOpen(false), [])

    const showId = user.admin || user.roles.includes('manageProjects')

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <img src={logo} className={classes.logo} />

                <Avatar src={org?.photo} className={classes.avatar} square id={org?._id} name={org?.name} />
                <Typography style={showId ? undefined : { marginBottom: 16 }} className={classes.title}>
                    {org?.name}
                </Typography>

                {showId ? <Typography className={classes.address}>{org?.address}</Typography> : null}

                <Divider />

                <div className={classes.tabsContainer}>
                    {tabs.map((tab, idx) => {
                        return <NavMenuItem idx={idx} tab={tab} key={idx} />
                    })}
                </div>

                <div className={classes.userContainer}>
                    <Avatar src={user?.photo} className={classes.userAvatar} id={user?._id} name={user.name} />

                    <div className={classes.nameContainer}>
                        <Typography className={classes.name}>{user.name}</Typography>
                        <Typography className={classes.email}>{user.email || user.phoneNumber}</Typography>
                    </div>

                    <IconButton onClick={openMenu} ref={optionsRef} className={classes.settingsButton}>
                        <MdSettings />
                    </IconButton>

                    <Menu left={40} onClose={closeMenu} open={menuOpen} anchorEl={optionsRef}>
                        <div className={classes.menuContent}>
                            <UserMenu close={closeMenu} />
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: 288,
        background: theme.palette.background.default,
        maxWidth: 288,
    },
    logo: {
        width: 130,
        pointerEvents: 'none',
        userSelect: 'none',
        marginBottom: 40,
    },
    content: {
        display: 'flex',
        padding: '24px 16px',
        flexDirection: 'column',
        height: 'calc(100% - 48px)',
    },
    avatar: {
        marginBottom: 16,
    },
    title: {
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: 16,
        marginBottom: 4,
    },
    address: {
        color: theme.palette.text.secondary,
        fontSize: 11,
        marginBottom: 16,
    },
    tabsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    userContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    userAvatar: {
        height: 40,
        width: 40,
        marginRight: 16,
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    name: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    email: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    settingsButton: {
        height: 40,
        width: 40,
        padding: 0,
        fontSize: 22,
    },
    menuContent: {
        width: 230,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
    },
}))
