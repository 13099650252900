import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'

interface Props {
    className?: string
}

export default React.memo(Loading)

function Loading(props: Props) {
    const { className } = props

    const classes = useStyles()

    return (
        <div className={classNames(classes.container, className)}>
            <CircularProgress color="primary" size={52} />
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
