import { makeStyles } from '@material-ui/core/styles'
import { AxisBottom } from '@visx/axis'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { BarGroup } from '@visx/shape'
import { getColorFromId } from 'common/utils/getColorFromId'
import { timeFormat, timeParse } from 'd3-time-format'
import { values } from 'lodash'
import { themeColors } from 'mobile/config/styles'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { extraColors } from 'web/containers/ThemeProvider'
import { DashboardDataType } from '../ProjectDashboard'
import DashboardBarItem from './DashboardBarItem'

export type BarGroupProps = {
    width: number
    height: number
    margin?: { top: number; right: number; bottom: number; left: number }
    events?: boolean
    data: DashboardDataType[]
    dataColumns: { [key: string]: string; name: string }[]
    dataColumnKey: string
    title: string
    minLength?: number
}

const defaultMargin = { top: 40, right: 0, bottom: 40, left: 0 }
const MIN_LENGTH = 3

const parseDate = timeParse('%Y-%m-%d')
const format = timeFormat('%b %d')
const formatDate = (date: string) => format(parseDate(date) as Date)

export const getDate = (d: DashboardDataType) => d.date

export default function ChartViewer({
    width,
    height,
    margin = defaultMargin,
    data,
    dataColumns,
    title,
    dataColumnKey,
    minLength,
}: BarGroupProps) {
    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom
    const keys = data.length ? Object.keys(data[0]).filter((d) => d !== 'date') : ([] as string[])
    const classes = useStyles()
    const { t } = useTranslation()

    const dateScale = scaleBand<string>({
        domain: data.map(getDate),
        padding: 0.2,
    })

    const cityScale = scaleBand<string>({
        domain: keys,
        padding: 0.1,
    })

    const tempScale = scaleLinear<number>({
        domain: [0, Math.max(...data.map((d) => Math.max(...keys.map((key) => Number(d[key])))))],
    })

    const colorScale = scaleOrdinal<string, string>({
        domain: keys,
        range: values(extraColors),
    })

    dateScale.rangeRound([0, xMax])
    cityScale.rangeRound([0, dateScale.bandwidth()])
    tempScale.range([yMax, 0])

    const tickLabelProps = useCallback(
        () => ({
            fill: themeColors.primary,
            fontSize: 11,
            textAnchor: 'middle',
        }),
        []
    )

    const notEnoughData =
        data.filter((x: any) =>
            Object.keys(x).some((key: any) => {
                return x[key] !== 0.1 && key !== 'date'
            })
        ).length < (minLength ?? MIN_LENGTH)

    return width < 10 ? null : (
        <div className={classes.container}>
            <h3 className={classes.title}>{title}</h3>

            {!data.length || notEnoughData ? (
                <div style={{ height: 200 }} className={classes.notEnoughContainer}>
                    <span className={classes.notEnoughPlaceholder}>{t('common.dataNotEnough')}</span>
                </div>
            ) : (
                <svg width={width} height={height}>
                    <rect x={0} y={0} width={width} height={height} fill={themeColors.background} rx={14} />
                    <Group top={margin.top} left={margin.left}>
                        <BarGroup
                            data={data}
                            keys={keys}
                            height={yMax}
                            x0={getDate}
                            x0Scale={dateScale}
                            x1Scale={cityScale}
                            yScale={tempScale}
                            color={colorScale}
                        >
                            {(barGroups) =>
                                barGroups.map((barGroup) => (
                                    <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                                        {barGroup.bars.map((bar, idx) => (
                                            <DashboardBarItem barData={bar} key={idx} />
                                        ))}
                                    </Group>
                                ))
                            }
                        </BarGroup>
                    </Group>
                    <AxisBottom
                        top={yMax + margin.top}
                        tickFormat={formatDate}
                        scale={dateScale}
                        stroke={themeColors.primary}
                        tickStroke={themeColors.primary}
                        hideAxisLine
                        tickLabelProps={tickLabelProps as any}
                    />
                </svg>
            )}
            <div className={classes.namesContainar}>
                {dataColumns.map((column) => {
                    return (
                        <div key={column[dataColumnKey]} className={classes.nameItemContainar}>
                            <div
                                className={classes.line}
                                style={{ background: getColorFromId(column[dataColumnKey]) }}
                            />
                            <span className={classes.orgName}>{column.name}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
    container: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 4,
        margin: '24px 0px',
        display: 'flex',
        flexDirection: 'column',
    },

    line: {
        width: 8,
        height: 8,
        margin: '0px 4px',
        borderRadius: 2,
    },
    orgName: {
        color: '#FFF',
        fontSize: 14,
    },
    namesContainar: {
        width: 1000,
        background: '#404646',
        borderRadius: 4,

        padding: '14px 0',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 26,
        boxSizing: 'border-box',
    },
    nameItemContainar: {
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
    },
    title: {
        padding: '0px 24px',
        color: theme.palette.text.primary,
        margin: '22px 0px',
        fontWeight: 500,
    },
    notEnoughPlaceholder: {
        color: '#bdbdbd',
    },
    notEnoughContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
}))
