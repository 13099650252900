interface ThemeColors {
    paper: string
    primary: string
    background: string
    hover: string
    selected: string
    divider: string
    red: string
    disabled: string
    textPrimaryColor: string
    iconWitheColor: string
}

export const themeColors = {
    paper: '#383D3E',
    primary: '#72DAA3',
    background: '#404546',
    hover: '#484C4D',
    selected: '#505555',
    divider: '#585C5D',
    red: '#DA7272',
    disabled: 'rgba(255, 255, 255, 0.32)',
    textPrimaryColor: '#FFF',
    iconWitheColor: 'rgba(255, 255, 255, 0.6);',
} as ThemeColors

export const extraColors = {
    cyan: '#72CEDA',
    blue: '#72AFDA',
    purple: '#9A72DA',
    orange: '#DAC372',
    red: '#DA7272',
    yellow: '#B9DA72',
    green: '#72DAA3',
}
