import { serverEndPoints } from 'common/api/serverEndpoints'
import {
    ChangeOrgStatusArgs,
    ChangeOrgStatusResponse,
    ResendSetupEmailArgs,
    ResendSetupEmailResponse,
} from 'common/types/adminTypes'
import sendRequest from './sendRequest'

export const admin = {
    resendSetupEmail: (args: ResendSetupEmailArgs) =>
        sendRequest<ResendSetupEmailResponse>({ args, endpoint: serverEndPoints.admin.resendSetupEmail }),

    changeOrgStatus: (args: ChangeOrgStatusArgs) =>
        sendRequest<ChangeOrgStatusResponse>({ args, endpoint: serverEndPoints.admin.changeOrgStatus }),
}
