import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdSearch } from 'react-icons/md'

interface Props {
    className?: string
    value: string
    onChange: (val: string) => void
}

export default React.memo(SearchBar)

function SearchBar(props: Props) {
    const { value, onChange, className } = props

    const classes = useStyles()
    const { t } = useTranslation()

    const inputRef = useRef<HTMLInputElement | null>(null)

    const applyHotKeys = useCallback(
        (e: React.KeyboardEvent): void => {
            if (e.which === 27 || e.keyCode === 27) {
                e.preventDefault()
                e.stopPropagation()
                onChange('')
                inputRef.current?.blur()
            }
        },
        [onChange]
    )

    const onClose = useCallback(
        (e) => {
            e.preventDefault()
            e.stopPropagation()
            onChange('')
            inputRef.current?.blur()
        },
        [onChange]
    )

    const onSearchChange = useCallback(
        (e) => {
            const val = e.target.value
            onChange(val)
        },
        [onChange]
    )

    const [isFocused, setIsFocused] = useState(false)
    const onFocus = useCallback(() => setIsFocused(true), [])
    const onBlur = useCallback(() => setIsFocused(false), [])

    const isEmpty = useMemo(() => value.trim() === '', [value])

    return (
        <div
            className={classNames({
                [className!]: !!className,
                [classes.searchContainer]: true,
                [classes.searchContainerFocused]: isFocused,
            })}
        >
            <InputBase
                className={classes.input}
                fullWidth
                spellCheck={false}
                inputProps={{
                    ref: inputRef,
                    onKeyDown: applyHotKeys,
                    onFocusCapture: onFocus,
                    onBlurCapture: onBlur,
                }}
                placeholder={t('common.search')}
                value={value}
                onChange={onSearchChange}
            />

            {isEmpty ? (
                <MdSearch className={classes.searchIcon} />
            ) : (
                <IconButton className={classes.closeButton}>
                    <MdClose className={classes.clearIcon} onClick={onClose} />
                </IconButton>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.default,
        borderRadius: 4,
        padding: '0px 12px',
        transition: theme.transitions.create(['box-shadow', 'border-color', 'height'], { duration: 200 }),
        height: 40,
        width: 256,
        border: `1px solid ${theme.palette.background.paper}`,
    },
    searchIcon: {
        color: theme.palette.text.secondary,
        fontSize: 24,
        transition: theme.transitions.create('color', { duration: 200 }),
    },
    input: {
        direction: theme.direction,
    },
    clearIcon: {
        color: theme.palette.text.secondary,
        fontSize: 20,
        transition: theme.transitions.create('color', { duration: 200 }),
    },
    closeButton: {
        padding: 0,
        height: 24,
        width: 24,
    },
    searchContainerFocused: {
        '& $searchIcon': {
            color: theme.palette.primary.main,
        },
        border: `1px solid ${theme.palette.primary.main}`,
        height: 38,
    },
}))
