import { BaseFields } from './common'

export interface ProjectOrgType {
    _id: string
    chainId: string
    name: string
    photo?: string
}

export interface ProjectType extends BaseFields {
    name: string
    duplicationStatus: DuplicationOptionType
    beneficiaryIdStandard: BeneficiaryIdStandardOptionType
    ownerOrgId: string
    actors: ProjectOrgType[]
    actorsIds: string[]
}

export type DuplicationOptionType = 'ignore' | 'insideThisProject' | 'inAllProject'

export const duplicationOptions = [
    { value: 'ignore', title: 'common.ignore' },
    { value: 'insideThisProject', title: 'common.insideThisProject' },
    // { value: 'inAllProject', title: 'common.inAllProject' },
]

export type BeneficiaryIdStandardOptionType = 'defaultStandard'

export const beneficiaryIdStandardOptions = [
    // { value: 'firstAndLastName', title: 'common.firstAndLastName' },
    { value: 'defaultStandard', title: 'common.defaultStandard' },
]
