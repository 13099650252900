import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AlertProvider from './AlertProvider'
import ConfirmProvider from './ConfirmProvider'
import LoadingProvider from './LoadingProvider'
import Locale from './Locale'
import Router from './Router/Router'
import ThemeProvider from './ThemeProvider'
import UserProvider from './UserProvider'

const Root = () => {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <Locale>
                    <AlertProvider>
                        <LoadingProvider>
                            <UserProvider>
                                <ConfirmProvider>
                                    <Router />
                                </ConfirmProvider>
                            </UserProvider>
                        </LoadingProvider>
                    </AlertProvider>
                </Locale>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default Root
