import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ChangePassword from 'web/pages/Auth/ChangePassword'
import ResetPassword from 'web/pages/Auth/ResetPassword'
import RegistrationLookup from 'web/pages/Beneficiary/RegistrationLookup'
import SetupAccount from 'web/pages/Login/SetupAccount'
import OAuth from 'web/pages/OAuth/OAuth'
import ProjectLayout from 'web/pages/Project/ProjectLayout'
import PublicPage from 'web/pages/PublicPage/PublicPage'
import Login from '../../pages/Login/Login'
import PageNotFound from '../../pages/Misc/PageNotFound'
import Layout from '../Layout/Layout'
import { useUser } from '../UserProvider'

const RouterComp = () => {
    const user = useUser()

    return (
        <Switch>
            <Route path="/public" exact component={PublicPage} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route path="/change-password" exact component={ChangePassword} />

            <Route path="/registration-lookup" exact component={RegistrationLookup} />

            {user ? null : <Route path="/login" exact component={Login} />}

            {user ? null : <Route path="/setup" exact component={SetupAccount} />}

            {user ? null : <Route path="/login" exact component={Login} />}

            {user ? <Route path="/oauth/authorize" exact component={OAuth} /> : null}

            {user ? <Route path="/projects/:projectId" component={ProjectLayout} /> : null}

            {user ? <Route component={Layout} /> : null}

            <Route component={PageNotFound} />
        </Switch>
    )
}

export default RouterComp
