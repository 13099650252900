import makeStyles from '@material-ui/core/styles/makeStyles'
import { GetPublicResponse } from 'common/types/dashboardTypes'
import { dashboard } from 'frontend/API/dashboard'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import logo from 'web/assets/logo.svg'
import Loading from 'web/components/Loading'
import { useAlert } from 'web/containers/AlertProvider'
import PublicDashboardTransactions from './components/PublicDashboardTransactions'
import TopCompanies from './components/TopCompanies'

export default React.memo(PublicPage)

function PublicPage() {
    const classes = useStyles()
    const alert = useAlert()
    const { t } = useTranslation()

    const [data, setData] = useState<GetPublicResponse>()

    useEffect(() => {
        dashboard
            .getPublicData({})
            .then((x) => setData(x))
            .catch(() => alert('error', t('errors.somethingWentWrong')))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} />

            <div className={classes.content}>
                {!data ? (
                    <Loading />
                ) : (
                    <div className={classes.innerContent}>
                        <TopCompanies data={data} />
                        {/* 
                        <ChartViewer
                            title={'Registrations'}
                            width={960}
                            height={320}
                            data={data.registraions as any}
                            dataColumns={data.orgs}
                            dataColumnKey="orgId"
                        /> */}

                        <PublicDashboardTransactions />

                        <div style={{ minHeight: 100, width: 1 }} />
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
    },
    content: {
        flex: 1,
        display: 'flex',
        overflowY: 'auto',
    },
    innerContent: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
    },
    logo: {
        position: 'absolute',
        width: 164,
        left: 24,
        top: 24,
    },
}))
