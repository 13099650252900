import Button from '@material-ui/core/Button/Button'
import * as FileSaver from 'file-saver'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { MdFileDownload } from 'react-icons/md'
import * as XLSX from 'xlsx'

interface Props {}

function DownloadBeneficiaryImportTemplate(props: Props) {
    const {} = props
    const { t } = useTranslation()
    const fileName = 'Beneficiary Import Template'

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const createTemplate = useCallback(() => {
        const ws1 = XLSX.utils.aoa_to_sheet([[]])
        XLSX.utils.sheet_add_aoa(
            ws1,
            [['name', 'lname', 'father_name', 'mother_name', 'gender', 'gv_doc', 'id_number']],
            { origin: 0 }
        )

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, ws1, 'Beneficiares')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }, [])

    return (
        <Button
            style={{ margin: '0px 12px' }}
            variant="contained"
            color="primary"
            startIcon={<MdFileDownload />}
            onClick={createTemplate}
        >
            {t('common.importTemplate')}
        </Button>
    )
}
export default DownloadBeneficiaryImportTemplate
