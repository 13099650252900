import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useCallback, useState } from 'react'
import Animate from 'web/components/Animate'
import top from '../../assets/left-top.png'
import logo from '../../assets/logo.svg'
import bottom from '../../assets/right-bottom.png'
import ResetPasswordConfirmEmailStep from './Components/ResetPasswordConfirmEmailStep'
import ResetPasswordEmailStep from './Components/ResetPasswordEmailStep'
import ResetPasswordNewPasswordStep from './Components/ResetPasswordNewPasswordStep'

export default React.memo(ResetPassword)

type ResetPasswordStep = 'email' | 'confirmEmail' | 'newPassword'

function ResetPassword() {
    const classes = useStyles()

    const [step, setStep] = useState<ResetPasswordStep>('email')
    const [token, setToken] = useState<string | undefined>()

    const [email, setEmail] = useState('')

    const onEmailConfirm = useCallback((emailValue: string) => {
        setEmail(emailValue)
    }, [])

    const onConfirmCode = useCallback((tokenValue: string) => {
        setToken(tokenValue)
    }, [])

    const goToNextStep = useCallback(() => {
        if (step === 'email') {
            setStep('confirmEmail')
            return
        } else if (step === 'confirmEmail') {
            setStep('newPassword')
            return
        }
    }, [step])

    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} />
            <img src={top} className={classes.top} />
            <img src={bottom} className={classes.bottom} />
            <Animate changeKey={step}>
                <div>
                    {step === 'email' ? (
                        <ResetPasswordEmailStep onEmailConfirm={onEmailConfirm} onNext={goToNextStep} />
                    ) : null}
                    {step === 'confirmEmail' ? (
                        <ResetPasswordConfirmEmailStep
                            onCodeConfirm={onConfirmCode}
                            email={email}
                            onNext={goToNextStep}
                        />
                    ) : null}
                    {step === 'newPassword' && token ? <ResetPasswordNewPasswordStep token={token} /> : null}
                </div>
            </Animate>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 480,
        marginBottom: 24,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 32,
        marginBottom: 80,
        textAlign: 'center',
    },
    email: {
        marginBottom: 24,
    },
    password: {
        marginBottom: 16,
    },
    submit: {
        height: 40,
    },
    logo: {
        position: 'absolute',
        width: 264,
        left: '50%',
        top: 64,
        transform: 'translate(-60%)',
        userSelect: 'none',
        pointerEvents: 'none',
    },
    top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    bottom: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 380,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    forgetPasswordLink: {
        color: theme.palette.text.primary,
        marginBottom: 34,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))
