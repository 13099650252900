import { withServerUrl } from 'common/api/serverEndpoints'
import removeUndefined from 'common/utils/removeUndefined'
import { getGlobalConfig } from 'frontend/config/globalConfig'

interface Args {
    args: any
    method?: 'post'
    endpoint: string
    auth?: boolean
    extraHeaders?: { [key: string]: string }
}

export default async function sendRequest<R = any>({ args, method = 'post', endpoint, auth = true, extraHeaders = {} }: Args) {
    const token = (await getGlobalConfig().getTokens()).token

    const res = await fetch(withServerUrl(endpoint), {
        method: method,
        mode: 'cors',
        headers: removeUndefined({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: auth ? token : undefined,
            ...extraHeaders,
        }) as any,
        body: JSON.stringify(args),
    })

    if (res.status === 302) {
        const { redirectTo } = (await res.json())
        window.location = redirectTo
    }

    if (!(res.status >= 200 && res.status < 300)) {
        throw Error('Status not success')
    }

    return (await res.json()) as R
}
