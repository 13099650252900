import { SubToCollectionArgs } from 'common/types/subTypes'
import { CollectionType } from '../models/common'

export const getIdTopic = ({ collection, id }: { id: string; collection: CollectionType }) => {
    return `${collection}:${id}`
}

export const getCollectionTopic = (args: SubToCollectionArgs) => {
    switch (args.collection) {
        case 'projectUsers': {
            return `${args.collection}:${args.projectId}`
        }
        case 'registrationAttempts': {
            return `${args.collection}:${args.projectId}`
        }
        case 'projectBeneficiaries': {
            return `${args.collection}:${args.projectId}`
        }
    }

    return args.collection
}

export const getCollectionFromTopic = (topic: string): CollectionType => {
    if (topic.includes('projectUsers')) {
        return 'projectUsers'
    }
    if (topic.includes('registrationAttempts')) {
        return 'registrationAttempts'
    }
    if (topic.includes('projectBeneficiaries')) {
        return 'projectBeneficiaries'
    }
    if (topic.includes('beneficiaries') && !topic.includes('projectBeneficiaries')) {
        return 'beneficiaries'
    }

    return topic as CollectionType
}
