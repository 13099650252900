import makeStyles from '@material-ui/core/styles/makeStyles'
import { ProjectType } from 'common/models/project'
import useId from 'frontend/query/useId'
import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import Loading from 'web/components/Loading'
import NavMenu from 'web/containers/Layout/NavMenu/NavMenu'
import { PROJECT_ROUTES } from 'web/containers/Router/routes'
import { useRoles } from 'web/containers/UserProvider'
import PageNotFound from 'web/pages/Misc/PageNotFound'
import hasPermissions from 'web/utils/hasPermissions'

interface Props {}

export default React.memo(ProjectLayout)

function ProjectLayout(props: Props) {
    const {} = props

    const classes = useStyles()
    const roles = useRoles()
    const { match } = useReactRouter()
    const projectId = (match.params as any).projectId

    const [project, loading] = useId<ProjectType>({ id: projectId, collection: 'projects' })

    const routes = useMemo(() => {
        return PROJECT_ROUTES.filter((tab) => hasPermissions(roles, tab.roles)).map((r) => {
            return { ...r, path: `/projects/${projectId}${r.path}` }
        })
    }, [projectId, roles])

    return (
        <div className={classes.container}>
            <NavMenu tabs={routes} />

            <div className={classes.content}>
                {loading ? (
                    <Loading />
                ) : (
                    <Switch>
                        {routes.map((route, idx) => {
                            return (
                                <Route
                                    key={idx}
                                    exact={route.exact}
                                    path={route.path}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    component={(props: any) => <route.component {...props} project={project} />}
                                />
                            )
                        })}

                        <Route component={PageNotFound} />
                    </Switch>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        display: 'flex',
    },
}))
