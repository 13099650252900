import { serverEndPoints } from 'common/api/serverEndpoints'
import {
    BeneficiariesRegistraionResponse,
    BeneiciariesRegistraionArgs,
} from 'common/types/beneficiaryRegistrationTypes'
import sendRequest from './sendRequest'

export const beneficiariesRegistraion = {
    registerBeneficiaries: (args: BeneiciariesRegistraionArgs) =>
        sendRequest<BeneficiariesRegistraionResponse>({ endpoint: serverEndPoints.api.registerBeneficiaries, args }),
}
