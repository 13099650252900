import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import PageNotFound from 'web/pages/Misc/PageNotFound'
import hasPermissions from 'web/utils/hasPermissions'
import { BENEFICIARY_ROUTES, LAYOUT_ROUTES } from '../Router/routes'
import { useRoles } from '../UserProvider'
import NavMenu from './NavMenu/NavMenu'

interface Props { }

export default React.memo(Layout)

function Layout(props: Props) {
    const { } = props



    const classes = useStyles()
    const roles = useRoles()

    console.log('=========', roles)
    
    const routes = useMemo(() => {
        return LAYOUT_ROUTES.filter((tab) => hasPermissions(roles, tab.roles))
    }, [roles])


    return (
        <div className={classes.container}>
            <NavMenu tabs={routes} />

            <div className={classes.content}>
                <Switch>
                    {[...BENEFICIARY_ROUTES, ...routes].map((route, idx) => {
                        return <Route key={idx} exact={route.exact} path={route.path} component={route.component} />
                    })}

                    <Route component={PageNotFound} />
                </Switch>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        display: 'flex',
    },
}))
