import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { UserType } from 'common/models/user'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd, MdGroup } from 'react-icons/md'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import UserItem from './components/UserItem'
import CreateUser from './CreateUser'

interface Props {}

export default React.memo(UsersList)

function UsersList(props: Props) {
    const {} = props

    const classes = useStyles()
    const { t } = useTranslation()

    const [search, setSearch] = useState('')

    const [users, loading] = useCollection<UserType>({ collection: 'users' })

    const list = useMemo(() => {
        const filtered = (users ?? []).filter((x) => x.name.toLowerCase().includes(search.toLowerCase()))
        return sortBy(filtered, (x) => x.createdAt).reverse()
    }, [users, search])

    const [createOpen, setCreateOpen] = useState(false)
    const openCreate = useCallback(() => setCreateOpen(true), [])
    const closeCreate = useCallback(() => setCreateOpen(false), [])

    const changeKey = loading + '' + list.length + ''

    return (
        <div className={classes.container}>
            <Dialog open={createOpen} onClose={closeCreate}>
                <CreateUser close={closeCreate} />
            </Dialog>

            <Header
                value={search}
                onChange={setSearch}
                title={t('common.users')}
                Icon={MdGroup}
                actions={[{ title: t('common.createUser'), onClick: openCreate, Icon: MdAdd }]}
            />

            <Animate changeKey={changeKey}>
                {loading ? (
                    <Loading />
                ) : !list.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemContainer}>
                                {list.map((user, idx) => {
                                    const last = idx === list.length - 1
                                    const first = idx === 0

                                    return <UserItem user={user} key={idx} last={last} first={first} />
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
}))
