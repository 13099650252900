import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

interface Props {
    label: string
    value: string | undefined
    noMargin?: boolean
}

export default React.memo(LabelWithValue)

function LabelWithValue(props: Props) {
    const { label, value, noMargin } = props

    const classes = useStyles()

    return (
        <div className={classes.container} style={{ marginBottom: noMargin ? undefined : 24 }}>
            <Typography className={classes.label}>{label}</Typography>
            <Typography className={classes.value}>{value || '...'}</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontSize: 12,
        color: theme.palette.primary.main,
    },
    value: {
        fontSize: 16,
        color: theme.palette.text.primary,
    },
}))
