const dev = false
export const portalDomain = 'genius-chain-294b5.web.app'

export const baseAppUrl = dev ? process.env.DEVELOPMENT_SERVER_URL : 'https://server.thegeniuschain.com'

export const withServerUrl = (end: string) => (dev ? `${baseAppUrl}:3000/${end}` : `${baseAppUrl}/${end}`)

export const serverEndPoints = {
    socket: dev ? `${baseAppUrl}:3000` : `${baseAppUrl}`,

    auth: {
        login: 'auth/login',
        mobileLogin: 'auth/mobile-login',
        setupPassword: 'auth/setup-password',
        changePassword: 'auth/change-password',
        checkEmailExist: 'auth/checkEmailExist',
        sendVerificationCode: 'auth/send-verification-code',
        checkVerificationCode: 'auth/check-verification-code',
        resetPassword: 'auth/reset-password',
    },

    mutate: {
        insert: 'mutate/insert',
        update: 'mutate/update',
        delete: 'mutate/delete',
    },

    chain: {
        getOrgInfoByChainId: 'chain/get-org-info',
        checkIfRegistrationValid: 'chain/check-registration',
        lookup: 'chain/lookup',
        checkStatus: 'chain/check-status',
    },

    admin: {
        resendSetupEmail: 'admin/resend-setup-email',
        changeOrgStatus: 'admin/change-org-status',
    },

    dashboard: {
        project: 'dashboard/project',
        getPublicData: 'dashboard/get-public-data',
        organizationData: 'dashboard/organizationData',
    },

    api: {
        registerBeneficiaries: 'registerBeneficiaries',
        getBeneficiaryInfo: 'getBeneficiaryInfo',
        test: 'api/test',
    },
    oAuth: {
        getClintById: 'oauth/getClintById',
    },

    sync: 'sync',
} as const
