import { replaceString } from './replaces'

export interface DefaultStandardInfo {
    name: string
    lname: string
    father_name: string
    mother_name: string
    gender: string
    gv_doc: string
    id_number: string
}

export default function getDefaultStandardInfo(data: any): string {
    const { father_name, gender, gv_doc, lname, mother_name, name, id_number } = data

    const result = {
        name: replaceString(name + ''),
        lname: replaceString(lname + ''),
        father_name: replaceString(father_name + ''),
        mother_name: replaceString(mother_name + ''),
        gender: replaceString(gender + ''),
        gv_doc: replaceString(gv_doc + ''),
        id_number: id_number ? replaceString(id_number) + '' : '',
    }

    return JSON.stringify(result)
}
