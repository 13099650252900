export const replaceString = (str: string | number) => {
    if (!str) {
        return
    }

    let result = str

    if (typeof result === 'number') {
        result = result.toString()
    }

    result = result.toLowerCase()
    result = result.trim().replaceAll(' ', '')

    result = arabic(result)
    result = arabicNumbersToEnglish(result)

    result = specialCharacters(result)
    result = arabicSpecial(result)

    return result
}

const arabic = (str: string) => {
    let result = str

    result = result.replaceAll('أ', 'ا')
    result = result.replaceAll('إ', 'ا')
    result = result.replaceAll('آ', 'ا')
    result = result.replaceAll('ة', 'ه')
    result = result.replaceAll('ؤ', 'و')
    result = result.replaceAll('ئ', 'ي')
    result = result.replaceAll('ء', 'ا')
    result = result.replaceAll('ى', 'ي')

    return result
}

const arabicNumbersToEnglish = (str: string) => {
    const res = str
        .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '')
        .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')

    return res
}

const specialCharacters = (str: string) => {
    const reg = /[~!@#$%^&*()\-_=+[\]{}'"|/.,:;><?`]*/g
    return str.replace(reg, '')
}

const arabicSpecial = (str: string) => {
    const reg = /[\u0610-\u061A\u064B-\u0660]*/g
    return str.replace(reg, '')
}
