import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ProjectType } from 'common/models/project'
import { ProjectUserType } from 'common/models/projectUser'
import useCollection from 'frontend/query/useCollection'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import Animate from 'web/components/Animate'
import EmptyList from 'web/components/EmptyList'
import Loading from 'web/components/Loading'
import Header from 'web/containers/Layout/Header'
import AddUserToProject from './components/AddUserToProject'
import ProjectUserItem from './components/ProjectUserItem'

interface Props {
    project: ProjectType
}

export default React.memo(ProjectUsersList)

function ProjectUsersList(props: Props) {
    const { project } = props

    const classes = useStyles()
    const { t } = useTranslation()
    const { push } = useReactRouter().history

    const [search, setSearch] = useState('')

    const [projectUsers, loading] = useCollection<ProjectUserType>({
        collection: 'projectUsers',
        projectId: project._id,
    })

    const list = useMemo(() => {
        const filtered = (projectUsers ?? []).filter((x) => x.name.toLowerCase().includes(search.toLowerCase()))
        return sortBy(filtered, (x) => x.createdAt).reverse()
    }, [projectUsers, search])

    const [createOpen, setCreateOpen] = useState(false)
    const openCreate = useCallback(() => setCreateOpen(true), [])
    const closeCreate = useCallback(() => setCreateOpen(false), [])

    const changeKey = loading + '' + list.length + ''

    const goBack = useCallback(() => push('/projects'), [push])

    return (
        <div className={classes.container}>
            <Dialog open={createOpen} onClose={closeCreate}>
                <AddUserToProject existingUsers={projectUsers ?? []} projectId={project._id} close={closeCreate} />
            </Dialog>

            <Header
                onBackPress={goBack}
                value={search}
                onChange={setSearch}
                title={`${project.name} - ${t('common.projectUsers')}`}
                actions={[{ title: t('common.addUserToProject'), onClick: openCreate, Icon: MdAdd }]}
            />

            <Animate changeKey={changeKey}>
                {loading ? (
                    <Loading />
                ) : !list.length ? (
                    <EmptyList />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.innerContent}>
                            <div className={classes.itemContainer}>
                                {list.map((projectUser, idx) => {
                                    const last = idx === list.length - 1
                                    const first = idx === 0

                                    return (
                                        <ProjectUserItem
                                            projectUser={projectUser}
                                            key={idx}
                                            last={last}
                                            first={first}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Animate>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
        marginRight: 5,
    },
    innerContent: {
        width: '100%',
        maxWidth: 640,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
}))
